import React,{useState} from 'react'
// import ListenAndChooseTheWord from './../engines/ListenAndChooseTheWord';
import VerticalMenu from './../components/VerticalMenu';
import { useEffect } from 'react';
import SearchIcon from './../svgs/SearchIcon'
import { useHistory } from "react-router-dom";

import HeaderTop from './../components/headerTop';


const baseurl = process.env.REACT_APP_FRONT_LINK;

const quizsection = {
    subtitle:"Listen and point"
}
const questions = [{
    "_id":"question_82",
    "type":"listen_choose",
    "name":"Jordan",
    "answers":
    [{"name":"color","correct":true,"fileId":"file_26","fileLink":"attach/1625400172579.png"},
    {"name":"draw","correct":false,"fileId":"file_27","fileLink":"attach/1625400187584.png"},
    {"name":"raise your hand","correct":false,"fileId":"file_28","fileLink":"attach/1625400198207.png"},
    {"name":"read","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"},
    {"name":"listen","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"}

], 
    "stageId":"stage_40",
    "catId":"category_1",
    "unitId":"unit_20",
    "quizId":"quizsection_2",
    "created":1625392118,
    "createdTime":"2021-07-04T09:48:38.383Z",
    "audio":"attach/1625394010949.mp3",
    "updated":1625400721,
    "updatedTime":"2021-07-04T12:12:01.076Z"}
]
function FollowInstructions(props) {

    const history = useHistory();
    const [icon,setIcon] = useState("");
    const [levels,setLevels] = useState([1,2,3,4,5,6])

    const [data,setData] = useState([])

    const [startDrag,setStartDrag] = useState({});
    
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    const checkQuestion = (e) => {
        // let data2 = [...data];
        // let allcorrect = true;

        // data2.map((item,index) => {

        //     if( JSON.stringify(item.userAnswer) !== JSON.stringify(item.correctAnswer)){

        //         item.correct = false;
        //         allcorrect = false;
        //     } else {
        //         item.correct = true;

        //     }

        //     return item;
        // });

        // setData(data2);

        // if(allcorrect === true) {
        //     document.getElementById("correct-au").play();
        // } else {
        //     document.getElementById("wrong-au").play();
        // }


        setTimeout(() => {

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:"Follow the instructions",
                userAnswers:[],
                type:"follow_instruction"
            }
            props.nextExercise(answerFormat);

        }, 1000);
        
        // 
    }

    useEffect(() => {
        document.getElementById("intr").play();
     document.getElementsByTagName("body")[0].style.background = "#EAF6FC";
     setIcon(props.questions.icon);
     setData(props.questions.data)


    }, [])

    const playAudio = () => {
                document.getElementById("intr").play();

    }

    const allowDrop = (ev) => {     
        ev.preventDefault();
    }

    const dropItemFunc = (e,i) => {

        let data2 = [...data];


        if(startDrag.parent !== undefined) {
            data2[startDrag.parent].userAnswer.push(data2[startDrag.parent].words[startDrag.child])
            delete data2[startDrag.parent].words[startDrag.child];
            setData(data2);
        }
        

    }

    const dragItemFunc = (e,i,j) => {
        setStartDrag({
            parent:i,
            child:j
        })
    }

    
    return(<div id="mainapp" className="gameapp" style={{color:"#fff"}} >
            <div>
                <div id="mainquestion">
                {props.questions.mainImage !== undefined &&                 <div style={{width:"40%"}} align="left"><img src={baseurl + props.questions.mainImage} />  </div>   
}

        <div id="orderwords" style={{
                width: "40%",
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                flexWrap:"wrap"
        }}>
           {props.questions.images.map((item,index) => {
               return(
                    <div align="center" style={{width:item.width,padding:"2%",boxSizing:"border-box"}}>
                    <img src={baseurl + item.url} style={{width:"100%",height:"auto"}} />
                    <div style={{color:"#22CBF2",fontFamily:"gotham-bold",fontSize:25}}>{item.text}</div>
                    </div>
               )
        })}
        </div>
                   

                    <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>
            </div>
                </div>

            </div>
            <audio id="intr" src={baseurl + props.questions.audio} />

            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

        
    </div>)
}
export default FollowInstructions;