import react,{useEffect, useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import CharacherItem from './CharacherItem'

const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]


const assets = [
    "assets/avatars/home/meet/muzzy.png"
    ,"assets/avatars/home/meet/king.png"
    ,"assets/avatars/home/meet/queen.png"
    ,"assets/avatars/home/meet/sylvia.png"
    ,"assets/avatars/home/meet/bob.png"
    ,"assets/avatars/home/meet/corvax.png"
]
function MeetTheCharacters(props) {
    const history = useHistory();

    const audiotitle = useRef();
    // const audio1 = useRef();
    // const audio2 = useRef();
    // const audio3 = useRef();
    // const audio4 = useRef();
    // const audio5 = useRef();
    // const audio6 = useRef();




    useEffect(() => {
            // console.log(audiotitle.current)
            // audiotitle.current.play();
            // audiotitle.current.ontimeupdate = function () {
            
            //     // console.log(audiotitle.current.currentTime,audiotitle.current.duration);
            // }
            console.log(props)
    }, [])



    const playAudio = (e) => {
        audiotitle.current.play()
    }


    const checkQuestion = (e) => {

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:"Meet the characters",
            userAnswers:[],
            type:"MeetTheCharactersV2"
        }
        props.nextExercise(answerFormat);

    }

    return(<div style={{width:"60%"}}>
        
        {props.questions.leftImage !== "" && 
        <div>
            <img style={{width:400}} src={baseurl + props.questions.leftImage} />
            </div>}
 
        {props.questions.title !== "" && 
        <div className="question-title-container" >
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/listen.svg"} />
                <h1 className="question-title">Meet the characters.</h1>

            </div>
            <div id="audio-bt"><img src={baseurl + "assets/audio/listen.png"} onClick={playAudio} /></div>
        </div>}
        <div className="read-question-data chara-lists">
            {assets.map((item,i) => {
                let delay = 0.3 * i;
                return (<CharacherItem index={i + 1}  imgUrl={item} delay={delay} />)
            })}
        </div>
        <div className="button-check">
                <button className="check-answer-bt" onClick={checkQuestion} variant="contained" color="primary">Next</button>

                </div>

        <audio src={`${baseurl}assets/audio/meet/title.mp3`} ref={audiotitle} />


    </div>)
}

export default MeetTheCharacters;