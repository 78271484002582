import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={248.253}
      height={233.608}
      viewBox="0 0 248.253 233.608"
      {...props}
    >
      <defs>
        <filter
          id="prefix__a"
          x={0}
          y={0}
          width={248.253}
          height={233.608}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={8} dy={10} />
          <feGaussianBlur stdDeviation={10} result="blur" />
          <feFlood floodColor="#0b329d" floodOpacity={0.114} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g data-name="Group 4027">
        <g transform="translate(-.003 -.003)" filter="url(#prefix__a)">
          <path
            data-name="Path 2484"
            d="M39.228 73.337C48.09 65.555 60.273 60.6 75.557 60.201c17.777-.463 39.7 6.11 55.687-4.5 10.435-6.926 10.649-18.109 6.365-28.73-.828-2.053-2.5-4.73-2.49-6.852a11.218 11.218 0 016.431 1.141c69.713 32.1 105.23 137.175 13.772 168.369-36.23 12.357-98.972-5.194-120.949-37.447-18.418-27.022-15.854-60.658 4.855-78.845z"
            fill="#7ebe40"
          />
        </g>
        <text
          data-name="What's your name?"
          transform="translate(115.037 116.938)"
          fill="#fff"
          fontSize={24}
          fontFamily="gotham-medium"
          fontWeight={500}
        >
          <tspan x={-45.264} y={0}>
            {"What's"}
          </tspan>
          <tspan x={-69.804} y={29}>
            {"your name?"}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export default SvgComponent
