import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import GreenCircle from './../svgs/greenCircle'
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);
    const [icon,setIcon] = useState("");
    
    
    const intr = useRef(null)

    const [items,setItems] = useState([
       
        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i) => {
        let userSelect2 = [...userSelect];
        userSelect2[i] = value;
        setUserSelect(userSelect2);

    }

    const checkQuestion = () => {


        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:"Listen, read, and say.",
            userAnswers:[],
            type:"listen-read-and-say"
        }

        //history.push(baseurl + "look_say")
        props.nextExercise(answerFormat);
    }


    useEffect(() => {
        setItems(props.questions.answers);
        let currentItems = props.questions.answers

        setIcon(props.questions.icon)
    

        // currentItems = shuffle(currentItems);
        if(props.questions.hideAudio !== true) {
            intr.current.play();
        }

        setTimeout(() => {
            // document.getElementById("intr").play();
        },300)

        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const audioPlay = (i) => {
        console.log(document.getElementsByClassName("audio-file"));

        let audios = document.getElementsByClassName("audio-file");

        for(let i = 0;i < audios.length;i++) {
            audios[i].currentTime = 0;
            audios[i].pause();
        }
        document.getElementById("audioplay-" + i).play();
    }

    return(<div id="look_write">

{props.questions.muzzyImage !== undefined && <div>
            <img src={baseurl + props.questions.muzzyImage} />    
        </div>}

        {props.questions.topImage !== undefined && <div>
            <img src={baseurl + props.questions.topImage} />    
        </div>}
        {props.questions.hideTitle === undefined && 
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                {/* <img src={baseurl + "assets/game5/write.svg"} /> */}

                {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/write.svg"} />}


                    <h1 className="question-title">Listen, read, and say.</h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>}
                {/* <GreenCircle style={{marginLeft:-30,marginTop:-30}} /> */}

        <div id="look_write-items-v1" className="listenread-container">
            {randList.map((item,j) => {

                let itemStyle = {};
                if(props.questions.paddingBottom !== undefined) {
                    itemStyle = {paddingBottom:props.questions.paddingBottom}
                }
                return(
                <div className="look_write-item-v1 listenread" style={itemStyle}>

                    {item.img !== "none"?checkAnswers === false ?<img src={baseurl + "assets/chara/" + item.img + ".png"} />:item.correct === false ?<img style={{border:"5px solid #F84E53"}} src={baseurl + "assets/chara/" + item.img + ".png"} />:<img style={{border:"5px solid #83cd83"}} src={baseurl + "assets/chara/" + item.img + ".png"} />:""}
                   
                    
                    <div className="word-v1">
                        {item.words.map((subWord,i) => {


                            
                                return(<div style={{
                            
                                fontSize: 20,
    boxShadow: "rgb(34 203 242) -4px 4px 0px",
    border: "2px solid rgb(34 203 242)",
    padding: "15px 30px",
    borderRadius: "10px",
    position:"relative",
    background:"#fff"
                            }}>

                                
                                <img src={j % 2 === 0?baseurl + "assets/game8/arrow.png":baseurl + "assets/game8/arrow_ar.png"} style={{
                                        width: "auto",
                                        height: "auto",
                                        border: 0,
                                        position: "absolute",
                                        left: -19,
                                        top: "17%",
                                        borderRadius:0
                                }} />
                                <div dangerouslySetInnerHTML={{ __html: subWord.text}}>

                                </div>
                                
                                </div>)
                            

                        })}
                                                       {item.fileLink !== undefined && 
                                                       <>
                                                                                                              <div onClick={() => {audioPlay(j)}} className="audio-de" style={{marginLeft:10,marginRight:10}}><img src={baseurl + "assets/audio/listen.png"} /></div>

<audio className='audio-file' src={baseurl + item.fileLink} id={"audioplay-" + j} />

                                                       </>}

<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                    </div>
                </div>)
            })}
        </div>
        
        {props.questions.newaudio !== undefined?
    <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" ref={intr} />:
    <audio src={`${baseurl}assets/tracks/t05/track5v1.mp3`} className="hidden-audio" id="intr" ref={intr} />    
    }
        

             

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>

                </div>


    </div>)
}
export default DragAndDrop;