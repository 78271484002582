import React,{useState} from 'react'
// import ListenAndChooseTheWord from './../engines/ListenAndChooseTheWord';
import VerticalMenu from './../components/VerticalMenu';
import { useEffect } from 'react';
import SearchIcon from './../svgs/SearchIcon'
import { useHistory } from "react-router-dom";

import HeaderTop from './../components/headerTop';
import ReactHlsPlayer from 'react-hls-player';


const baseurl = process.env.REACT_APP_FRONT_LINK;

const quizsection = {
    subtitle:"Listen and point"
}
const questions = [{
    "_id":"question_82",
    "type":"listen_choose",
    "name":"Jordan",
    "answers":
    [{"name":"color","correct":true,"fileId":"file_26","fileLink":"attach/1625400172579.png"},
    {"name":"draw","correct":false,"fileId":"file_27","fileLink":"attach/1625400187584.png"},
    {"name":"raise your hand","correct":false,"fileId":"file_28","fileLink":"attach/1625400198207.png"},
    {"name":"read","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"},
    {"name":"listen","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"}

], 
    "stageId":"stage_40",
    "catId":"category_1",
    "unitId":"unit_20",
    "quizId":"quizsection_2",
    "created":1625392118,
    "createdTime":"2021-07-04T09:48:38.383Z",
    "audio":"attach/1625394010949.mp3",
    "updated":1625400721,
    "updatedTime":"2021-07-04T12:12:01.076Z"}
]
function Video() {

    const history = useHistory();

    const [levels,setLevels] = useState([1,2,3,4,5,6])
    const [videoLink, setVideoLink] = React.useState("");

    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    const checkQuestion = (e) => {
     //   history.push(baseurl + "read_match")
        window.location.hash = "#6"
    }

    useEffect(() => {
        document.getElementById("intr").play();

     document.getElementsByTagName("body")[0].style.background = "#EAF6FC";

     fetch("https://api.vimeo.com/videos/173725167",{
        method: "GET",
        headers: {
            "Authorization": "Bearer aeeec5987db81bbfd76e0f272e35468c", 
        }
    }).then(response => response.json()).then(data => {

        if(data.files !== undefined) {
            let files = data.files;
            let findHls = files.findIndex((item) => item.quality === "hls");

            if(findHls !== -1) {
                setVideoLink(data.files[findHls].link_secure);
            }
        }
    });

    }, [])
    return(<div id="mainapp" className="gameapp" style={{color:"#fff"}} >
            <div id="game-container">
                <VerticalMenu active={"Exercise"} />
                <div id="mainquestion">
                <HeaderTop progress={15} />

                    <div className="question-title-container" style={{width:"52vw"}}>
        <h1 className="question-title">Watch the video.</h1>

        <div id="audio-bt" onClick={(e) => {
            document.getElementById("intr").play();
        }}><img src={baseurl + "assets/audio/listen.png"} /></div>
             
        </div>
                    {/* <div className="video-intro" style={{height:"50vh"}}>
                        <img src={baseurl + "assets/game1/play.png"} onClick={videoPlay} id="video-waf" />
                        <video id="video-lin" src={baseurl + "assets/video1.mp4"} controls />

                    </div> */}

                    <div className="video-intro" style={{height:"50vh"}}>
          <ReactHlsPlayer
          src={videoLink}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        /></div>

                    <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>
            </div>
                </div>

            </div>

            <audio id="intr" src={baseurl + "assets/levels/level1/unit1/assets/audios/watch_video.mp3"} />

        
    </div>)
}
export default Video;