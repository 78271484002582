import React,{useState} from 'react'

function SplitText() {
    const [result,setResult] = useState("");
    const [textarea,setTextarea] = useState("");

    const exportData = (e) => {
        let selected = textarea;
        selected = selected.split(" ");
        let final = selected.map((item) => {
            let obj = {};
            obj.start = 0;
            obj.end = 0;
            obj.text = item;
            return obj
        });
        console.log(final);
    }
    return(<div>

        <textarea style={{width:"96%",margin:"1% 2% 0 2%",height:"50vh",boxSizing:"border-box"}} value={textarea} id="textarea" onChange={(e) => setTextarea(e.target.value)} />
        <button style={{margin:"1% 2% 0 2%"}} onClick={exportData}>Export</button>
        <div>{result}</div>
    </div>)
}
export default SplitText;

