import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import ArrowLeftSide from './../svgs/arrowLeftSide'


let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {

    const history = useHistory();

    const intr = useRef(null);
    const audioPlayer = useRef(null)

    const [icon,setIcon] = useState("");



    const [locations,setLocations] = useState([]);

    const [data,setData] =  useState([])
    const [currentCount,setCurrentCount] = useState(0)
    const [answers,setAnswers] = useState([]);
    const [userAnswers,setUserAnswers] = useState([]);

      const checkQuestion = () => {
        let correctAnswers = answers.map((item) => item.answer);
        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"color_circle"
        }
        
        let check = true;
        answers.forEach((answerItem,i) => {
            if(answerItem.answer !== answerItem.userAnswer) {
                check = false;
                answerFormat.pass = false;
            } else {
                answerItem.correct = true;
            }

            
                answerFormat.userAnswers.push({
                    userAnswer:answerItem.userAnswer,
                    correctAnswer:answerItem.answer,
                    correct:answerItem.correct
                });
        });


        if(check === true) {
            document.getElementById("correct-au").play();
        } else {
            document.getElementById("wrong-au").play();
        }
        setTimeout(() => {
            console.log("answerFormat",answerFormat);
            props.nextExercise(answerFormat);
 
        }, 3000);


      }

    useEffect(() => {
        setData(props.questions.answers)
        setAnswers(props.questions.answers[0])
        setIcon(props.questions.icon)
        document.getElementById("intr").play();

    }, [])


    const selectItem = (answer,i) => {

        // let correctAnswers = answers.map((item) => item.answer);

        let currentAnswers = [...answers];

        currentAnswers[i].userAnswer = answer;


        // if(currentAnswers.length === 0) {
        //     currentAnswers = answers.map((item,i) => [] )
        // }
        // currentAnswers[i] = answer;

        // document.getElementById("day-" + i).classList.remove("selected-color");
        // document.getElementById("night-" + i).classList.remove("selected-color");

        // document.getElementById(answer + "-" + i).classList.add("selected-color");

        setAnswers(currentAnswers)
    
    }

    const playIntr = (e) => {
        // audioPlayer.current.currentTime = 5.5;
        document.getElementById("intr").play();
    }
    const pause = (e) => {
        audioPlayer.current.pause();
    }
    return(<div style={{width:"70%"}}>
                <div className="question-title-container">
                    <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                    {/* <img src={baseurl + "assets/game5/listen.svg"} /> */}

                    {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}

                    <h1 className="question-title">{props.questions.title}</h1>

                    </div>
                    <div>
                        <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                    </div>

                </div>

                <div style={{"display":"flex","alignItems":"center","justifyContent":"center"}}>
                    
                    <div style={{display: "flex",flexWrap: "wrap"}}>

                    <div id="charaeter" style={{width: "33%"}}>
                        <img src={baseurl + "assets/levels/level1/unit2/look_color_the_circle/title1.png"} />
                    </div>
                    {answers.map((item,i) => {
                        return(<div style={{display: "flex",justifyContent:"center",alignItems:"center",width:"33%",flexDirection:"column",marginBottom: 40}}>
                            <img src={baseurl + item.img} />
                            <div className="color-select">

                            <div
                                id={"night-" + i}
                                className={item.userAnswer === "night"?"nightclass selected-color":"nightclass"}
                                onClick={() => { 
                                    selectItem("night",i)
                                }}
                                ></div>
                                <div className={item.userAnswer === "day"?"dayclass selected-color":"dayclass"} id={"day-" + i} onClick={() => { 
                                    selectItem("day",i)
                                }}></div>
                                
                            </div>
                        </div>)
                    })}
                    </div>
                    
                </div>




             
        <audio src={`${baseurl}assets/levels/level1/unit2/look_color_circle/look_color_the_circle.mp3`} className="hidden-audio" id="intr" ref={intr} />
                <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />



                <audio src={baseurl + data.audio} ref={audioPlayer} />




        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check answer</button>

                </div>


    </div>)
}
export default DragAndDrop;