import React,{useState} from 'react'
import DragAndDrop from './../engines/DragAndDrop';
import VerticalMenu from './../components/VerticalMenu';
import { useEffect } from 'react';


import HeaderTop from './../components/headerTop';

const baseurl = process.env.REACT_APP_FRONT_LINK;

const quizsection = {
    subtitle:"Listen and point"
}
const questions = [{
    "_id":"question_82",
    "type":"listen_choose",
    "name":"Jordan",
    "answers":
    [{"name":"color","correct":true,"fileId":"file_26","fileLink":"attach/1625400172579.png"},
    {"name":"draw","correct":false,"fileId":"file_27","fileLink":"attach/1625400187584.png"},
    {"name":"raise your hand","correct":false,"fileId":"file_28","fileLink":"attach/1625400198207.png"},
    {"name":"read","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"},
    {"name":"listen","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"}

],
    "stageId":"stage_40",
    "catId":"category_1",
    "unitId":"unit_20",
    "quizId":"quizsection_2",
    "created":1625392118,
    "createdTime":"2021-07-04T09:48:38.383Z",
    "audio":"attach/1625394010949.mp3",
    "updated":1625400721,
    "updatedTime":"2021-07-04T12:12:01.076Z"}
]
function DragAndDropV(props) {
    const [levels,setLevels] = useState([1,2,3,4,5,6])
    


    useEffect(() => {
    
        document.getElementsByTagName("body")[0].style.background = "#EAF6FC";
       
       }, [])

    return(<div id="mainapp" className="gameapp" style={{color:"#fff"}} >
            <div id="game-container">
                {/* <div id="vertical-menu">
                    <div id="vertical-logo"><img src={baseurl + "assets/header/logo.svg"} /></div>
                    <div id="vertical-vmenu">
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/read.svg"} />
                            <div>Read</div>
                        </div>
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/watch.png"} />
                        <div>Watch</div>

                        </div>
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/exercise.png"} />
                        <div>Exercise</div>

                        </div>
                    </div>
                    <div id="vertical-bottom">
                        <img src={baseurl + "assets/header/icons/alert.png"} />     
                        <img src={baseurl + "assets/header/icons/profile2.png"} />
                    </div>
                </div> */}
                <VerticalMenu active={"Exercise"} />
                <div id="mainquestion">
                    {/* <img src={baseurl + "assets/header/icons/level_menu.png"} className="mainquestion-levels" /> */}

                    <HeaderTop progress={10} />

                    <DragAndDrop nextExercise={props.nextExercise} />
                    {/* <img src={baseurl + "assets/header/icons/maingroup.png"} /> */}
                </div>
            </div>
            

        
    </div>)
}
export default DragAndDropV;