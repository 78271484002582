import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import GreenCircle from './../svgs/greenCircle'
const baseurl = process.env.REACT_APP_FRONT_LINK;

function LookReadAndWrite(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);
    const [imgLink,setImgLink] = useState("");

    const [items,setItems] = useState([
        {
            id:"1",
            word:"I am Bob.",
            img:baseurl +"assets/game2/item1.png",
            words:[
                {
                    text:""
                },
                {
                    text:"Bob."
                }
            ]
        },
        {
            id:"2",
            word:"I am Muzzy",
            img:baseurl +"assets/game2/item2.png",
            words:[
                {
                    text:""
                },
                {
                    text:"Muzzy."
                }
            ]
        },
        {
            id:"3",
            word:"I am Corvax",
            img:baseurl +"assets/game2/item3.png",
            words:[
                {
                    text:""
                },
                {
                    text:"Corvax."
                }
            ]
        },
        {
            id:"4",
            word:"Iam Sylvia.",
            img:baseurl +"assets/game2/item5.png",
            words:[
                {
                    text:""
                },
                {
                    text:"Sylvia."
                }
            ]
        }
        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,j,i) => {
        let randList2 = [...randList];

        
        randList2[j].words[i].userAnswer = value;
        setRandList(randList2);
    }
    const correctAnswers = ["I am","i am","I'm","i'm","i'm","I'm","Iam","iam"];
  //  const correctAnswers = props.questions.correctAnswers;
    const checkQuestion = () => {

        let userSelect2 = []
        let randList2 = [...randList];

        let allcorrect = true;


        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:"Look, read, and write.",
            userAnswers:[],
            type:"listen-read-and-write"
        }


        randList2.forEach((answerItem,i) => {
            answerItem.words.forEach((item,j) => {
                if(item.type === "input") {
                    if(item.userAnswer !== item.text) {
                    
                        allcorrect = false;
                        randList2[i].check = false;
                        answerFormat.pass = false;
                    } else {
                        randList2[i].check = true;
                    }

                    answerFormat.userAnswers.push({
                        userAnswer:item.userAnswer,
                        correctAnswer:item.text,
                        correct:randList2[i].check
                    });
                }
  
            });
        })


    
        // userSelect2.forEach((item,i) => {
        //     // console.log(item,correctAnswers,correctAnswers.indexOf(item))
        //     item = item.toLowerCase();
        //     if(correctAnswers.indexOf(item) === -1) {
        //         allcorrect = false;
        //         items2[i].check = false;
        //     } else {
        //         items2[i].check = true;
        //     }
        // })
        setRandList(randList2);
        if(allcorrect === true) {
            document.getElementById("correct-au").play();
        } else {
            document.getElementById("wrong-au").play();
        }
        setCheckAnswers(true);

        setTimeout(() => {
            console.log("answerFormat",answerFormat);
           props.nextExercise(answerFormat);
            //  history.push(baseurl + "talk_to_friend")
        }, 3000);
        
    }


    useEffect(() => {
        setItems(props.questions.answers)
        let currentItems = props.questions.answers;

        if(props.questions.imgLink !== "") {
            setImgLink(props.questions.imgLink);
        }


        setTimeout(() => {
            document.getElementById("intr").play();
        },300)

        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    return(<div id="look_write" className="look_read_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title">Look, read, and write.</h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>
                <div style={{position:"relative"}}>

                    {imgLink !== "" && <img src={baseurl + imgLink} style={{height: 200,width:"auto"}} />}

                {/* <GreenCircle style={{marginLeft:-30,marginTop:-30}}
                style={{
                    marginLeft: "-30px",
                    marginTop: "-30px",
                    width: 200,
                    height: "auto"
                }}
                /> */}

<div id="look_write-items-v1" style={{marginTop:0}}>
    {randList.map((item,j) => {
        return(
        <div className="look_write-item-v1" style={{marginTop:0}}>

            {item.check === undefined ?
                <img src={baseurl + "assets/chara/" + item.img + ".png"} />:item.check === false ?
                
                <img style={{border:"5px solid #F84E53"}} src={baseurl + "assets/chara/" + item.img + ".png"} />:
                <img style={{border:"5px solid #83cd83"}} src={baseurl + "assets/chara/" + item.img + ".png"} />}
            
            <div className="word-v1">
                {item.words.map((subWord,i) => {


                    if(subWord.type === "input") {
                        return(<div className={"editable-input"} ><input spellCheck="false"  maxLength={subWord.text.length} size={subWord.text.length} className="selectortext" style={{width:subWord.text.length * 22,borderBottom:"2px dashed #B7BCD1"}} type="text" onChange={(e) => {changeTxt(e.target.value,j,i)}} />&nbsp;&nbsp;</div>)
                    } else {
                        return(<div className={subWord.text === ""?"editable":"readable"} contentEditable={subWord.text === ""?true:false}>{subWord.text === ""?userSelect[i]:subWord.text}</div>)
                    }

                })}

<audio src={`${baseurl}assets/levels/level1/unit1/assets/audios/look_read_and_write.mp3`} className="hidden-audio" id="intr" />
<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
        <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

            </div>
        </div>)
    })}
</div>

                </div>
 

             

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>

                </div>


    </div>)
}
export default LookReadAndWrite;