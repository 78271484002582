import React,{useState} from 'react'
// import ListenAndChooseTheWord from './../engines/ListenAndChooseTheWord';
import VerticalMenu from './../components/VerticalMenu';
import { useEffect } from 'react';
import SearchIcon from './../svgs/SearchIcon'
import { useHistory } from "react-router-dom";

import HeaderTop from './../components/headerTop';


const baseurl = process.env.REACT_APP_FRONT_LINK;
const audioStyle=  {

    background:"#0B329D",width:30,height:30,alignItems:"center",justifyContent:"center",borderRadius:"100%",padding:10,marginLeft:10,cursor:"pointer",


    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}
const quizsection = {
    subtitle:"Listen and point"
}
const questions = [{
    "_id":"question_82",
    "type":"listen_choose",
    "name":"Jordan",
    "answers":
    [{"name":"color","correct":true,"fileId":"file_26","fileLink":"attach/1625400172579.png"},
    {"name":"draw","correct":false,"fileId":"file_27","fileLink":"attach/1625400187584.png"},
    {"name":"raise your hand","correct":false,"fileId":"file_28","fileLink":"attach/1625400198207.png"},
    {"name":"read","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"},
    {"name":"listen","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"}

], 
    "stageId":"stage_40",
    "catId":"category_1",
    "unitId":"unit_20",
    "quizId":"quizsection_2",
    "created":1625392118,
    "createdTime":"2021-07-04T09:48:38.383Z",
    "audio":"attach/1625394010949.mp3",
    "updated":1625400721,
    "updatedTime":"2021-07-04T12:12:01.076Z"}
]
function OrderWords(props) {

    const history = useHistory();
    const [icon,setIcon] = useState("");
    const [levels,setLevels] = useState([1,2,3,4,5,6])

    const [data,setData] = useState([])

    const [startDrag,setStartDrag] = useState({});
    
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    const checkQuestion = (e) => {
        let data2 = [...data];
        let allcorrect = true;

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"order_words"
        }

        data2.map((item,index) => {

            if( JSON.stringify(item.userAnswer) !== JSON.stringify(item.correctAnswer)){

                item.correct = false;
                allcorrect = false;
            } else {
                item.correct = true;

            }

            let userAnswerTxt = "";
            let corerctAnswerTxt = "";

            item.userAnswer.forEach((item,i) => {
                if(i === 0) {
                    userAnswerTxt += item;
                    corerctAnswerTxt += item.correctAnswer[i];
                } else {
                    userAnswerTxt += item + ", ";
                    corerctAnswerTxt += item.correctAnswer[i] + ", ";
                }
            });

            answerFormat.userAnswers.push({
                userAnswer:userAnswerTxt,
                correctAnswer:corerctAnswerTxt,
                correct:item.correct
            })

            return item;
        });

        setData(data2);

        if(allcorrect === true) {
            document.getElementById("correct-au").play();
        } else {
            document.getElementById("wrong-au").play();
        }

        answerFormat.pass = allcorrect;

        setTimeout(() => {
            props.nextExercise(answerFormat);
        }, 3000);
        
        // 
    }

    useEffect(() => {
        document.getElementById("intr").play();
     document.getElementsByTagName("body")[0].style.background = "#EAF6FC";
     setIcon(props.questions.icon);
     setData(props.questions.data)


    }, [])

    const playAudio = () => {
                document.getElementById("intr").play();

    }

    const allowDrop = (ev) => {     
        ev.preventDefault();
    }

    const dropItemFunc = (e,i) => {

        let data2 = [...data];


        if(startDrag.parent !== undefined) {
            data2[startDrag.parent].userAnswer.push(data2[startDrag.parent].words[startDrag.child])
            delete data2[startDrag.parent].words[startDrag.child];
            setData(data2);
        }
        

    }

    const dragItemFunc = (e,i,j) => {
        setStartDrag({
            parent:i,
            child:j
        })
    }

    
    return(<div id="mainapp" className="gameapp" style={{color:"#fff"}} >
            <div>
                <div id="mainquestion">

                    <div className="question-title-container" style={{width:"60vw"}}>
                        
        <div style={{display:"flex"}}>
            
        {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}
        <h1 className="question-title" style={{paddingLeft:15}}>{props.questions.title}</h1>
            </div>

        <div id="audio-bt" onClick={playAudio}><img src={baseurl + "assets/audio/listen.png"} /></div>
             
        </div>

        <div id="orderwords" style={{
                width: "60vw"
            
        }}>
            {data.map((item,i) => {
                return(<div style={{display:"flex",alignContent:"flex-start",alignItems:"flex-start",marginBottom:50,flexWrap:"wrap"}}>
                    <div style={{width:"15%"}}><img style={item.correct !== undefined?item.correct === true?{border:"5px solid #7EBE40",borderRadius:30,width:"100%",height:"100%"}:{border:"5px solid #FC4F63",borderRadius:30,width:"100%",height:"100%"}:{border:"5px solid rgb(234 246 252)",borderRadius:30,width:"100%",height:"100%"}} src={baseurl + item.image} /></div>
                    <div style={{width:"80%"}}>
                        <div style={{display:"flex",height: 44,marginLeft:28}}>{item.words.map((wordItem,j) => {
                            return(<div draggable={true} onDrag={(e) => dragItemFunc(e,i,j)} style={{background:"#22CBF2",margin:"0px 10px",color:"#fff",borderRadius:20,padding:"10px 20px",fontFamily:"gotham-medium",fontSize:20}}>{wordItem}</div>)
                        })}</div>

                        <div style={{display:"flex",alignItems:"center",marginTop:20,marginLeft:10}}>
                            <div style={{color:"#0B329D",fontFamily:"gotham-medium",fontSize:30,marginRight:10}}>{i + 1}.</div>
                        <div onDragOver={allowDrop} onDrop={(e) => dropItemFunc(e,i)} style={{background:"rgb(34 203 242 / 33%)",width:"100%",height:50,
                        
                        maxWidth: 700,
                        height: 60,
                        marginTop: 20,
                        margin:"0px 0px 0px",
                        display:"flex",
                        alignContent:"center",
                        alignItems:"center",
                        padding:"0px 10px",
                        flex:1
                    }}>{item.userAnswer.map((item2) => {
                            return(<div style={{color:"#fff",background:"#22CBF2",height:"22px",fontSize:20,fontFamily:"gotham-medium",borderRadius:20,padding:"10px 20px",margin:"0px 10px"}}>{item2}</div>)
                        })}</div>

<div style={{color:"#0B329D",fontFamily:"gotham-medium",fontSize:30,marginLeft:10}}>.</div>

                                                                <div style={audioStyle} onClick={(e) => document.getElementById("audioitem-" + i).play()}>
                                                                <audio src={baseurl + item.audio} id={"audioitem-" + i} />

                                <img src={baseurl + "assets/audio/listen.png"} />
                                </div>
                        </div>
                        

                    </div> 
                </div>)
})}
        </div>
                   

                    <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>
            </div>
                </div>

            </div>
            <audio id="intr" src={baseurl + props.questions.audio} />

            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

        
    </div>)
}
export default OrderWords;