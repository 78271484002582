import react, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import ArrowLeftSide from './../svgs/arrowLeftSide'


let words = ["Sit down", "Stand up", "Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {

    const history = useHistory();

    const intr = useRef(null);
    const audioPlayer = useRef(null)

    const [locations, setLocations] = useState([]);

    const [data, setData] = useState({
        audio: "",
        mainTitle: "What’s your name?",
        mainTitleColor: "#89C647",
        tableData: []
    });
    const arrayMoveMutable = (array, fromIndex, toIndex) => {
        const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

        if (startIndex >= 0 && startIndex < array.length) {
            const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

            const [item] = array.splice(fromIndex, 1);
            array.splice(endIndex, 0, item);
        }
        return array;
    }
    const SortableItem = SortableElement(({ value, index2 }) => <div style={{ position: "relative" }} className="blue-border"><div
        style={

            {
                position: "absolute",
                background: "white",
                color: "#0B329D",
                top: -12,
                left: -5,
                width: 30,
                height: 30,
                borderRadius: "100%",
                fontFamily: 'fredokaone',
                fontWeight: "normal",
                fontSize: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }
        }>{index2 + 1}</div><img src={value.img} /></div>);

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div className="sort-item" style={{ display: "flex", alignItems: "center", flexDirection: "row", margin: "auto", width: "100%", maxWidth: 1000 }}>
                {items.map((value, index) => (
                    <SortableItem key={`item-${value}`} index={index} index2={index} value={value} />
                ))}
            </div>
        );
    });




    const [randList, setRandList] = useState([]);


    const shuffle = (array) => {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }
    const checkQuestion = () => {
        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"table-data-table"
        }
        props.nextExercise(answerFormat);
        //history.push(baseurl + "complete"); 
    }

    useEffect(() => {

        setData(props.questions)

        

        let formatList =  [];
        
        
        if(props.questions.tableData !== undefined) {
            // props.questions.tableData.forEach((item,i) => {
            //     formatList[i] = [];
            //     item.forEach((rowItem) => {
            //         formatList[i].push({
            //             text:rowItem,
            //             audio:"assets/levels/level6/unit8/watch_video/ex2/1.mp3"
            //         })
            //     });
            // });
            // console.log(formatList)
        }

    }, [])
    useEffect(() => {
        audioPlayer.current.play();
    },[data]);
  

    const playIntr = (e) => {
        // audioPlayer.current.currentTime = 5.5;
        audioPlayer.current.play();
    }
    const pause = (e) => {
        audioPlayer.current.pause();
    }
    return (<div style={{ width: "70%" }}>
        <div className="question-title-container">
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <img src={baseurl + "assets/game5/listen.svg"} />
                <h1 className="question-title">{props.questions.title}</h1>

            </div>
            <div>
                <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
            </div>

        </div>

        {props.questions.image !== undefined && <div style={{width:"100%",height:"auto",display:"flex",justifyContent:"center",marginBottom:20}}>
            <img src={baseurl + props.questions.image} style={{borderRadius:20}} />
        </div>}





        <audio src={`${baseurl}assets/tracks/t06/track6_v1.mp3`} className="hidden-audio" id="intr" ref={intr} />
        <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
        <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />


        <table className='tableStyleLv' style={{width:"100%",color:"#0B329D",fontFamily:"gotham-medium",fontSize:20}} align="center" cellPadding={15}>
        {data.tableData.map((item, m) => {
            return (<tr>
                {item.map((rowItem,i) => {
                    return(<td onClick={(e) => {
                        document.getElementById(`audio-${m}-${i}`).play();
                    }} style={{background:"#B6EBFA",cursor:"pointer"}} align="center">{rowItem.text}
                        <audio id={`audio-${m}-${i}`} src={baseurl + rowItem.audio} />
                    </td>);
                })}


            </tr>)
        })}
        </table>
        <audio src={baseurl + data.audio} ref={audioPlayer} />






        <div className="button-check">
            <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>

        </div>


    </div>)
}
export default DragAndDrop;