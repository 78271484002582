import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import ArrowLeftSide from './../svgs/arrowLeftSide'


let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

const itemStyle = {"border":"3px solid #7BE0F8","height":"40px",borderColor:"#1BAFDB","borderRadius":"11px","margin":"5px","cursor":"pointer","transition":"ease 0.2s all","width":"40px",borderRadius:"100%","background":"#22cbf2","color":"#fff","display":"flex","alignItems":"center","justifyContent":"center","padding":"9px","fontFamily":"'gotham-bold'"}
function DragAndDrop(props) {

    const history = useHistory();

    const intr = useRef(null);
    const audioPlayer = useRef(null)

    const [icon,setIcon] = useState("");



    const [locations,setLocations] = useState([]);

    const [data,setData] =  useState([])
    const [currentCount,setCurrentCount] = useState(0)
    const [answers,setAnswers] = useState([]);
    const [userAnswers,setUserAnswers] = useState([]);

      const checkQuestion = () => {
        let answers2 = [...answers];

        let answerFormat = {
            pass: true,
            progress: 0,
            questionTitle: props.questions.title,
            userAnswers: [
                {
                    "userAnswer": "",
                    "correctAnswer": "bees",
                    "correct": false
                }
            ],
            type: "colorCircleV2"
        }
        
        let check = true;
        answers2.forEach((answerItem,i) => {

            if(answerItem.userAnswers !== undefined) {

                answerItem.userAnswers = answerItem.userAnswers.sort();
                answerItem.correctAnswers = answerItem.correctAnswers.sort();

                if(_.isEqual(answerItem.userAnswers,answerItem.correctAnswers)) {
                    answers2[i].checkAnswer = true;
                } else {
                    check = false;
                    answers2[i].checkAnswer = false;
                }

                answerFormat.userAnswers.push({
                    userAnswer:answerItem.userAnswers,
                    correctAnswer:answerItem.correctAnswers,
                    correct:answers2[i].checkAnswer
                })
            }
            else if(answerItem.answers !== undefined) {
                if(answerItem.answers.indexOf(answerItem.userAnswer) !== -1) {
                    answers2[i].checkAnswer = true;
                } else {
                    check = false;
                    answers2[i].checkAnswer = false;    
                }

                answerFormat.userAnswers.push({
                    userAnswer:answerItem.userAnswer,
                    correctAnswer:answerItem.answers,
                    correct:answers2[i].checkAnswer
                })
            }
            else if(answerItem.answer !== answerItem.userAnswer) {


                answerFormat.userAnswers.push({
                    userAnswer:answerItem.userAnswer,
                    correctAnswer:answerItem.answer,
                    correct:false
                })
                check = false;
                answers2[i].checkAnswer = false;
            } else {

                answerFormat.userAnswers.push({
                    userAnswer:answerItem.userAnswer,
                    correctAnswer:"",
                    correct:true
                })

                answers2[i].checkAnswer = true;
            }
        });

        setAnswers(answers2);

        if(check === true) {
            document.getElementById("correct-au").play();
        } else {
            document.getElementById("wrong-au").play();
        }

        answerFormat.pass = check;
        setTimeout(() => {
           props.nextExercise(answerFormat);
 
        }, 3000);


      }

    useEffect(() => {
        setData(props.questions.answers)
        setAnswers(props.questions.answers[0])
        setIcon(props.questions.icon)
        document.getElementById("intr").play();

    }, [])


    const selectItem = (answer,i) => {

        // let correctAnswers = answers.map((item) => item.answer);

        let currentAnswers = [...answers];

        if(currentAnswers[i].userAnswers !== undefined) {
            if(currentAnswers[i].userAnswers.indexOf(answer) === -1) {
                currentAnswers[i].userAnswers.push(answer);
            } else {
                currentAnswers[i].userAnswers = currentAnswers[i].userAnswers.filter((item) => item !== answer);
            }
        }
        currentAnswers[i].userAnswer = answer;


        // if(currentAnswers.length === 0) {
        //     currentAnswers = answers.map((item,i) => [] )
        // }
        // currentAnswers[i] = answer;

        // document.getElementById("day-" + i).classList.remove("selected-color");
        // document.getElementById("night-" + i).classList.remove("selected-color");

        // document.getElementById(answer + "-" + i).classList.add("selected-color");

        setAnswers(currentAnswers)
    
    }

    const playIntr = (e) => {
        // audioPlayer.current.currentTime = 5.5;
        document.getElementById("intr").play();
    }
    const pause = (e) => {
        audioPlayer.current.pause();
    }
    return(<div style={{width:"70%"}}>
                <div className="question-title-container">
                    <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                    {/* <img src={baseurl + "assets/game5/listen.svg"} /> */}

                    {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}

                    <h1 className="question-title" dangerouslySetInnerHTML={{ __html: props.questions.title }}></h1>

                    </div>
                    <div>
                        <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                    </div>

                </div>
                {props.questions.images !== undefined && 
                    <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                        {props.questions.images.map((item) => {
                            return(<div><img src={baseurl + item} /></div>)
                        })}
                    </div>
                }

                <div style={{"display":"flex","alignItems":"center","justifyContent":"center"}}>
                    
                    <div style={{display: "flex",flexWrap: "wrap"}}>

                        {props.questions.mainTitle !== undefined && 
                        <div id="charaeter" style={{width: "33%"}}>
                        <img src={baseurl + props.questions.mainTitle} />
                    </div>
                        }
                    
                    {answers.map((item,i) => {


                        let itemInnerStyle = {};
                        let currentAnswers = [];

                        if(props.questions.style !== undefined) {
                            itemInnerStyle = props.questions.style;
                        }
                        return(<div style={{display: "flex",justifyContent:"center",alignItems:"center",width:"33%",flexDirection:"column",marginBottom: 40}}>
                            <div style={{background:"#22CBF2",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",borderRadius:30}}>
                            <img src={baseurl + item.img} style={{border:"5px solid #22CBF2",borderRadius:30,marginBottom:0}} />
                            <div className="color-select" style={{paddingBottom:10}}>

                                    {item.options.map((subItem,j) => {
                                    if(item.userAnswers !== undefined) {
                                        currentAnswers = item.userAnswers;
                                        
                                        if(currentAnswers.length > 0) {
                                            console.log(currentAnswers,subItem)
                                        }

                                    } else {
                                        currentAnswers = [item.userAnswer];
                                    }
                                    return(<>
                                    <div
                                    style={
                                        item.checkAnswer === undefined?
                                        
                                        currentAnswers.indexOf(subItem) === -1?{...itemStyle,...itemInnerStyle}:
                                        {...itemStyle,background:"#F9CB2E",color:"#0B329D",borderColor:"#F9CB2E",...itemInnerStyle}:

                                        currentAnswers.indexOf(subItem) !== -1?
                                            item.checkAnswer === true?{...itemStyle,background:"#7EBE40",...itemInnerStyle}:{...itemStyle,background:"#FC4F63",...itemInnerStyle}:{...itemStyle,...itemInnerStyle}
                                        
                                    }
                                    
                                    onClick={() => { 
                                        selectItem(subItem,i)
                                    }}
                                    >{
                                        props.questions.mainType === "image"?<img src={baseurl + props.questions.mainOptions[j]} />:subItem}</div></>)
                                    }
                                    )}
                            
                               
                                
                            </div>
                            </div>
                           
                        </div>)
                    })}
                    </div>
                    
                </div>




             
        <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" ref={intr} />
                <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />



                <audio src={baseurl + data.audio} ref={audioPlayer} />




        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check answer</button>

                </div>


    </div>)
}
export default DragAndDrop;