import * as React from "react"

function WhiteLayer(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={437} height={705} {...props}  viewBox="0 0 437 705">
      <defs>
        <clipPath id="prefix__a22">
          <path
            data-name="Rectangle 1531"
            fill="#fff"
            stroke="#707070"
            d="M0 0h437v705H0z"
          />
        </clipPath>
      </defs>
      <g data-name="Mask Group 66">
        <g data-name="Group 2324" clipPath="url(#prefix__a22)">
          <path
            data-name="Path 2363"
            d="M436.005 308.077c16.607-126.824-172.117-572.216-748.863-609.96s-679.41 375.939-674.88 522.39 266.395 501.169 711.982 538.385c314.682 26.28 260.329-123.19 408.289-188.111s279.569-80.178 303.472-262.704z"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export default WhiteLayer;