import * as React from "react"

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={918} height={227} {...props} viewBox={"0 0 918 227"}>
      <defs>
        <clipPath id="prefix__a">
          <path
            data-name="Rectangle 1530"
            transform="translate(522)"
            fill="#fff"
            stroke="#707070"
            d="M0 0h918v227H0z"
          />
        </clipPath>
      </defs>
      <g
        data-name="Mask Group 65"
        transform="translate(-522)"
        clipPath="url(#prefix__a)"
      >
        <g data-name="Group 4028">
          <g data-name="Group 2331" opacity={0.574}>
            <g
              data-name="Group 2326"
              transform="translate(522.995 -135.749)"
              fill="#8fe3fb"
            >
              <circle
                data-name="Ellipse 12"
                cx={10.109}
                cy={10.109}
                r={10.109}
                transform="rotate(135 660.802 419.081)"
              />
              <circle
                data-name="Ellipse 13"
                cx={15.768}
                cy={15.768}
                transform="rotate(166.717 437.095 209.18)"
                r={15.768}
              />
              <circle
                data-name="Ellipse 14"
                cx={18.82}
                cy={18.82}
                transform="rotate(166.717 258.83 187.713)"
                r={18.82}
              />
              <path
                data-name="Path 2362"
                d="M9.73-.427v202.809a110.419 110.419 0 00201.064.5 85.378 85.378 0 00120.106 3.583q10.815 6.623 22.216 12.342a36.462 36.462 0 1066.7 24.015 301.837 301.837 0 00175.741-12.417 25.9 25.9 0 0047.449 11.619 107.6 107.6 0 00190.533-39.643h35.852a48.65 48.65 0 0092.856 4.067 161.665 161.665 0 00194.328-2.506 128.153 128.153 0 00193.719 2.508 97.055 97.055 0 0064.138-4.069h62.9V0z"
              />
            </g>
            <g data-name="Group 2327">
              <g
                data-name="Group 2325"
                transform="translate(531.02 -77.383)"
                fill="none"
                stroke="#8fe3fb"
                strokeMiterlimit={10}
                strokeWidth={3}
              >
                <circle
                  data-name="Ellipse 15"
                  cx={112.921}
                  cy={112.921}
                  r={112.921}
                  transform="translate(701.72)"
                />
                <circle
                  data-name="Ellipse 16"
                  cx={76.454}
                  cy={76.454}
                  r={76.454}
                  transform="translate(1323.053 38.178)"
                />
                <circle
                  data-name="Ellipse 17"
                  cx={29.943}
                  cy={29.943}
                  r={29.943}
                  transform="translate(823.678 198.129)"
                />
                <circle
                  data-name="Ellipse 18"
                  cx={98.296}
                  cy={98.296}
                  r={98.296}
                  transform="translate(178.961 40.855)"
                />
                <circle
                  data-name="Ellipse 24"
                  cx={121.296}
                  cy={121.296}
                  r={121.296}
                  transform="translate(996.961 69.79)"
                />
                <circle
                  data-name="Ellipse 19"
                  cx={32.884}
                  cy={32.884}
                  r={32.884}
                  transform="translate(0 157.664)"
                />
              </g>
            </g>
          </g>
          <circle
            data-name="Ellipse 26"
            cx={111}
            cy={111}
            r={111}
            transform="translate(522 -90)"
            fill="#60d9f7"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
