import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import ReactHlsPlayer from 'react-hls-player';

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {


    const intr = useRef(null);


    const arrayMoveMutable = (array, fromIndex, toIndex) => {
        const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;
    
        if (startIndex >= 0 && startIndex < array.length) {
            const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;
    
            const [item] = array.splice(fromIndex, 1);
            array.splice(endIndex, 0, item);
        }
        return array;
    }
    const SortableItem = SortableElement(({value,index2}) => <div style={{position:"relative"}} className="blue-border"><div
    style={

        {
            position: "absolute",
            background: "white",
            color: "#0B329D",
            top: -12,
            left:-5,
            width:30,
            height:30,
            borderRadius: "100%",
            fontFamily: 'fredokaone',
            fontWeight: "normal",
            fontSize:20,
            display:"flex",
            alignItems:"center",
            justifyContent:"center"
        }
    }>{index2 + 1}</div><img src={value.img} /></div>);

    const SortableList = SortableContainer(({items}) => {
        return (
          <div className="sort-item" style={{display:"flex",alignItems:"center",flexDirection:"row",margin:"auto",width:"100%",maxWidth:1000}}>
            {items.map((value, index) => (
              <SortableItem key={`item-${value}`} index={index} index2={index} value={value} />
            ))}
          </div>
        );
      });
      

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState({});
    const history = useHistory();
    const [videoLink, setVideoLink] = useState("");

    const [items,setItems] = useState([
        {
            id:"1",
            img:baseurl + "assets/avatars/home/meet/icons/item1.png",
        },
        {
            id:"2",
            img:baseurl + "assets/avatars/home/meet/icons/item2.png",
        },
        {
            id:"3",
            img:baseurl + "assets/avatars/home/meet/icons/item3.png",
        },
        {
            id:"4",
            img:baseurl + "assets/avatars/home/meet/icons/item4.png",
        },
        {
            id:"5",
            img:baseurl + "assets/avatars/home/meet/icons/item5.png",
        },
        {
            id:"6",
            img:baseurl + "assets/avatars/home/meet/icons/item6.png",
        }
    ]);

        const [randList,setRandList] = useState([]);
    

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      const checkQuestion = () => {

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:"Watch the video. Number the pictures.",
            userAnswers:[],
            type:"watch-video-numbe-the-pictures"
        }


        let answers_data = document.querySelectorAll(".blue-border img");


        if(JSON.stringify(randList) === JSON.stringify(items)) {


            answers_data.forEach((answer_item) => {
                answer_item.style.border = "6px solid #83cd83";
            })

            document.getElementById("correct-au").play();



        } else {
            // console.log(false)


            answers_data.forEach((answer_item) => {
                answer_item.style.border = "6px solid red";
            })

            document.getElementById("wrong-au").play();
            answerFormat.pass = false;

        }

        randList.forEach((item,i) => {
            answerFormat.userAnswers.push({
                userAnswer:item.id,
                correctAnswer:items[i].id,
                correct:item.id === items[i].id?true:false
            })
        });

        setTimeout(() => {
          //  history.push(baseurl + "ListenReadAndSay"); 
          props.nextExercise(answerFormat);
          window.location.hash = "#8";
        }, 3000);
      }

    useEffect(() => {
        let currentItems = [...items];

        currentItems = shuffle(currentItems);

        setRandList(currentItems);

        // setTimeout(() => {
             intr.current.play();
        // },300)


        fetch("https://api.vimeo.com/videos/173725167",{
            method: "GET",
            headers: {
                "Authorization": "Bearer aeeec5987db81bbfd76e0f272e35468c", 
            }
        }).then(response => response.json()).then(data => {

            if(data.files !== undefined) {
                let files = data.files;
                let findHls = files.findIndex((item) => item.quality === "hls");
    
                if(findHls !== -1) {
                    setVideoLink(data.files[findHls].link_secure);
                }
            }
        });
        
    }, [])

    const playIntr = (e) => {
         document.getElementById("intr").play();
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        let items = [...randList];
        items =  arrayMoveMutable(items, oldIndex, newIndex);
        setRandList(items)
    }
    
        const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }


    return(<div style={{width:"70%"}}>
                <div className="question-title-container">
                    <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                    <img src={baseurl + "assets/game5/listen.svg"} />
                    <h1 className="question-title">Watch the video. Number the pictures.</h1>

                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                </div>
             
        {/* <audio src={`${baseurl}assets/audio/p4/p1.wav`} className="hidden-audio" id="intr" ref={intr} /> */}

        <audio src={baseurl + "assets/levels/level1/unit1/assets/audios/watch_the_video_number_the_pictures.mp3"} className="hidden-audio" id="intr" ref={intr} />

                <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                {/* <div className="video-intro" style={{height: "30vh",width: "30vw",margin: "0 auto 50px"

                }}><video id="video-lin" src={baseurl + "assets/video1.mp4"} controls /></div> */}

<div className="video-intro" 
style={{height: "30vh",width: "30vw",margin: "0 auto 50px"

}}
>
          <ReactHlsPlayer
          src={videoLink}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        /></div>

                <SortableList onSortEnd={onSortEnd} items={randList} axis={"x"} />
        {/* <SortableContainer style={{display:"flex",alignItems:"center",flexDirection:"row",margin:"auto"}}>
            {randList.map((randItem) => {
                return);
            })}
        </SortableContainer> */}
        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>

                </div>


    </div>)
}
export default DragAndDrop;