import React,{useEffect,useState} from 'react';
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux';

const baseurl = process.env.REACT_APP_FRONT_LINK;
const progressHeight = 12;
function Headertop(props) {
    const user = useSelector((state) => state.user);
    const [header,setHeader] = useState("assets/vertical/headertop.png")

    let progressStyle = {background:"#22CBF2",height:"100%",width:`${props.progress}%`};

    useEffect(() => {
        console.log(props.unit)

        if(props.unit === "U5") {
            setHeader("assets/header/unit5.png")
        }
        else if(props.unit === "U4") {
            setHeader("assets/header/unit4.png")
        }

        else if(props.unit === "U3") {
            setHeader("assets/header/header2.png")
        }
        else if(props.level === "2") {
            setHeader("assets/header/header.png")
        } else {
            setHeader("assets/vertical/headertop.png")
        }
    }, [props.level])

    return(<div id="headertop-section" className="mainquestion-levels">
                            <img src={baseurl + header} />
                            <div style={{width:"100%",height:progressHeight,display:"flex",justifyContent:"center",alignItems:"center",marginTop:25}}>
                                <div style={{color:"red",fontFamily:"gotham-bold",color:"#22CBF2",paddingRight:10,width:40}}>{parseInt(props.progress)}%</div>
                                <div style={{background:"#D0D3E1",borderRadius:6,width:`100%`,height:progressHeight,overflow:"hidden"}}>
                                    <div style={progressStyle}></div>
                                </div>
                            </div>


</div>)
}
export default Headertop;