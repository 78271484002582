import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import TickIcon from './../components/svgs/tick'
import CrossIcon from './../components/svgs/cross'


let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    
    const history = useHistory();
    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState([
        {
            id:"1",
            word:"Hello !",
            img:baseurl +"assets/game2/item1.png",
            spans:[
                {
                    text:"H",
                    type:"text",
                    userAnswer:""
                },
                {
                    text:"e",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"l",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"l",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"o",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"!",
                    type:"text",
                    userAnswer:""
                }
            ],
            words:["H","","","","","!"]
        },
        {
            id:"2",
            word:"What's your name ?",
            img:baseurl +"assets/game2/item2.png",
            spans:[
                {
                    text:"What's",
                    type:"text",
                    userAnswer:""
                },
                {
                    text:"your",
                    type:"text",
                    userAnswer:""
                },
                {
                    text:"n",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"a",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"m",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"e",
                    type:"text",
                    userAnswer:""
                },
                {
                    text:"?",
                    type:"text",
                    userAnswer:""
                }
            ],
            words:["What's","your","","","","","?"]
        },
        {
            id:"3",
            word:"I ' am Jenny.",
            img:baseurl +"assets/game2/item3.png",
            spans:[
                {
                    text:"I",
                    type:"text",
                    userAnswer:""
                },
                {
                    text:"'",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"am",
                    type:"input",
                    userAnswer:"",
                    answers:["am","m"]
                },
                {
                    text:"Jenny.",
                    type:"input",
                    userAnswer:""
                }
            ],
            words:["","'","","Jenny."]
        }
    ]);

    const [items,setItems] = useState([
        {
            id:"1",
            word:"Hello!",
            spans:[
                {
                    text:"H",
                    type:"text",
                    userAnswer:""
                },
                {
                    text:"e",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"l",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"l",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"o",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"!",
                    type:"text",
                    userAnswer:""
                }
            ],
            img:baseurl +"assets/game2/item1.png",
            words:["H","","","","","!"]
        },
        {
            id:"2",
            word:"What's your name ?",
            img:baseurl +"assets/game2/item2.png",
            words:["What's","your","","","","","?"],
            spans:[
                {
                    text:"What's",
                    type:"text",
                    userAnswer:""
                },
                {
                    text:"your",
                    type:"text",
                    userAnswer:""
                },
                {
                    text:"n",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"a",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"m",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"e",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"?",
                    type:"text",
                    userAnswer:""
                }
            ]
        },
        {
            id:"3",
            word:"I' am Jenny.",
            img:baseurl +"assets/game2/item3.png",
            words:["","'","","Jenny."],
            spans:[
                {
                    text:"I",
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"'",
                    type:"text",
                    userAnswer:""
                },
                {
                    text:"am",
                    answers:["am","m"],
                    type:"input",
                    userAnswer:""
                },
                {
                    text:"Jenny.",
                    type:"text",
                    userAnswer:""
                }
            ]
        }
        ]);

        const [randList,setRandList] = useState([]);
     
        const checkQuestion = () => {
            let items2 = [...randList]; 
            let allcorrect = true;

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:"Complete.",
                userAnswers:[],
                type:"complete"
            }


            items2.map((item,i) => {

                item.spans.forEach((subItem,j) => {
                    if(subItem.type === "input") {

                        if(subItem.answers !== undefined) {
                            if(subItem.answers.indexOf(subItem.text.toLowerCase()) === -1) {
                                subItem.correctAnswer = false;
                                allcorrect = false;
                                item.correct = false;
                            } else {
                                subItem.correctAnswer = true;
                                items.correct = true;
                            }

                            answerFormat.userAnswers.push({
                                userAnswer:subItem.text.toLowerCase(),
                                correct:item.correct,
                                correctAnswer:subItem.answers[0]
                            });
                        }
                        else if(subItem.userAnswer.toLowerCase() === subItem.text.toLowerCase()) {
                            subItem.correctAnswer = true;
                            item.correct = true;

                            answerFormat.userAnswers.push({
                                userAnswer:subItem.userAnswer.toLowerCase(),
                                correct:true,
                                correctAnswer:subItem.text.toLowerCase()
                            });

                        } else {
                            subItem.correctAnswer = false;
                            allcorrect = false;
                            item.correct = false;

                            answerFormat.userAnswers.push({
                                userAnswer:subItem.userAnswer.toLowerCase(),
                                correct:false,
                                correctAnswer:subItem.text.toLowerCase()
                            });
                        }

                        
                    }
                });
                return item;
            })

            if(allcorrect === true) {
                document.getElementById("correct-au").play();

            } else {
                document.getElementById("wrong-au").play();

            }

            answerFormat.pass = allcorrect;

            setRandList(items2);
            setTimeout(() => {
                // history.push(baseurl + "look_listen_say")
               window.location.hash = "#15";
               props.nextExercise(answerFormat);
            }, 3000);
            
        }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i,j) => {
          let userSelect2 = [...randList];
          userSelect2[i].spans[j].userAnswer = value;

          setRandList(userSelect2)


      }

    useEffect(() => {
        setTimeout(() => {
            document.getElementById("intr").play();
        },300)
        let currentItems = [...items];

        // currentItems = shuffle(currentItems);

        setRandList(currentItems);
        document.getElementById("intr").play();

    }, [])

    
    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    return(<div id="complete_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title">Complete.</h1>
                </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>

                </div>
        <div id="complete-items-v1" style={{minWidth:"60vw"}}>
            {randList.map((item,i) => {
                return(<div className="items-v1" style={{position:"relative"}}>
                    
                    {item.spans.map((subItem,j) => {
                    if(subItem.type === "input") {
                        let char_len = item.word.split(" ");
                        
                        return <>
                        <span><input spellCheck="false"  
                         type="text" onChange={(e) => {changeTxt(e.target.value,i,j)}} maxLength={subItem.text.length} size={subItem.text.length} /></span>

{/* {subItem.correctAnswer === true && <div style={{position:"absolute",right:20,top:25}}><TickIcon /></div>}
                    {subItem.correctAnswer === false && <div style={{position:"absolute",right:20,top:25}}><CrossIcon /></div>} */}
        

                        </>
                    } else {
                        return <><span>{subItem.text}</span>
                        
                        {/* {subItem.correctAnswer === true && <div style={{position:"absolute",right:20,top:25}}><TickIcon /></div>}
                    {subItem.correctAnswer === false && <div style={{position:"absolute",right:20,top:25}}><CrossIcon /></div>} */}
                        </>
                    }
                })}
                {item.correct === true && <div style={{position:"absolute",right:20,top:25}}><TickIcon /></div>}
                    {item.correct === false && <div style={{position:"absolute",right:20,top:25}}><CrossIcon /></div>}



                    

                </div>);
            })}
        </div>

            {/* <audio src={`${baseurl}assets/audio/audio1/complete.wav`} className="hidden-audio" id="intr" /> */}
            <audio src={`${baseurl}assets/levels/level1/unit1/assets/audios/complete.mp3`} className="hidden-audio" id="intr" />

            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

            <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>
            </div>


    </div>)
}
export default DragAndDrop;