import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import GreenCircle from './../svgs/greenCircle'
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);
    const [icon,setIcon] = useState("");
    
    
    const [items,setItems] = useState([
        {
            id:"1",
            word:"bob",
            img:baseurl +"assets/game2/item1.png",
            words:[
                {
                    text:""
                },
                {
                    text:"ob"
                }
            ]
        },
        {
            id:"2",
            word:"Muzzy",
            img:baseurl +"assets/game2/item2.png",
            words:[
                {
                    text:""
                },
                {
                    text:"uzzy"
                }
            ]
        },
        {
            id:"3",
            word:"Corvax",
            img:baseurl +"assets/game2/item3.png",
            words:[
                {
                    text:""
                },
                {
                    text:"orvax"
                }
            ]
        },
        {
            id:"4",
            word:"Queen",
            img:baseurl +"assets/game2/item4.png",
            words:[
                {
                    text:""
                },
                {
                    text:"ueen"
                }
            ]
        },
        {
            id:"5",
            word:"Sylvia",
            img:baseurl +"assets/game2/item5.png",
            words:[
                {
                    text:""
                },
                {
                    text:"ylvia"
                }
            ]
        }
        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,j,i) => {
        // let userSelect2 = [...userSelect];
        // userSelect2[i] = value;
        //     setUserSelect(userSelect2);

            let randList2 = [...randList];
            randList2[j].words[i].userAnswer = value;
            setRandList(randList2);
    
    }

    const checkQuestion = () => {
        let randList2 = [...randList];
        let allcorrect = true;

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"look_write"
        }

        // console.log(randList)

        randList2.forEach((randItem,i) => {
            randItem.words.forEach((wordItem,j) => {

                if(wordItem.type === "input") {
                    wordItem.userAnswer = wordItem.userAnswer.replaceAll("’","'");
                    wordItem.text = wordItem.text.replaceAll("’","'");

                    if(wordItem.answers !== undefined) {
                        wordItem.answers = wordItem.answers.map((item) => item.toLowerCase());
                        wordItem.answers = wordItem.answers.map((item) => item.replaceAll("’","'"));

                    }
                    else if(wordItem.userAnswer.toLowerCase() !== wordItem.text.toLowerCase()) {
                        randList2[i].correct = false;
                        allcorrect = false;
                    } else {
                        randList2[i].correct = true;
                    }

                    answerFormat.userAnswers.push({
                        userAnswer:wordItem.userAnswer.toLowerCase(),
                        correctAnswer:wordItem.text.toLowerCase(),
                        correct:randList2[i].correct
                    })
                }

            }) 
        });

        setRandList(randList2);


        if(allcorrect === true) {
            document.getElementById("correct-au").play();
        } else {
            document.getElementById("wrong-au").play();
            answerFormat.pass = false;
        }
        // setRandList(items2);
        setCheckAnswers(true);

        setTimeout(() => {
            // history.push(baseurl + "look_notice")
            props.nextExercise(answerFormat);
        }, 3000);
        
    }


    
    const getSentence = (text) => {
       return text.split(' ').map((w, id) => {
            let end = w.search(">");
            let start = w.search(">");

            return {word:w.split("<").pop().split(">"),start:start,end:end}

        //   if (w.startsWith('<')) {
        //     const m = w.match(/[a-z-A-Z]+/);
        //     return { id, type: 'answer', placed: false, displayed: ''};
        //   }
        //   return { id, text: w, type: 'word' };
       });
      };


    useEffect(() => {
        let currentItems = props.questions.answers;


        setIcon(props.questions.icon)
    

        setTimeout(() => {
            document.getElementById("intr").play();
        },300)
        setItems(currentItems)
        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    return(<div id="look_write" style={props.questions.mainWidth !== undefined?{maxWidth:props.questions.mainWidth}:{}}>
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                {/* <img src={baseurl + "assets/game5/write.svg"} /> */}

                {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}


                    <h1 className="question-title">{props.questions.title}</h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr} style={{width:"auto",height:"auto"}}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>
                {props.questions.options !== undefined && <div style={{display:"flex",alignItems:"center",justifyContent:"center",background:"#B3EBF5",padding:"20px 0px",margin:"0px 0px 30px 0px"}}>
                    {props.questions.options.map((item) => {
                        return(<div style={{color:"rgb(11, 50, 157)",fontFamily:"gotham-medium",padding:"0px 20px",textAlign:"center",borderRadius:"20px"}}>{item}</div>)
                    })}    
                </div>}

        <div id="look_write-items-v1">
            {randList.map((item,j) => {

                var lines = [];
                    
                
                return(
                <div className="look_write-item-v1" style={{marginTop:0,marginBottom:30,alignItems:"center"}}>

                    {checkAnswers === false ?<img src={baseurl + "assets/chara/" +item.img + ".png"} />:item.correct === false ?<img style={{border:"5px solid #F84E53"}} src={baseurl + "assets/chara/" +item.img + ".png"} />:<img style={{border:"5px solid #7EBE40"}} src={baseurl + "assets/chara/" +item.img + ".png"} />}
                    {/* {item.correct === undefined ?<img style={{border:"8px solid #F84E53"}} src={item.img} />:
                    item.correct === true?<img style={{border:"8px solid #83cd83"}} src={item.img} />:<img src={item.img} />
                    } */}
                    <div className="word-v1" style={{
                    position:"relative",
    background: "white",
    border: "3px solid #22cbf2",
    margin: "0px 20px 20px",
    boxShadow:"rgb(34 203 242) -4px 4px 0px",
    padding:"10px 20px 20px"
                }}
                    
                    >

{j % 2 === 0?
    <img src={ baseurl + "assets/game8/arrow.png"} style={{width: "auto",height: "auto", border: "0px",position: "absolute", left: "-19px", top: "17%",borderRadius: "0px"}} />
:
<img src={ baseurl + "assets/game8/arrow.png"} style={{width: "auto",height: "auto", border: "0px",transform:"rotate(180deg)",position: "absolute", right: "-19px", top: "17%",borderRadius: "0px"}} />
}


                        
                        {item.words.map((subWord,i) => {

                            let currentWidth = subWord.text.length * 22
                            if(props.questions.width !== undefined) {
                                currentWidth = props.questions.width;
                            }

                            if(subWord.type === "input") {
                                return(<div className={"editable-input"} style={{fontSize:20}} ><input spellCheck="false"  maxLength={subWord.text.length} size={subWord.text.length} className="selectortext" style={{width:currentWidth,borderBottom:"2px solid #B7BCD1",fontSize:20,textAlign:"left"}} type="text" onChange={(e) => {changeTxt(e.target.value,j,i)}} />&nbsp;&nbsp;</div>)
                            } else {
                                return(<div style={{fontSize:20,padding:0}} className={subWord.text === ""?"editable":"readable"} contentEditable={subWord.text === ""?true:false}>{subWord.text === ""?userSelect[i]:subWord.text}&nbsp;</div>)
                            }
                      
                        })}
                        {/* {item.words.map((subWord,i) => {


                            if(subWord.text === "") {
                                return(<div className={"editable-input"} ><input spellCheck="false"  maxLength={1} style={{borderBottom:"2px solid #B7BCD1",textTransform:"capitalize"}} type="text" onChange={(e) => {changeTxt(e.target.value,j)}} /></div>)
                            } else {
                                return(<div className={subWord.text === ""?"editable":"readable"} contentEditable={subWord.text === ""?true:false}>{subWord.text === ""?userSelect[i]:subWord.text}</div>)
                            }

                        })} */}

{/* <audio src={`${baseurl}assets/muzzy/audio/look_write.mp3`} className="hidden-audio" id="intr" /> */}

{props.questions.audioNew === true?<audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />:<audio src={`${baseurl}assets/levels/level1/unit1/assets/audios/look_write.mp3`} className="hidden-audio" id="intr" />}

<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                    </div>
                </div>)
            })}
        </div>

             

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>

                </div>


    </div>)
}
export default DragAndDrop;