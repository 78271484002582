import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Animate } from 'react-simple-animate';
import Body from './../components/body'
import Face from './../components/face'; 

const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]

function ListenAndChooseTheWord(props) {
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState("");
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [icon,setIcon] = useState("");
    const [name,setName] = useState("");
    const [answerFormat,setAnswerFormat] = useState({
        pass:true,
        progress:0,
        questionTitle:question.title,
        userAnswers:[],
        type:"listen-and-repeat"
    });

    const history = useHistory();

    const instr = useRef();



    useEffect(() => {
        
        // let elements = document.getElementsByClassName("rect-text");

        // let i = 0;

        // while(i < elements.length) {
        //     elements[i].addEventListener("click",selectItem);
        //     i++;
        // }

        setTimeout(() => {
            document.getElementById("intr2").play();
        },500)
        setTimeout(() => {
            document.getElementById("intr").play();
        },3000)


    }, [data])
    useEffect(() => {
        setCurrent(0);

            let selected = props.questions;
        // console.log(props.questions)
            setIcon(props.questions.icon);
            let finalData = [];

            if(selected !== undefined) {
                selected.answers.forEach((item,i) => {
                    let p2 = JSON.parse(JSON.stringify(selected))
        
                    finalData.push(p2);
                })
        
                finalData.map((item,i) => {
                    item.answers[i].correct = true;
                    return item;
                })
            }    
           
            // selected = _.shuffle(selected);
            
            setData(finalData);

            if(finalData.length > 0) {
                finalData[0].complete = true;
                finalData[0].answers = _.shuffle(finalData[0].answers);
                let find = finalData[0].answers.findIndex((item) => item.correct === true);

                setAudio(finalData[0].answers[find].audio);

                setQuestion(finalData[0])



             
           
            }
        
                

    }, [props.questions])

    const checkQuestion = (e) => {
        
        let counterNext = current;
       // let currentData = data;

        counterNext++;

        let answerFormat2 = {...answerFormat};


        if(question.answers[userSelect].correct === true) {
           // props.playAudio(true);

            answerFormat2.userAnswers.push({
                userAnswer:question.answers[userSelect].name,
                correctAnswer:question.answers[userSelect].name,
                correct:true
            })

            setShowCorrect(true);
            document.getElementById(`${name}-rect`).classList.add("correct-answer");

            //#7EBE40
            document.getElementById("correct-au").play();
        } else {
            
            // props.playAudio(false);
            setShowCorrect(true);

            let findCorrect = question.answers.findIndex((item) => item.correct === true);

            answerFormat2.userAnswers.push({
                userAnswer:question.answers[userSelect].name,
                correctAnswer:question.answers[findCorrect].name,
                correct:false
            })

            // document.getElementById(`${name}-rect`).style.fill = "red";
            let correctList = question.answers.filter((item) => item.correct === true);
            

            document.getElementById(`${name}-rect`).classList.add("wrong-answer");
            document.getElementById( correctList[0].name + '-rect').classList.add("correct-answer");

            

            answerFormat2.pass = false;
            document.getElementById("wrong-au").play();

        }
        setAnswerFormat(answerFormat2);

        setTimeout(() => {

            if(counterNext < data.length) {

                let correct_item = document.getElementsByClassName("correct-answer");
                let wrong_item = document.getElementsByClassName("wrong-answer");
                let selected_item = document.getElementsByClassName("selected-item");

                if(correct_item.length > 0) {
                    correct_item[0].classList.remove("correct-answer");
                }
                if(wrong_item.length > 0) {
                    wrong_item[0].classList.remove("wrong-answer");
                }

                if(selected_item.length > 0) {
                    selected_item[0].classList.remove("selected-item");
                }


                let selected =  data[counterNext];
                selected.answers = _.shuffle(selected.answers);
                let find = selected.answers.findIndex((item) => item.correct === true);
    
                setQuestion(selected);
                setAudio(selected.answers[find].audio);
                setShowCorrect(false);
                setUserSelect("");
                setCurrent(counterNext);  
                document.getElementById("intr").play();

                setTimeout(() => {
                },300)

            } else {
                props.nextExercise(answerFormat2);
              //  history.push(baseurl + "drag")
            }
        }, 3000);



    }

    const playAudio = (e) => {
        if(document.getElementById("question-audio") !== null) {
            document.getElementById("question-audio").play();
        }
    }

    const checkUserSelect = (userSelect,indx) => {
        if(userSelect === indx) {
            return ("selected multiple-question-item");
        } else {
            return ("multiple-question-item");
        }
    }

    const checkCorrect = (userSelect,indx,correct) => {

        if(correct === true) {
            return("multiple-question-item selected correct")
        } else {
            if(userSelect === indx) {
                return ("selected wronganswer multiple-question-item");
            } else {
                return ("multiple-question-item");
            }
        }
        
    }

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }


    const selectItem = (value) => {
        let item = document.getElementsByClassName("selected-item");


        let find = question.answers.findIndex((subItem) => subItem.name === value);

        setUserSelect(find);

        setName(value);


        if(item.length > 0) {
            item[0].classList.remove("selected-item");
        }
        document.getElementById(`${value}-rect`).classList.add("selected-item");
    }


    return(<div>
        

 

        <div className="question-title-container">
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                {/* <img src={baseurl + "assets/game5/listen.svg"} /> */}
                {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}


                <h1 className="question-title">{question.title}</h1>

            </div>
                    <div id="audio-bt" onClick={playIntr}>
                        <img src={baseurl + "assets/audio/listen.png"} />
                    </div>
                </div>

        <div className="question-container">
            {question.answers !== undefined && 
                <div>
                    <div align="center">
                         <div style={{
    background: "#95D557",
    display: "inline-block",
    padding: "10px 20px",
    borderRadius: 20,
    fontFamily: 'gotham-bold',
    boxShadow: "0px 3px 0px #7ebe40",
    marginBottom: 20,
    fontSize: 22,
    marginLeft:-100

                     } }>{question.svgType === "body"?"The body":"The face"}</div></div>
                <div className="multiple-question-data">

                    
                    {question.audio !== "" && question.audio !== undefined && <audio style={{display:"none"}} id="question-audio" src={process.env.REACT_APP_BACKEND_LINK + "/" + question.audio} style={{display:"none"}} />}


               
                    {question.svgType === "body" && <Body style={{background: "#eaf6fc"}} selectItem={selectItem} />}
                    {question.svgType === "face" && <Face style={{background: "#eaf6fc"}} selectItem={selectItem} />}

                    {/* {question.answers.map((item,indx) => {

                        return (<div 
                            className={showCorrect === true?checkCorrect(userSelect,indx,item.correct):checkUserSelect(userSelect,indx)}
                        onClick={() => {
                            document.getElementById("clickbt").play();
                            setUserSelect(indx);}}>
                                <Animate play={true} easeType={"cubic-bezier(0.55, 0.06, 0.68, 0.19)"} start={{transform:"scale(0)",opacity:0}} end={{transform:"scale(1)",opacity:1}} duration={0.3} delay={indx * 0.2}>
                            <div className="multiple-question-img">
                                 {showCorrect === false && <img className={userSelect === indx?"answer-img selected":"answer-img"} src={baseurl + item.fileLink} />}
                                 {showCorrect === true && <img className={item.correct === true?"answer-img selected correct":userSelect === indx?"answer-img selected wronganswer":"answer-img"} src={baseurl + item.fileLink} />}
                                 <div className="multiple-question-title" style={{
                                         textAlign: "center",
                                         fontSize: 26
                                 }}><div style={{    minWidth: 145}} dangerouslySetInnerHTML={{ __html: item.name }}></div></div>
                            </div>
                            </Animate>
                        </div>)
                    })} */}
                </div>

                <audio src={`${baseurl}${audio}`} className="hidden-audio" id="intr" />

                <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />


                <div className="button-check">
                <button className="check-answer-bt" onClick={checkQuestion} disabled={userSelect === ""?true:false} variant="contained" color="primary">Check my answer</button>

                </div>

                </div> 
            }
                            <audio src={`${baseurl}assets/audio/audio1/listen_point.mp3`} className="hidden-audio" id="intr2" />

        </div>


    </div>)
}

export default ListenAndChooseTheWord;