import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import TickIcon from './../components/svgs/tick'
import CrossIcon from './../components/svgs/cross'

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

const audioStyle=  {

    background:"#0B329D",width:30,height:30,display:"inline-block",alignItems:"center",justifyContent:"center",borderRadius:"100%",padding:10,marginLeft:10,cursor:"pointer"
}
function DragAndDrop(props) {
    
    const history = useHistory();
    const [currentDrag,setCurrentDrag] = useState({});
    const [images,setImages] = useState([]);
    const [chart,setChart] = useState([]);

    const [userSelect,setUserSelect] = useState([
    
    ]);
    const [items,setItems] = useState([
       
        ]);

        const [randList,setRandList] = useState([]);
        const [randListV1,setRandListV1] = useState([]);

        const checkQuestion = () => {

            
            let chart2 = [...chart];
            let allcorrect = true;

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:props.questions.title,
                userAnswers:[],
                type:"completeRev_v2"
            }


            chart2.map((item,i) => {
                item.body.forEach((item2,j) => {
                    item2.forEach((item3,k) => {
                        item3.spans.forEach((item4,l) => {
                            if(item4.type === "input") {
                                if(item4.userAnswer.toLowerCase() === item4.text.toLowerCase()) {

                                    item.body[j][k].correct = true;
                                    
                                    
                                } else {
                                    item.body[j][k].correct = false;
                                    allcorrect = false;
                                }

                                answerFormat.userAnswers.push({
                                    userAnswer:item4.userAnswer.toLowerCase(),
                                    correctAnswer:item4.text.toLowerCase(),
                                    correct:item.body[j][k].correct
                                })
                            }
                        });
                    });
                });

                return item;
            });

            setChart(chart2);



            // items2.forEach((item,m) => {
            //     item.questions.forEach((questionItem,i) => {
            //         questionItem.spans.map((spanItem,j) => {
            //             // console.log("item.questions",spanItem)

            //             if(spanItem.type === "input") {
            //                 spanItem.userAnswer = spanItem.userAnswer.replaceAll("’","'").trim();
            //                 spanItem.text = spanItem.text.replaceAll("’","'").trim();

            //                 if(spanItem.answers !== undefined) {
            //                     if(spanItem.answers.length > 0) {
            //                         if(spanItem.answers.indexOf(spanItem.userAnswer) === -1) {
            //                             allcorrect = false;
            //                             items2[m].questions[i].correct = false;
            //                         } else {
            //                             items2[m].questions[i].correct = true;
            //                         }
            //                     }
            //                 }

            //                 else if(spanItem.fullAnswers !== undefined) {
            //                     items2[m].questions[i].correct = true;
            //                 }
            //                 else if(spanItem.answers !== undefined) {
            //                     items2[m].questions[i].correct = true;
            //                 }
            //                 else if(spanItem.open === true) {
            //                     items2[m].questions[i].correct = true;
            //                 }
            //                 else if(spanItem.userAnswer.toLowerCase() !== spanItem.text.toLowerCase()) {




            //                     // console.log(questionItem.spans,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())

            //                     // console.log(allcorrect,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())
            //                     allcorrect = false;
            //                     items2[m].questions[i].correct = false;
            //                 } else {
            //                     items2[m].questions[i].correct = true;
            //                 }
            //             }

            //         });
            //     });

            //     item.answers.forEach((questionItem,i) => {
            //         questionItem.spans.map((spanItem,j) => {    
            //             // console.log("item.answers",spanItem)

            //             if(spanItem.answers !== undefined) {

            //                 let checksub = false
            //                 spanItem.answers.forEach((subItem2) => {
            //                     if(spanItem.userAnswer.toLowerCase() === subItem2.toLowerCase()) {
            //                         checksub = true;
            //                     }
            //                 });

            //                 if(checksub === true) {
            //                     items2[m].questions[i].correct = true;
            //                 } else {
            //                     allcorrect = false;
            //                     items2[m].questions[i].correct = false;
            //                 }




            //             }
            //             else if(spanItem.type === "input") {
                            

            //                 if(spanItem.userAnswers !== undefined) {
            //                     console.log(spanItem.userAnswers.indexOf(spanItem.userAnswer),allcorrect);
            //                     if(spanItem.userAnswers.indexOf(spanItem.userAnswer) !== -1) {
            //                         items2[m].questions[i].correct = true;
            //                     } else {
            //                         allcorrect = false;
            //                         items2[m].questions[i].correct = false;
            //                     }
            //                 }
            //                 else if(spanItem.open === true) {
            //                     items2[m].questions[i].correct = true;
            //                 }
            //                 else if(spanItem.userAnswer.toLowerCase() !== spanItem.text.toLowerCase()) {
            //                     // console.log(allcorrect,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())

            //                     allcorrect = false;
            //                     items2[m].questions[i].correct = false;
            //                 } else {
            //                     items2[m].questions[i].correct = true;
            //                 }
            //             }
            //         });
            //     });

            // });



            if(allcorrect === true) {
                document.getElementById("correct-au").play();

            } else {
                document.getElementById("wrong-au").play();

            }
            answerFormat.pass = allcorrect;
            setTimeout(() => {
                props.nextExercise(answerFormat);
                // history.push(baseurl + "look_listen_say")
            }, 3000);
            
        }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }

      const changeTxt = (value,i,m,n,j) => {
          let chart2 = [...chart];
          chart2[i].body[m][n].spans[j].userAnswer = value;

          setChart(chart2);

        //   userSelect2[i] = value;
        //   setUserSelect(userSelect2);

      }

    useEffect(() => {
        setTimeout(() => {
            document.getElementById("intr").play();
        },300)
        let currentItems = [...items];


        if(props.questions.images !== undefined) {
            setImages(_.shuffle(props.questions.images));
        }

        // currentItems = shuffle(currentItems);

        setRandList(currentItems);
        document.getElementById("intr").play();

        setRandListV1(props.questions.answers)
        console.log("chart",props.questions.chart);
        setChart(props.questions.chart);

        return () => {
            setImages([]);
        }

    }, [props])

    
    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const checkQuestionAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].questions[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);
    }

    const checkAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].answers[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);

    }
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    return(<div id="complete_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title" dangerouslySetInnerHTML={{ __html: props.questions.title }}></h1>
                </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>

                </div>  
                {props.questions.video !== "" && <div style={{maxWidth:"60vw",minWidth:"60vw",marginBottom:50}}>
                
                <div class="video-intro" style={{height: "50vh",width:"100%"}}><img onClick={videoPlay} src={baseurl + "assets/game1/play.png"} id="video-waf" /><video style={{width:"100%"}} id="video-lin" src={baseurl + props.questions.video} controls></video></div>
                </div>}
                {props.questions.image !== "" && <div style={{maxWidth:"60vw",display:"flex",alignItems:"center",justifyContent:"center",gap:20,marginBottom:30}}><img src={baseurl + props.questions.image} /></div>}
                
                <div style={{display:"flex",alignItems:"flex-start"}}>
               
                
        <div id="complete-items-v1" style={{minWidth:"60vw"}}>

            <table className='table-style-v2' border='0' cellPadding={"20"} cellSpacing={"0"}>
                {chart.map((chartItem,i) => {
                    let headers = [];

                    chartItem.header.map((headerItem,j) => {
                        headers.push(<th><div><span>{headerItem}</span></div></th>)
                    });

                    return(<>
                    <tr>{headers}</tr>
                    {chartItem.body.map((dataItem,m) => {
                        return(<tr>
                            {dataItem.map((dataItem2,n) => {
                                return(<td>
{dataItem2.spans.map((spanItem,j) => {


if(spanItem.type === "input") {

    let border = {};
    if(spanItem.border === true) {
        border = {border:"3px dashed #1BAFDB",borderRadius:10}
    }
    return(<span><input spellCheck="false"  type='text' onChange={(e) => changeTxt(e.target.value,i,m,n,j)} style={{width:"auto",...border}} value={spanItem.userAnswer} size={spanItem.text.length} maxLength={spanItem.text.length} />
    
    </span>)
} else {
    return(<span dangerouslySetInnerHTML={{__html:spanItem.text}}></span>)
}

})}

{dataItem2.correct === true &&  <div style={{position:"absolute",right:25,top:25}}><TickIcon /></div>}
                            {dataItem2.correct === false &&  <div style={{position:"absolute",right:25,top:25}}><CrossIcon /></div>}


                                </td>)
                            })}

                        </tr>)
                    })}
                    </>)
                })}
            </table>
           
            


            {/* {randList.map((item,i) => {
                return(<div className="items-v1">{item.words.map((subItem,j) => {
                    if(subItem === "") {
                        let char_len= item.word.split(" ");
                        
                        return <span><input spellCheck="false"  type="text" onChange={(e) => {changeTxt(e.target.value,i,j)}} maxLength={char_len[j] !== undefined?(char_len[j].length>2?1:2):1} /></span>

                    } else {
                        return <span>{subItem + " "}</span>
                    }
                })}</div>);
            })} */}
        </div>


        {images.length > 0 && 
                    <div style={{maxWidth:"60vw",flexDirection:"column",marginLeft:20,display:"flex",alignItems:"center",justifyContent:"center",gap:20,marginBottom:30,padding:"0px 0px",width:120}}>
                        {images.map((item) => <div style={{borderRadius:20,overflow:"hidden"}}>
                            <img src={baseurl + item.url} />
                        </div> )}
                </div>}

        </div>

            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

            <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>
            </div>


    </div>)
}
export default DragAndDrop;