import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import TickIcon from './../components/svgs/tick'
import CrossIcon from './../components/svgs/cross'
import GreenCircle from './../svgs/greenCircle_v4'
import ReactHlsPlayer from 'react-hls-player';

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    
    const history = useHistory();
    const [leftItems, setLeftItems] = useState([]);
    const [rightItems, setRightItems] = useState([]);
    const [mainItems, setMainItems] = useState([]);
    const [dragItem,setDragItem] = useState({});
    const [videoLink, setVideoLink] = useState(""); 


        const [randListV1,setRandListV1] = useState([]);

        const checkQuestion = () => {

            
            let mainItems2 = [...mainItems];
            let allcorrect = true;

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:props.questions.title,
                userAnswers:[],
                type:"dragtable"
            }

            
            
            mainItems2.forEach((item) => {

                item.correctAnswer = item.correctAnswer.sort((a,b) => {
                    if(a.title < b.title) {
                        return - 1;
                    }
                    if(a.title > b.title) {
                        return 1;
                    }
                    return 0;
                });
                item.userAnswer = item.userAnswer.sort((a,b) => {
                    if(a.title < b.title) {
                        return - 1;
                    }
                    if(a.title > b.title) {
                        return 1;
                    }
                    return 0;
                });


                // console.log("correctlist",item.correctAnswer,item.userAnswer);


                let userAnswerTxt = "";
                let correctAnswerTxt = "";
                item.userAnswer.forEach((subItem,i) => {

                    answerFormat.userAnswers.push({
                        userAnswer:subItem.title,
                        correctAnswer:item.correctAnswer[i].title,
                        correct:subItem.title === item.correctAnswer[i].title?true:false
                    });
                });

                

                // item.userAnswer.forEach((subItem,i) => {
                    
                // });
                


                
                if(_.isEqual( item.correctAnswer, item.userAnswer)) {
                    
                } else {
                    allcorrect = false;
                    answerFormat.pass = false;
                }

                
            });

            // items2.forEach((item,m) => {
            //     item.questions.forEach((questionItem,i) => {
            //         questionItem.spans.map((spanItem,j) => {
            //             if(spanItem.type === "input") {
            //                 if(spanItem.userAnswer !== spanItem.text) {
            //                     allcorrect = false;
            //                     items2[m].questions[i].correct = false;
            //                 } else {
            //                     items2[m].questions[i].correct = true;
            //                 }
            //             }

            //         });
            //     });


            // });

            



            if(allcorrect === true) {
                document.getElementById("correct-au").play();
            } else {
                document.getElementById("wrong-au").play();
            }
            // setRandListV1(items2);
            setTimeout(() => {
               props.nextExercise(answerFormat);
                // history.push(baseurl + "look_listen_say")
            }, 3000);
            
        }

    useEffect(() => {
        setLeftItems(props.questions.leftItems);
        setRightItems(props.questions.rightItems);
        setMainItems(props.questions.mainItems);


        document.getElementById("intr").play();

        if(props.questions.videoCode !== undefined) {

            fetch("https://api.vimeo.com/videos/" + props.questions.videoCode,{
                method: "GET",
                headers: {
                    "Authorization": "Bearer aeeec5987db81bbfd76e0f272e35468c", 
                }
            }).then(response => response.json()).then(data => {
                // console.log(data);
                // setVideoData(data.embed.html)
                // console.log("video212",{
                //     autoplay: true,
                //     controls: true,
                //     responsive: true,
                //     fluid: true,
                //     sources: [{
                //       src: data.files[0].link,
                //       type: 'video/mp4'
                //     }]
                // })

                
                if(data.files !== undefined) {
                    let files = data.files;
                    let findHls = files.findIndex((item) => item.quality === "hls");

                    if(findHls !== -1) {
                        setVideoLink(data.files[findHls].link_secure);
                    }
                }
            
            });

        }


    }, [props.questions])

    
    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const checkQuestionAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].questions[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);
    }

    const checkAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].answers[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);

    }

    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    const dragItemFunc = (e,location,i,item) => {
        setDragItem({location:location,id:i,item:item})
    }

    const dropItemFunc = (e,i) => {

        e.target.style.background = "none";

        let mainItems2 = [...mainItems];

        console.log(dragItem);
        if(dragItem.location === "left") {
            setLeftItems(leftItems => leftItems.filter((item,j) => j !== dragItem.id))
            // setMainItems((mainItems) => {mainItems[i].push(leftItems[dragItem.id]}))
            mainItems2[i].userAnswer.push(leftItems[dragItem.id])
        }
        if(dragItem.location === "right") {
            setRightItems(rightItems => rightItems.filter((item,j) => j !== dragItem.id))
            // setMainItems((mainItems) => mainItems[i](rightItems[dragItem.id]))
            mainItems2[i].userAnswer.push(rightItems[dragItem.id])

        }


        setMainItems(mainItems2)
        
        


    }

    const onDragLeave = (ev) => {
        ev.target.style.background = "none";
        ev.preventDefault();
    }

    const allowDrop = (ev) => {    
        // let dropAllList = document.querySelectorAll(".drop-subitems");
        // dropAllList.forEach((item) => {
        //     item.style.background = "none";
        // });
        ev.target.style.background = "#c5e5ed";
        ev.preventDefault();
    }

    return(<div id="complete_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title">{props.questions.title}</h1>
                </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>

                </div>

        {props.questions.hideGreenBt !== true &&
          <GreenCircle width={200} height={200} style={{marginTop:-20}} title={
            <>

{props.questions.mainTxt !== undefined && <>
{props.questions.mainTxt.map((item) => {
  return(
    <tspan x={item.x} y={item.y} fontSize={item.fontSize}>
    {item.text} 
    </tspan>
  

  )
})}  
</>} 
            
          </>} />
        }
              
 
        <div id="complete-items-v1" style={{minWidth:"60vw"}} className='dragtable'>




        {videoLink !== ""?
         <div className="video-intro" style={{height:"50vh",width:"100%",marginBottom:50}}>
          <ReactHlsPlayer
          src={videoLink}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
         /></div>:<>
         {props.questions.video !== undefined && props.questions.video !== "" &&<div className="video-intro" style={{height:"50vh",width:"100%",marginBottom:50}}>
                        <img src={baseurl + "assets/game1/play.png"} onClick={videoPlay} id="video-waf" />
                        <video id="video-lin" src={baseurl + props.questions.video} controls />

                    </div>}
         </>
       
        }




            

            <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap:"wrap",
                    height:"100%"
            }}>
                <div className='left-items' style={{width:"25%"}}>
                    {leftItems.map((item,i) => {
                        return(<div style={{position:"relative"}} onDrag={(e) => dragItemFunc(e,"left",i,item)}>
                            <img src={baseurl + item.image} />
                            {props.questions.showSubTitle === true && <div style={{position:"absolute",bottom:30,width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                        <div className='bluebt'>{item.title}</div></div>}
                        </div>)
                    })}
                </div>
                <div className='main-items' style={{width:"50%"}}>
                    {mainItems.map((item,i) => {
                        return(<div className='main-subitems'>
                            <div style={{background:"#fff",textAlign:"center"}}>
                                {props.questions.showTitle === true?<div style={{fontSize:25,padding:"20px",color:"#0B329D",fontFamily:"gotham-medium",padding:"20px 50px"}}>{item.title}</div>:<img src={baseurl + item.image} />}
                            </div>
                            <div onDrop={(e) => dropItemFunc(e,i)} onDragOver={allowDrop}  onDragLeave={onDragLeave} className='drop-subitems'>
                                {item.userAnswer.map((item,i) => {
                                    return(<div style={{position:"relative"}} onDrag={(e) => dragItemFunc(e,"middle",i,item)}>
                                        
                                        <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"auto",width:"100%",margin:"auto" }}><img src={baseurl + item.image} /></div>
                                    {props.questions.showSubTitle === true && <div style={{position:"absolute",bottom:20,width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                        <div className='bluebt'>{item.title}</div></div>}
                                    </div>)
                                })}
                                </div>                       
                        </div>)
                    })}
                </div>
                <div className='right-items' style={{width:"25%"}}>
                    {rightItems.map((item,i) => {
                        return(<div style={{position:"relative"}} onDrag={(e) => dragItemFunc(e,"right",i,item)}><img src={baseurl + item.image} />
                                                    {props.questions.showSubTitle === true && <div style={{position:"absolute",bottom:30,width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                        <div className='bluebt'>{item.title}</div></div>}

                        </div>) 
                    })}
                </div>

                <div className="button-check" style={{width:"100%"}}>
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>
            </div>
            </div>
        </div>

            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

   


    </div>)
}
export default DragAndDrop;