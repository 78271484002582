import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import GreenCircle from './../svgs/greenCircle'
const baseurl = process.env.REACT_APP_FRONT_LINK;

function LookAndWrite4(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);
    const [icon,setIcon] = useState("");
    const [headImage,setHeadImage] = useState("");

    
    const [items,setItems] = useState([]);

        const [randList,setRandList] = useState([]);
    



      

    const changeTxt = (value,j,m,i,t) => {
        let randList2 = [...randList];
        randList2[j].words[m].lines[i][t].userAnswer = value;
        setRandList(randList2);
    }

    const checkQuestion = () => {
        let randList2 = [...randList];
        let allcorrect = true;

        // console.log(randList)

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"watch_video"
        }
        

        randList2.forEach((randItem,i) => {
            randItem.words.forEach((wordItem,j) => {
                wordItem.lines.forEach((subItem,m) => {
                    subItem.forEach((wordItem) => {
                        if(wordItem.type === "input") {
                            if(wordItem.answers !== undefined) {
                                wordItem.answers = wordItem.answers.map((item) => item.toLowerCase());
                                if(wordItem.answers.indexOf(wordItem.userAnswer.toLowerCase()) === -1) {
                                    allcorrect = false;
                                    randList2[i].correct = false;
                                    answerFormat.pass = false;
                                } else {
                                    randList2[i].correct = true;
                                }

                                answerFormat.userAnswers.push({
                                    userAnswer:wordItem.userAnswer.toLowerCase(),
                                    correctAnswer:wordItem.answers[0],
                                    correct:randList2[i].correct
                                  })

                            } else {
                                if(wordItem.userAnswer.toLowerCase() !== wordItem.text.toLowerCase()) {
                                    allcorrect = false;
                                    randList2[i].correct = false;
                                    answerFormat.pass = false;
                                } else {
                                    randList2[i].correct = true;
                                }

                                answerFormat.userAnswers.push({
                                    userAnswer:wordItem.userAnswer.toLowerCase(),
                                    correctAnswer:wordItem.text.toLowerCase(),
                                    correct:randList2[i].correct
                                  })
                            }

                           
                            
                        }
                    });
                    
                });
            })
        }); 

        setRandList(randList2);



        if(allcorrect === true) {
            document.getElementById("correct-au").play();
        } else {
            document.getElementById("wrong-au").play();
        }
        // setRandList(items2);
        setCheckAnswers(true);

        setTimeout(() => {
            // history.push(baseurl + "look_notice")
            props.nextExercise(answerFormat);
        }, 3000);
        
    }

    useEffect(() => {
        let currentItems = props.questions.answers;


        setIcon(props.questions.icon)
    

        setTimeout(() => {
             document.getElementById("intr").play();
        },300)
        setItems(currentItems)
        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    return(<div id="look_write">
        {props.questions.hideTitle === undefined &&
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                {/* <img src={baseurl + "assets/game5/write.svg"} /> */}

                {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}


                    <h1 className="question-title">{props.questions.title}</h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>}

                

{props.questions.img !== undefined &&
    <img src={baseurl + props.questions.img} />
}
        <div id="look_write-items-v1">
            {randList.map((item,j) => {
                return(<div className="look_write-item-v1" style={{marginTop:20,alignItems:"center"}}>

{checkAnswers === false ?<img src={baseurl + "assets/chara/" +item.img + ".png"} />:item.correct === false ?<img style={{border:"5px solid red"}} src={baseurl + "assets/chara/" +item.img + ".png"} />:<img style={{border:"5px solid #7EBE40"}} src={baseurl + "assets/chara/" +item.img + ".png"} />}

                    {item.words.map((subItem,m) => {
                        return(<div className="word-v1" style={{display:"inline-block",
                        position:"relative",
                        display: "inline-block",
        background: "white",
        border: "3px solid #22cbf2",
        margin: "0px 20px 20px",
        boxShadow:"rgb(34 203 242) -4px 4px 0px",
        padding:"10px 20px 20px"
                    }}>
                        {j % 2 === 0?
    <img src={ baseurl + "assets/game8/arrow.png"} style={{width: "auto",height: "auto", border: "0px",position: "absolute", left: "-19px", top: "17%",borderRadius: "0px"}} />
:
<img src={ baseurl + "assets/game8/arrow.png"} style={{width: "auto",height: "auto", border: "0px",transform:"rotate(180deg)",position: "absolute", right: "-19px", top: "17%",borderRadius: "0px"}} />
}
                            {subItem.lines.map((wordItem,i) => { 

                                return(<div style={{display:"flex",alignItems:"center",marginTop:5}}>{wordItem.map((word,t) => {

                                    if(word.type === "text") {
                                        return(<span style={{fontSize:20}}>{word.text}&nbsp;</span>);
                                    } else {
                                        return(<div className={"editable-input"} ><input spellCheck="false"  onChange={(e) => { changeTxt(e.target.value,j,m,i,t) }} maxLength={word.text.length} size={word.text.length} style={{marginBottom:5,width:"auto",borderBottom:"2px solid #B7BCD1",fontSize:20}} type="text" />&nbsp;</div>);
                                    }
                                    
    
                                    // return(<span style={{fontSize:20}}>{item.text} </span>)
                                })}</div>)

                                
                            })}
                        </div>)
                    })}
                </div>)
            })}
        </div>

        {/* <audio src={`${baseurl}assets/levels/level1/unit1/assets/audios/look_write.mp3`} className="hidden-audio" id="intr" /> */}

                <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />


<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />


        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>{props.questions.buttonText !== undefined?props.questions.buttonText:"Check my answer"}</button>

                </div>


    </div>)
}
export default LookAndWrite4;