import * as React from "react"


function Shape1(props) {

    const allowDrop = (ev) => {     
     //   ev.target.style.fill = "red";
        ev.preventDefault();
    }

    return(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={500.542}
    height={500.003}
    viewBox={`0 0 500.542 500.003`}
    {...props}
  >
<defs>

      {props.userAnswers.map((item,i) => {



          return(
            <pattern id={"img" + i}  patternContentUnits="objectBoundingBox" height="100%" width="100%">
                {item.item !== undefined &&
                            <image preserveAspectRatio="none" href={props.baseurl + item.item.url} preserveAspectRatio="xMidYMid slice" style={{backgroundRepeat:"no-repeat"}} x="0" y="0" width="1" height="1" />

                }
          </pattern>
        

            
          )
      })}

</defs>

    <g
      data-name="Group 4490"
      fill="#d3f8fe"
      stroke="#22cbf2"
      strokeLinecap="round"
      strokeWidth={4}
      strokeDasharray={9}
    >

<path

onDrop={(e) => props.dropItemFunc(e,0)}
      onDragOver={allowDrop}
        onDragEnter={(e) => {e.target.style.opacity = "0.5"}}      
        onDragLeave={(e) => {e.target.style.opacity = "1"}}
      fill="url(#img0)"
        data-name="Path 2607"
        d="M15.789 243.839h135.477a13.669 13.669 0 0 0 13.454-10.908 87.336 87.336 0 0 1 65.896-67.921 13.643 13.643 0 0 0 10.439-13.353V15.794a13.786 13.786 0 0 0-15.106-13.719C106.72 13.616 12.087 109.226 2.056 228.899a13.789 13.789 0 0 0 13.732 14.94"
      />
     
     <path
      fill="url(#img1)"
      onDrop={(e) => props.dropItemFunc(e,1)}
      onDragOver={allowDrop}
      onDragEnter={(e) => {e.target.style.opacity = "0.5"}}      
      onDragLeave={(e) => {e.target.style.opacity = "1"}}

        data-name="Path 2608"
        d="M349.273 243.839h135.476a13.793 13.793 0 0 0 13.737-14.94C488.455 109.322 393.984 13.778 274.896 2.115a13.783 13.783 0 0 0-15.117 13.716v135.885a13.639 13.639 0 0 0 10.4 13.344 87.361 87.361 0 0 1 65.638 67.871 13.669 13.669 0 0 0 13.456 10.91"
      />
      <path onDragOver={allowDrop}
      fill="url(#img2)"
      onDrop={(e) => props.dropItemFunc(e,2)}

      onDragEnter={(e) => {e.target.style.opacity = "0.5"}}      
      onDragLeave={(e) => {e.target.style.opacity = "1"}}

      data-name="Path 2606"
        d="M152.759 262.562H16.401a13.777 13.777 0 0 0-13.7 15.272C15.644 394.335 108.953 486.6 225.964 497.928a13.78 13.78 0 0 0 15.09-13.719V348.35a13.9 13.9 0 0 0-10.883-13.458 87.36 87.36 0 0 1-64.08-61.958 13.874 13.874 0 0 0-13.333-10.372"
      />


      <path
      fill="url(#img3)"

      onDrop={(e) => props.dropItemFunc(e,3)}
      onDragOver={allowDrop}

      onDragEnter={(e) => {e.target.style.opacity = "0.5"}}      
      onDragLeave={(e) => {e.target.style.opacity = "1"}}

        data-name="Path 2609"
        d="M259.777 348.287v135.882a13.782 13.782 0 0 0 15.106 13.719c116.849-11.449 210.023-103.649 222.956-220.053a13.776 13.776 0 0 0-13.7-15.272H347.785a13.878 13.878 0 0 0-13.331 10.375 87.367 87.367 0 0 1-63.833 61.9 13.9 13.9 0 0 0-10.841 13.449"
      />
    </g>
  </svg>
)
    }

export default Shape1
