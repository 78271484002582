import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import GreenCircle from './../svgs/greenCircle'
const baseurl = process.env.REACT_APP_FRONT_LINK;

function LookAndWrite4(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);
    const [icon,setIcon] = useState("");
    const [headImage,setHeadImage] = useState("");

    
    const [items,setItems] = useState([]);

        const [randList,setRandList] = useState([]);
    



      

    const changeTxt = (value,j,m,i,t) => {
        let randList2 = [...randList];
        randList2[j].words[m].lines[i][t].userAnswer = value;
        setRandList(randList2);
    }

    const checkQuestion = () => {

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"read_your_dialog"
        }
        props.nextExercise(answerFormat);

        
    }

    useEffect(() => {
        let currentItems = props.questions.answers;


        setIcon(props.questions.icon)
    

        setTimeout(() => {
             document.getElementById("intr").play();
        },300)
        setItems(currentItems)
        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    return(<div id="look_write" style={{maxWidth:800}}>
 

                

{props.questions.img !== undefined &&
<div align="center">    <img src={baseurl + props.questions.img} />
</div>
}
        <div id="look_write-items-v1">
            {randList.map((item,j) => {
                return(<div className="look_write-item-v1" style={{marginTop:20,alignItems:"flex-start",flexDirection:"row"}}>

{checkAnswers === false && <div style={{color:"#22CBF2",fontFamily:"gotham-bold",fontSize:20,marginRight:10,width:130,marginTop:10}}>{item.img}:</div>}

                    {item.words.map((subItem,m) => {
                        return(<div className="word-v1" style={{display:"inline-block",
                        position:"relative",
                        display: "inline-block",
        background: "white",
        border: "3px solid #22cbf2",
        margin: "0px 20px 20px",
        boxShadow:"rgb(34 203 242) -4px 4px 0px",
        padding:"10px 20px 20px",
        flex:1
                    }}>
                        {/* {j % 2 === 0?
    <img src={ baseurl + "assets/game8/arrow.png"} style={{width: "auto",height: "auto", border: "0px",position: "absolute", left: "-19px", top: "17%",borderRadius: "0px"}} />
:
<img src={ baseurl + "assets/game8/arrow.png"} style={{width: "auto",height: "auto", border: "0px",transform:"rotate(180deg)",position: "absolute", right: "-19px", top: "17%",borderRadius: "0px"}} />
} */}

<img src={ baseurl + "assets/game8/arrow.png"} style={{width: "auto",height: "auto", border: "0px",position: "absolute", left: "-19px", top: "17%",borderRadius: "0px"}} />
                            {subItem.lines.map((wordItem,i) => { 

                                return(<div style={{display:"flex",alignItems:"center",marginTop:5}}>{wordItem.map((word,t) => {

                                    if(word.type === "text") {
                                        return(<span style={{fontSize:20}}>{word.text}&nbsp;</span>);
                                    } else {
                                        return(<div className={"editable-input"} ><input spellCheck="false"  onChange={(e) => { changeTxt(e.target.value,j,m,i,t) }} maxLength={word.text.length} size={word.text.length} style={{marginBottom:5,width:"auto",borderBottom:"2px solid #B7BCD1",fontSize:20}} type="text" />&nbsp;</div>);
                                    }
                                    
    
                                    // return(<span style={{fontSize:20}}>{item.text} </span>)
                                })}</div>)

                                
                            })}
                        </div>)
                    })}
                </div>)
            })}
        </div>

        {/* <audio src={`${baseurl}assets/levels/level1/unit1/assets/audios/look_write.mp3`} className="hidden-audio" id="intr" /> */}

                <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />


<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />


        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>

                </div>


    </div>)
}
export default LookAndWrite4;