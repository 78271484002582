import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import Tick from './../svgs/tick'
import ReactHlsPlayer from 'react-hls-player';

const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]

function ListenAndChooseTheWord(props) {
    const [data,setData] = useState([]);
    const [dataGrid,setDataGrid] = useState([]);

    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState("");
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [answer,setAnswer] = useState(0);
    const history = useHistory();
    const [video, setVideo] = useState("");
    const [videoLink, setVideoLink] = useState("");

    const instr = useRef();

    const subTitleStyle = {
        position: "absolute",
        left: "30px",
        bottom: "20px",
        color: "#0b329d",
        fontFamily: 'gotham-medium'
    }

    useEffect(() => {

        if(props.questions.videoCode !== undefined) {

            fetch("https://api.vimeo.com/videos/" + props.questions.videoCode,{
                method: "GET",
                headers: {
                    "Authorization": "Bearer aeeec5987db81bbfd76e0f272e35468c", 
                }
            }).then(response => response.json()).then(data => {
                // console.log(data);
                // setVideoData(data.embed.html)
                // console.log("video212",{
                //     autoplay: true,
                //     controls: true,
                //     responsive: true,
                //     fluid: true,
                //     sources: [{
                //       src: data.files[0].link,
                //       type: 'video/mp4'
                //     }]
                // })

                
                if(data.files !== undefined) {
                    let files = data.files;
                    let findHls = files.findIndex((item) => item.quality === "hls");

                    if(findHls !== -1) {
                        setVideoLink(data.files[findHls].link_secure);
                    }
                }
            
            });

        } else {
            if(props.questions.video1 !== undefined) {
                setVideoLink(baseurl + props.questions.video1);
            }
        }

    },[])
    useEffect(() => {
            setCurrent(0);     
            
            if(props.questions.questionData !== undefined) {
                setData(props.questions.questionData);
            }

            if(props.questions.grid !== undefined) {
                setDataGrid(props.questions.grid);
            }
            document.getElementById("intr2").play();


    }, [props.questions])

    const checkQuestion = (e) => {
        
        let currentData = [...data];

        let dataGrid2 = [...dataGrid];

        let allcorrect = true;

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"watch_video"
        }


        if(dataGrid2.length > 0 ) {

            dataGrid2.forEach((gridItem,g) => {
                gridItem.items.forEach((item,i) => {
                    
                    let userAnswer = item.userAnswer.toLowerCase();
                    let title = item.title.toLowerCase();

                    if(userAnswer !== title) {
                        allcorrect = false;
                        dataGrid2[g].items[i].correctAnswer = false;
                    } else {
                        dataGrid2[g].items[i].correctAnswer = true;
                    }

                    answerFormat.userAnswers.push({
                        userAnswer:userAnswer,
                        correctAnswer:title,
                        correct:dataGrid2[g].items[i].correctAnswer
                      })


                });

            })

            setDataGrid(dataGrid2);

        } else {
            currentData.forEach((item,i) => {
                let answerItem = true;
                if(item.type === "text"){
                    if(item.userAnswer !== item.title) {
                        allcorrect = false;
                        answerItem = false;

                        answerFormat.userAnswers.push({
                            userAnswer:item.userAnswer,
                            correctAnswer:item.title,
                            correct:answerItem
                          })
                    }
                } else {
                    if(item.userAnswer !== item.text) {
                        allcorrect = false;
                        answerItem = false;

                        answerFormat.userAnswers.push({
                            userAnswer:item.userAnswer,
                            correctAnswer:item.text,
                            correct:answerItem
                          })
                    }
                }

               
       
                currentData[i].checkAnswer = true;
            })
    
            setData(currentData);
    
        }





        if(allcorrect === true) {
            document.getElementById("correct-au").play();

        } else {
            document.getElementById("wrong-au").play();
        }
        answerFormat.pass = allcorrect;
        setTimeout(() => {
            props.nextExercise(answerFormat);
        }, 3000);

    }

    const playAudio = (e) => {
        if(document.getElementById("question-audio") !== null) {
            document.getElementById("question-audio").play();
        }
    }

    const checkUserSelect = (userSelect,indx) => {
        if(userSelect === indx) {
            return ("selected multiple-question-item");
        } else {
            return ("multiple-question-item");
        }
    }

    const checkCorrect = (userSelect,indx,correct) => {

        if(correct === true) {
            return("multiple-question-item selected correct")
        } else {
            if(userSelect === indx) {
                return ("selected wronganswer multiple-question-item");
            } else {
                return ("multiple-question-item");
            }
        }
        
    }

    const playIntr = (e) => {
        document.getElementById("intr2").play();
    }
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    const updateText = (value,g,i) => {
        let dataGrid2 = [...dataGrid];

        dataGrid2[g].items[i].userAnswer = value;

        setDataGrid(dataGrid2)
    }
    const updateUserAnswer = (value,i) => {
        let data2 = [...data];

      //  if(data2[i].userAnswer === "") {
            data2[i].userAnswer = value;
        //}

        setData(data2);
    }

    return(<div>
        

 

        <div className="question-title-container" style={{width:"50vw",margin:"auto"}}>
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/listen.svg"} />
                <h1 className="question-title" dangerouslySetInnerHTML={{__html:props.questions.title}}></h1>

            </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                </div>

        <div className="question-container look-notice">
            <div 
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection:"column"
        }}
            >
            <div style={{
                textAlign:"left",
                width:"60vw"
            }}>
              
            {props.questions.topImage !== undefined && <img src={baseurl + props.questions.topImage} style={{marginBottom:50}} />}
            </div>
            {/* {props.questions.video1 !== undefined && 
            <div className="video-intro" style={{height:"50vh",marginBottom:50}}>
                        <img src={baseurl + "assets/game1/play.png"} id="video-waf" onClick={videoPlay} />
                        <video id="video-lin" src={baseurl + props.questions.video1} controls />

                    </div>} */}
                    {videoLink !== "" &&
         <div className="video-intro" style={{height:"50vh",marginBottom:50}}>
          <ReactHlsPlayer
          src={videoLink}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        /></div>}



                    {props.questions.options !== undefined && <div
                    style={{
                        display: "flex",
                        boxSizing: "border-box",
                        background: "rgb(179, 235, 245)",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 20,
                        margin: "5px 0px 30px",
                        borderRadius: 20
                    }}
                    >
                        {props.questions.options.map((optionItem) => {
                            return(<div style={{
                                padding: "0px 20px",
                                fontFamily: "gotham-medium",
                                color: "rgb(11, 50, 157)"
                            }}>{optionItem}</div>)
                        })}
                    </div>}

                    {dataGrid.length > 0 && <div id="checkboxs-grid-list" style={{display:"flex",alignItems:"flex-start",justifyContent:"center"}}>
                        {dataGrid.map((gridItem,g) => {
                            return(<div style={{flex:1,boxSizing:"border-box"}}>
                                <div style={{padding:"20px"}}>
                                <img src={baseurl + gridItem.imageLink} />
                                <div>
                                    {gridItem.items.map((item,i) => {
                                        let container_style = {};

                                        if(item.correctAnswer === true) {
                                            container_style = {border:"5px solid #83cd83"};
                                        }

                                        if(item.correctAnswer === false) {
                                            container_style = {border:"5px solid #F84E53"};
                                        }


                           


                                        return(<div style={{color:"#0B329D",fontFamily:"gotham-medium",display:"flex",alignItems:"center",margin:"10px 0px"}}>
                                            <div style={{marginRight:15}}><input onChange={(e) => updateText(e.target.value,g,i)} size={item.title.length} style={{textAlign:"center",border:"3px solid rgb(195, 186, 250)",borderRadius:10,fontSize:20,color:"rgb(11, 50, 157)",fontFamily:"gotham-medium",...container_style}} type='text' maxLength={item.title.length} value={item.userAnswer} /></div>
                                            <div style={{fontFamily:"gotham-medium",fontSize:20}}>{item.text}</div>
                                        </div>)
                                    })}
                                </div>
                                </div>
                               
                            </div>)
                        })}
                    </div>}

            <div id="checkboxs-list" style={{display:"flex",alignItems:"center",justifyContent:"center",flexWrap:"wrap",width:"60vw"}}>
                {data.map((item,i) => {

                    

                    let transform_style = {}
                    let container_style = {}

                    if(item.checkAnswer !== undefined) {
                        

                        if(item.userAnswer === item.title && item.type !== "image") {
                            container_style = {border:"5px solid #83cd83"};

                           
                        }
                        else if(item.userAnswer === item.text && item.type !== "text") {
                            container_style = {border:"5px solid #83cd83"};

                           
                        } else {
                            container_style = {border:"5px solid #F84E53"};
                        }
                    }
                    
                    if(props.questions.height !== undefined) {
                        container_style.height = props.questions.height;
                    }

                    let position = {};
                    if(props.questions.position === "static") {
                        position.top = "130px";
                        position.bottom = "auto";
                    }

                    return(<div style={{margin:"0px 20px 70px",display:"flex",flexDirection:"column",cursor:"pointer",width:300,height:200,...container_style}}>
                        <input spellCheck="false"  type='text' spellCheck={false} maxLength={2} size={2} 
                        onChange={(e) => {updateUserAnswer(e.target.value,i)}}
                        style={
                            {
                                "-webkit-appearance":"none",
                                position:"absolute",
                                fontSize:20,
                                borderRadius: 10,
    border: "3px solid #c3bafa",
                                right:30,bottom:20,textAlign:"center",fontFamily:"gotham-medium",...position}} />
                        {item.type === "image" && <img style={{height:"100%",width:"auto",...transform_style}} src={baseurl + item.url} />}
                        {item.type === "text" && <span style={{color:"#000",fontSize:22,fontFamily:"gotham-medium",color:"#0B329D"}}>{item.text}</span>}
                        {/* <div className='subtitlev2' style={subTitleStyle}>{item.subtitle}</div> */}
                        {props.questions.showSubTitle === true && <div className='subtut' style={props.questions.position === "static"?{width:"100%"}:{position:"absolute",bottom:-20}}><div className='bluebt'>{item.title}</div></div>}
                    </div>)
                })}
            </div>

            {props.questions.belowOptions !== undefined &&
            <div style={{background:"#D2F1F8",borderRadius:20,color:"#0B329D",display:"flex",alignItems:"center",justifyContent:"center",fontFamily:"gotham-medium",fontSize:20,marginTop:30}}>
                {props.questions.belowOptions.map((item,i) => {
                    return(<div style={{margin:"0px 10px",padding:"20px"}}>{item}</div>)
                })}
            </div>
            }


            </div>


            <div className="button-check">
            <button className="check-answer-bt" onClick={checkQuestion} variant="contained" color="primary">Check my answer</button>

            </div>

<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr2" />

        </div>


    </div>)
}

export default ListenAndChooseTheWord;