import React,{useEffect,useState} from 'react';
import {Link} from 'react-router-dom'
import {useSelector} from 'react-redux';

const baseurl = process.env.REACT_APP_FRONT_LINK;
const progressHeight = 12;

// const units = [
//     {
//         type:"unit",
//         number:1,
//         text:"U1"
//     },
//     {
//         type:"unit",
//         number:2,
//         text:"U2"
//     },
//     {
//         type:"unit",
//         number:3,
//         text:"U3"
//     },
//     {
//         type:"unit",
//         number:4,
//         text:"U4"
//     },
//     {
//         type:"unit",
//         number:5,
//         text:"U5"
//     },
//     {
//         type:"rev",
//         number:1,
//         text:"R1"
//     },
//     {
//         type:"unit",
//         number:6,
//         text:"U6"
//     },
//     {
//         type:"unit",
//         number:7,
//         text:"U7"
//     },
//     {
//         type:"unit",
//         number:8,
//         text:"U8"
//     },
//     {
//         type:"unit",
//         number:9,
//         text:"U9"
//     },
//     {
//         type:"unit",
//         number:10,
//         text:"U10"
//     },
//     {
//         type:"rev",
//         number:2,
//         text:"R2"
//     },
    
//     {
//         type:"reader",
//         number:1,
//         text:"Read1"
//     }
// ]


const levels_2 = [
    {
        type:"unit",
        number:1,
        text:"U1"
    },
    {
        type:"unit",
        number:2,
        text:"U2"
    },
    {
        type:"unit",
        number:3,
        text:"U3"
    },
    {
        type:"unit",
        number:4,
        text:"U4"
    },
    {
        type:"unit",
        number:5,
        text:"U5"
    },
    {
        type:"rev",
        number:1,
        text:"R1"
    },
    {
        type:"unit",
        number:6,
        text:"U6"
    },
    {
        type:"unit",
        number:7,
        text:"U7"
    },
    {
        type:"unit",
        number:8,
        text:"U8"
    },
    {
        type:"unit",
        number:9,
        text:"U9"
    },
    {
        type:"unit",
        number:10,
        text:"U10"
    },
    {
        type:"rev",
        number:2,
        text:"R2"
    },
    
    {
        type:"reader",
        number:1,
        text:"Read1"
    }
]


function Headertop(props) {
    const user = useSelector((state) => state.user);
    const [unColorUnit,setUnColorUnit] = useState([]); 
    const [colorUnit,setColorUnit] = useState([]); 

    let progressStyle = {background:"#22CBF2",height:"100%",width:`${props.progress}%`};

    useEffect(() => {

        let selectedLevel = props.level;
        selectedLevel = selectedLevel.replace("L","level");


        fetch(process.env.REACT_APP_FRONT_LINK + "assets/levels/" + selectedLevel + "/level.json").then(response => response.json())
        .then((res) => {

            let units = res.levelData;

        let currentunit2 = props.unit;
        currentunit2 = parseInt(currentunit2.replace("U",""));
        currentunit2++;

        let unitData = [];
        let unitDataColor = [];
        let find = units.findIndex((item) => item.text === props.unit);

        // console.log(find) 


        currentunit2 = units.findIndex((item,index) => item.text === props.unit);
        currentunit2++;

        while(currentunit2 <= units.length - 1) {
            unitData.push(<div class={

                units[currentunit2].type === "reader"?"unit-uncolor reader-color":
                units[currentunit2].type ==="rev"?'unit-uncolor rev-color':'unit-uncolor'
            
            }><span>{units[currentunit2].number}</span></div>)
            currentunit2++;
        }
        let startLevel = 0;
        currentunit2 = units.findIndex((item,index) => item.text === props.unit);

        while(startLevel <= currentunit2) {
            
            unitDataColor.push(<div class={
        
            units[startLevel].type === "reader"?"unit-color reader-color":
            units[startLevel].type ==="rev"?'unit-color rev-color':'unit-color'

        }><span>{units[startLevel].number}</span></div>)
            startLevel++;
        }
        
        setUnColorUnit(unitData);
        setColorUnit(unitDataColor);
    });

    }, [props.level])


    return(<div id="headertop-section" className="mainquestion-levels">
                            {/* <img src={baseurl + header} /> */}
                            <div style={{display:"flex",alignItems:"center",width:1000,justifyContent:"space-between"}}>
                                <div style={{display:"flex",alignItems:"center"}}>
                                    {colorUnit}
                                    <div className='mainTitle' dangerouslySetInnerHTML={{__html:props.title}}></div>
                                </div>
                                <div style={{display:"flex",alignItems:"center"}}>
                                    {unColorUnit}
                                </div>
                            </div>
    
                            <div style={{width:"100%",height:progressHeight,display:"flex",justifyContent:"center",alignItems:"center",marginTop:25}}>
                                <div style={{color:"red",fontFamily:"gotham-bold",color:"#22CBF2",paddingRight:10,width:40}}>{parseInt(props.progress)}%</div>
                                <div style={{background:"#D0D3E1",borderRadius:6,width:`100%`,height:progressHeight,overflow:"hidden"}}>
                                    <div style={progressStyle}></div>
                                </div>
                            </div>


</div>)
}
export default Headertop;