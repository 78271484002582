import React,{useState} from 'react'
// import ListenAndChooseTheWord from './../engines/ListenAndChooseTheWord';
import VerticalMenu from './../components/VerticalMenu';
import { useEffect } from 'react';
import { Animate, AnimateKeyframes, AnimateGroup } from "react-simple-animate";
import Stars from './../components/stars';
import {Link,useHistory} from 'react-router-dom'

const baseurl = process.env.REACT_APP_FRONT_LINK;

const quizsection = {
    subtitle:"Listen and point"
}
const questions = [{
    "_id":"question_82",
    "type":"listen_choose",
    "name":"Jordan",
    "answers":
    [{"name":"color","correct":true,"fileId":"file_26","fileLink":"attach/1625400172579.png"},
    {"name":"draw","correct":false,"fileId":"file_27","fileLink":"attach/1625400187584.png"},
    {"name":"raise your hand","correct":false,"fileId":"file_28","fileLink":"attach/1625400198207.png"},
    {"name":"read","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"},
    {"name":"listen","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"}

],
    "stageId":"stage_40",
    "catId":"category_1",
    "unitId":"unit_20",
    "quizId":"quizsection_2",
    "created":1625392118,
    "createdTime":"2021-07-04T09:48:38.383Z",
    "audio":"attach/1625394010949.mp3",
    "updated":1625400721,
    "updatedTime":"2021-07-04T12:12:01.076Z"}
]
function GoodJob(props) {

    const history = useHistory();
    const [levels,setLevels] = useState([1,2,3,4,5,6])
    const [correct,setCorrect] = useState(0)
    
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    useEffect(() => {
        
     document.getElementsByTagName("body")[0].style.background = "#EAF6FC";
     document.getElementById("goodjob-audio").play();

    }, [])
    return(<div id="mainapp" className="gameapp" style={{color:"#fff"}} >
            <div id="game-container" style={{paddingBottom:0}}>
                {/* <div id="vertical-menu">
                    <div id="vertical-logo"><img src={baseurl + "assets/header/logo.svg"} /></div>
                    <div id="vertical-vmenu">
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/read.svg"} />
                            <div>Read</div>
                        </div>
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/watch.png"} />
                        <div>Watch</div>

                        </div>
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/exercise.png"} />
                        <div>Exercise</div>

                        </div>
                    </div>
                    <div id="vertical-bottom">
                        <img src={baseurl + "assets/header/icons/alert.png"} />     
                        <img src={baseurl + "assets/header/icons/profile2.png"} />
                    </div>
                </div> */}
                <audio src={baseurl + "assets/game6/b1/Ending-great job.mp3"} id="goodjob-audio" />
                <VerticalMenu active={"Exercise"}  />
                <div id="mainquestion" style={{paddingTop:0}}>

                    <div className="goodjob" style={styles.container}>
                        <div style={styles.subcontaienr}>
                        {/* <Animate play start={{ transform: "scale(0)" }} end={{ transform: "scale(1)" }} delay={0.5}>
                        <img src={baseurl + "assets/progress/stars.png"} />
                        </Animate> */}
                        <div id="stars-list">

                            <div className="animate__animated  animate__bounceIn">
                            {/* <Animate play start={{ transform: "scale3d(0.3,0.3,0.3)" }} end={{ transform: "scale3d(1.1,1.1,1.1)" }} delay={0} duration={0.2}> */}
                                <Stars />
                            {/* </Animate> */}
                            </div>
                            <div className="animate__animated  animate__bounceIn">
                            <Stars />

                            {/* <Animate play start={{ transform: "scale(0)" }} end={{ transform: "scale(1)" }} delay={0.3} duration={0.2}><Stars />
                            </Animate> */}
                            </div>
                            <div className="animate__animated  animate__bounceIn">
                            <Stars />

                            {/* <Animate play start={{ transform: "scale(0)" }} end={{ transform: "scale(1)" }} delay={0.6} duration={0.2}><Stars />
                            </Animate> */}
                            </div>

                        </div>

                            <div style={styles.mainTitle}>Good Job</div>
                            {props.userAnswers.length > 0 && <div className='score-container'>Score: {correct} / {props.userAnswers.length}</div>}

                            <div class="button-check"><button onClick={() => {
                                window.location.href = baseurl + "L1/U2#1";
                                            // history.push(baseurl + "L1/U2#1")
                            }} style={{marginTop:50}} className="check-answer-bt arrow-right" variant="contained" color="primary">Unit 2</button></div>
                        </div>
                    </div>
                    
                    {/* <img src={baseurl + "assets/header/icons/maingroup.png"} /> */}
                </div>
            </div>
            

        
    </div>)
}

const styles = {
    container:{
        height:"100vh",
        width:"100%",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },
    mainTitle:{
        fontFamily:"fredokaone",
        color:"#22CBF2",
        fontSize:30,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        marginTop:50,
        fontSize:"5vw"
    },
    subcontaienr:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    }
}
export default GoodJob;