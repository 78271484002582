import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import BlueArrow from './../svgs/blue'
import BlueBg from './../svgs/blueBg'

const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);

    const [items,setItems] = useState([
        {
            id:"1",
            word:"Muzzy",
            word2:"Sylvia",
            fileLink1:baseurl + "assets/tracks/t08/muzzy.mp3",
            fileLink2:baseurl + "assets/tracks/t08/bob.mp3"
        },
        { 
            id:"2",
            word:"Bob",
            word2:"Corvax",
            fileLink1:baseurl + "assets/tracks/t08/seliva.mp3",
            fileLink2:baseurl + "assets/tracks/t08/corvex.mp3"
        }

        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i) => {
        let userSelect2 = [...userSelect];
        userSelect2[i] = value;
        setUserSelect(userSelect2);

    }

    const checkQuestion = () => {

//        setTimeout(() => {
            // history.push(baseurl + "look_write")
        // }, 3000);

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:"Read the names.",
            userAnswers:[],
            type:"read-names"
        }
        props.nextExercise(answerFormat);

        window.location.hash = "#18";
    }


    const stopAllAudios = (i) => {
        let audios = document.querySelectorAll("audio");
        audios.forEach((audio) => {
            audio.pause();
            audio.currentTime = 0;
        })
    }


    useEffect(() => {
        let currentItems = [...items];

        // currentItems = shuffle(currentItems);

        setTimeout(() => {
            document.getElementById("intr").play();
        },300)

        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    
    const playAudio = (i) => {

        // stopAllAudios();
        // document.getElementById("filelink1_" + i).play();
        // document.getElementById("filelink1_" + i).play();
    }

    const playAudio2 = (i) => {
        // stopAllAudios();

        // document.getElementById("filelink2_" + i).play();
        // document.getElementById("filelink2_" + i).play();

    }

    return(<div style={{width:"50%"}}>
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title">Read the names.</h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>
                {/* <GreenCircle style={{marginLeft:-30,marginTop:-30}} /> */}

        <div id="look_and_say" className="read_names">
            {randList.map((item,j) => {

                let first_letter = item.word[0];
                let other_words = item.word.substring(1,item.word.length)

                let first_letter2 = item.word2[0];
                let other_words2 = item.word2.substring(1,item.word2.length)


                return(
                <div className="item-v1">
                    <div className="look_words" style={{position:"static"}}>
                        <div className="word1" style={{position:"relative"}} onClick={() => playAudio(j)}>
                            <div className={"text-st0-" + j}><BlueBg style={{width:"100%",height:"auto"}} /></div>
                            {/* <div style={{position:"absolute",left:0,top:0,width:"100%",textAlign:"center"}}>{item.word}</div> */}
                            

                            <p className={"text-st"} style={{position:"absolute",left:0,top:0,width:"100%",textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>
                                <span>{first_letter}</span>
                                <span>{other_words}</span>
                            </p>

                        </div>
                        <div className="word2" style={{position:"relative"}} onClick={() => playAudio2(j)}>
                            <div className={"text-st1-" + j}><BlueBg style={{width:"100%",height:"auto"}} /></div>
                            <div className={"text-st"} style={{position:"absolute",left:0,top:0,width:"100%",textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>
                                <span>{first_letter2}</span>
                                <span>{other_words2}</span>
                            </div>
                        </div>
                        <audio id={"filelink1_" + j} src={item.fileLink1}  />
                        <audio id={"filelink2_" + j} src={item.fileLink2}  />


                    </div>
                   
                </div>)
            })}

{/* <audio src={`${baseurl}assets/muzzy/audio/look_write.mp3`} className="hidden-audio" id="intr" /> */}
<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />


                <audio src={`${baseurl}assets/levels/level1/unit1/assets/audios/read_the_names.mp3`} className="hidden-audio" id="intr" />

        </div>

             

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>

                </div>


    </div>)
}
export default DragAndDrop;