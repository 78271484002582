import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import GreenCircle from './../svgs/greenCircle'
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);

    const [items,setItems] = useState([
       
        {
            id:"2",
            word:"I am Muzzy",
            img:baseurl +"assets/game2/item2.png",
            words:[
                {
                    text:"",
                    answers:["I am","I'm"]
                },
                {
                    text:"Muzzy."
                }
            ]
        },
        {
            id:"4",
            word:"Iam Sylvia.",
            img:baseurl +"assets/game2/item5.png",
            words:[
                {
                    text:"",
                    answers:["I am","I'm"]
                },
                {
                    text:"Sylvia."
                }
            ]
        },
        {
            id:"1",
            word:"I am Bob.",
            img:baseurl +"assets/game2/item1.png",
            words:[
                {
                    text:"",
                    answers:["I am","I'm"]
                },
                {
                    text:"Bob."
                }
            ]
        },
        {
            id:"3",
            word:"I am Corvax",
            img:baseurl +"assets/game2/item3.png",
            words:[
                {
                    text:"",
                    answers:["I am","I'm"]
                },
                {
                    text:"Corvax."
                }
            ]
        }
        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i) => {
        let userSelect2 = [...userSelect];
        userSelect2[i] = value;
      //  setUserSelect(userSelect2);

    }
    const correctAnswers = ["I am","i am","I'm","I 'm"];

    const checkQuestion = () => {

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:"Look, read, and write.",
            userAnswers:[],
            type:"look-read-and-write"
        }

        let answersv = document.querySelectorAll(".selectortext");
        let userSelect2 = []

        answersv.forEach((answerItem) => {
            userSelect2.push(answerItem.value)
        })

        let items2 = [...randList];
        let allcorrect = true;
        userSelect2.forEach((item,i) => {

            // console.log("item_v2v",item,correctAnswers[0]);
            if(correctAnswers.indexOf(item) === -1) {
                allcorrect = false;
                items2[i].check = false;
            } else {
                items2[i].check = true;
            }
            answerFormat.userAnswers.push({
                userAnswer:item,
                correctAnswer:correctAnswers[0],
                correct:items2[i].check
            })

        })
        
        if(allcorrect === true) {
            document.getElementById("correct-au").play();
        } else {
            document.getElementById("wrong-au").play();
        }
        setRandList(items2);
        setCheckAnswers(true);

        answerFormat.pass = allcorrect;

        setTimeout(() => {
            //  history.push(baseurl + "talk_to_friend")
            props.nextExercise(answerFormat);
            window.location.hash = "#12"
        }, 3000);
        
    }


    useEffect(() => {
        let currentItems = [...items];

        //currentItems = shuffle(currentItems);

        setTimeout(() => {
            document.getElementById("intr").play();
        },300)

        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    return(<div id="look_write" className="look_read_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title">Look, read, and write.</h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>
                <div style={{position:"relative"}}>

                <GreenCircle style={{marginLeft:-30,marginTop:-30}}
                style={{
                    marginLeft: "-30px",
                    marginTop: "-30px",
                    width: 200,
                    height: "auto"
                }}
                />

<div id="look_write-items-v1" style={{marginTop:-100}}>
    {randList.map((item,j) => {
        return(
        <div className="look_write-item-v1">

            {item.check === undefined ?
                <img src={item.img} />:item.check === false ?
                
                <img style={{border:"5px solid #F84E53"}} src={item.img} />:
                <img style={{border:"5px solid #83cd83"}} src={item.img} />}
            
            <div className="word-v1">
                {item.words.map((subWord,i) => {


                    if(subWord.text === "") {
                        return(<div className={"editable-input"} ><input spellCheck="false"  className="selectortext" style={{borderBottom:"2px dashed #B7BCD1",width: 100
                    }} type="text" onChange={(e) => {changeTxt(e.target.value,j)}} /></div>)
                    } else {
                        return(<div className={subWord.text === ""?"editable":"readable"} contentEditable={subWord.text === ""?true:false}>{subWord.text === ""?userSelect[i]:subWord.text}</div>)
                    }

                })}

<audio src={`${baseurl}assets/levels/level1/unit1/assets/audios/look_read_and_write.mp3`} className="hidden-audio" id="intr" />
<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
        <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

            </div>
        </div>)
    })}
</div>

                </div>
 

             

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>

                </div>


    </div>)
}
export default DragAndDrop;