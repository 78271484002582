import React,{useState,useEffect} from 'react'
import Header from './../components/header';
import {Link,useHistory} from 'react-router-dom'
import {connectAdvanced, useDispatch} from 'react-redux';
import HeaderV2 from './../components/headerV2';
import axios from 'axios';
import { useSelector } from 'react-redux';

const baseurl = process.env.REACT_APP_FRONT_LINK;
const backendurl = process.env.REACT_APP_BACKEND_URL;

function Home() {
    const [items,setItems] = useState(["avatar1","avatar2","avatar3","avatar4","avatar5","avatar6"])
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const selectAvatar = (i) => {
        document.getElementById("click-bt").play();

        // setTimeout(() => {

        axios({
            method:"post",
            url:backendurl + "/user/updatedata",
            data:{avatar:i,id:user._id}
        }).then((res) => {
            console.log("avatar",i)
            dispatch({type:"update_avatar",avatar:i})
            history.push(`${baseurl}levels`);
        })
    
        // }, 500);
    }


    useEffect(() => {
        
        document.getElementsByTagName("body")[0].style.background = "#22CCF2";
   
       }, [])

    return(
    <>
<div id="paints"><HeaderV2 /></div>                    

    <div id="mainapp" style={{color:"#fff"}} >
        <Header />
        <h1 align="center">Select avatar</h1>
        <div id="avatar-select">
            
        {items.map((item,i) => {
            return(<div className="avatar-select-item" style={{cursor:"pointer"}} onClick={() => selectAvatar(i)}>
                {/* <Link to={baseurl + "levels"}> */}
                    <div className="white-avatar"></div>
                    <img src={`${baseurl}assets/avatars/home/resized/item${i}.png`} />
                {/* </Link> */}
            </div>)
        })}
        </div>

        <audio src={baseurl + "assets/audio/click.mp3"} id="click-bt" style={{display:"none"}} />
        
    </div>
    </>
    )
}
export default Home;