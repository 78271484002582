import { getArrow } from 'curved-arrows'
import {useEffect} from 'react';

const arrowHeadSize = 9
const color = 'black'

function DrawLine(props) {
    let [sx, sy, c1x, c1y, c2x, c2y, ex, ey, ae] = getArrow(props.p1.x, props.p1.y, props.p2.x, props.p2.y, {
        padEnd: arrowHeadSize,
      })



    return(
                <div style={{position:"absolute",top:0,left:0,width:"100%",height:"100%",zIndex:1111}}>

          
            
                           <svg
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg">
              
<path
                d={`M ${sx} ${sy} ${ex} ${ey}`}
                stroke={"#B7BCD1"}
                strokeWidth={arrowHeadSize / 2}
                fill="none"
              />

            </svg> 

            );


        </div> 
    )
}
export default DrawLine;