import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import TickIcon from './../components/svgs/tick'
import CrossIcon from './../components/svgs/cross'

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

const audioStyle=  {
    background:"#0B329D",width:30,height:30,display:"inline-block",alignItems:"center",justifyContent:"center",borderRadius:"100%",padding:10,marginLeft:10,cursor:"pointer"
}
function DragAndDrop(props) {
    
    const history = useHistory();
    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState([
    
    ]);
    const [items,setItems] = useState([
       
        ]);

        const [randList,setRandList] = useState([]);
        const [randListV1,setRandListV1] = useState([]);
        const [mainItems,setMainItems] = useState([]);

        const checkQuestion = () => {

            
            let items2 = [...mainItems];
            let allcorrect = true;

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:props.questions.title,
                userAnswers:[],
                type:"completeRevTable"
            }



            items2.forEach((item,i) => {
                item.mainItems.forEach((questionItem,j) => {
                    questionItem.spans.map((spanItem,m) => {

                        if(spanItem.type === "input") {
                            spanItem.userAnswer = spanItem.userAnswer.replaceAll("’","'").trim();
                            spanItem.text = spanItem.text.replaceAll("’","'").trim();

                            if(spanItem.answers !== undefined) {
                                if(spanItem.answers.length > 0) {
                                    if(spanItem.answers.indexOf(spanItem.userAnswer) === -1) {
                                        allcorrect = false;
                                        items2[m].questions[i].correct = false;
                                    } else {
                                        items2[m].questions[i].correct = true;
                                    }
                                }
                            }

                            else if(spanItem.fullAnswers !== undefined) {
                                items2[i].mainItems[j].correct = true;
                            }
                            else if(spanItem.answers !== undefined) {
                                items2[i].mainItems[j].correct = true;
                            }
                            else if(spanItem.open === true) {
                                items2[i].mainItems[j].correct = true;
                            }
                            else if(spanItem.userAnswer.toLowerCase() !== spanItem.text.toLowerCase()) {




                                // console.log(questionItem.spans,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())

                                // console.log(allcorrect,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())
                                allcorrect = false;
                                items2[i].mainItems[j].correct = false;
                            } else {
                                items2[i].mainItems[j].correct = true;
                            }

                            if(spanItem.answers !== undefined) {
                                answerFormat.userAnswers.push({
                                    userAnswer:spanItem.userAnswer,
                                    correctAnswer:spanItem.answers[0],
                                    correct:items2[m].questions[i].correct
                                })
                            } else {
                                answerFormat.userAnswers.push({
                                    userAnswer:spanItem.userAnswer,
                                    correctAnswer:spanItem.text,
                                    correct:items2[m].questions[i].correct
                                })
                            }
                        }

                    });
                });
            });




            if(allcorrect === true) {
                document.getElementById("correct-au").play();
            } else {
                document.getElementById("wrong-au").play();
            }
            answerFormat.pass = allcorrect;
            setMainItems(items2);
            setTimeout(() => {
                props.nextExercise();
                // history.push(baseurl + "look_listen_say")
            }, 3000);
            
        }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i,j,m) => {
        let mainItems2 = [...mainItems];
        mainItems2[i].mainItems[j].spans[m].userAnswer = value;
        setMainItems(mainItems2);
      }

    useEffect(() => {
        setTimeout(() => {
            document.getElementById("intr").play();
        },300)
        let currentItems = [...items];

        // currentItems = shuffle(currentItems);

        setRandList(currentItems);
        document.getElementById("intr").play();

        setRandListV1(props.questions.answers)

        setMainItems(props.questions.items);

    }, [props])

    
    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const checkQuestionAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].questions[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);
    }

    const checkAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].answers[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);

    }
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    return(<div id="complete_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title" dangerouslySetInnerHTML={{ __html: props.questions.title }}></h1>
                </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>

                </div>  
                {props.questions.video !== "" && <div style={{maxWidth:"60vw",minWidth:"60vw",marginBottom:50}}>
                
                <div class="video-intro" style={{height: "50vh",width:"100%"}}><img onClick={videoPlay} src={baseurl + "assets/game1/play.png"} id="video-waf" /><video style={{width:"100%"}} id="video-lin" src={baseurl + props.questions.video} controls></video></div>
                </div>}



                <div id="complete-items-v1" style={{flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    maxWidth: "60vw"}}>
                    {mainItems.map((item,i) => {
                        return(<div style={{width:"48%",padding:"0 2% 0 0"}}>
                            <div><img src={baseurl + item.image} /></div>

                            
                            {item.mainItems.map((subItem,j) => {
                            return(<div className='items-v1' style={{width:"100%",boxSizing:"border-box",position:"relative",marginBottom:10,marginTop:10,paddingRight:70}}>{subItem.spans.map((subItem1,m) => {

                                if(subItem1.type === "input") {

                                    let border = {};
                                    if(subItem1.border === true) {
                                        border = {border:"3px dashed #1BAFDB",borderRadius:10}
                                    }
                                    return(<span><input spellCheck="false"  type='text' style={{width:"auto",...border}} value={subItem1.userAnswer} 
                                     size={subItem1.text.length} maxLength={subItem1.text.length} onChange={(e) => changeTxt(e.target.value,i,j,m)} />
                                    
                                    </span>)
                                } else {
                                    return(<span dangerouslySetInnerHTML={{__html:subItem1.text}}></span>)
                                }

                            })}
                            
                            {subItem.correct !== undefined && 
                            <>
                            {subItem.correct === true &&  <div style={{position:"absolute",right:25,top:25}}><TickIcon /></div>}
                            {subItem.correct === false &&  <div style={{position:"absolute",right:25,top:25}}><CrossIcon /></div>}


                            </>
                            }
                            {subItem.audio !== undefined &&
                            <>
                            <div id="audio-bt"
                            style={{
                               background: "#0B329D",
                               borderRadius: "100%",
                               padding: 10,
                               width: 24,
                               height: 24,
                               cursor: "pointer",
                               display: "flex",
                               alignItems: "center",
                               justifyContent: "center",
                               position: "absolute",
                               right: 20,
                               top: 20
                            }}
                            onClick={(e) => {
                                document.getElementById("audio--" + j + "-indx-" + i).play();
                            }}
                            ><img src={baseurl + "assets/audio/listen.png"} /></div>
                            <audio src={baseurl + subItem.audio} id={"audio--" + j + "-indx-" + i} />
                            </>
                            }
                             
                            </div>)
                        })}</div>)
                    })}                    
                </div>


                
                <div style={{display:"flex",alignItems:"flex-start"}}>
               
                


        {props.questions.images !== undefined && 
                    <div style={{maxWidth:"60vw",flexDirection:"column",marginLeft:20,display:"flex",alignItems:"center",justifyContent:"center",gap:20,marginBottom:30,padding:"20px 0px"}}>
                        {props.questions.images.map((item) => <div>
                            <img src={baseurl + item.url} />
                        </div> )}
                </div>}

        </div>

            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

            <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>
            </div>


    </div>)
}
export default DragAndDrop;