import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _, { forEach } from 'lodash';
import { useHistory } from "react-router-dom";
import { Animate } from 'react-simple-animate';

const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]

function ListenAndChooseTheWord(props) {
    const [data,setData] = useState([
       
    ]);
    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState("");
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [answer,setAnswer] = useState(0);
    const history = useHistory();
    const [userAnswerV2,setUserAnswersV2] = useState([]);

    const [answerFormat,setAnswerFormat] = useState({
        pass:true,
        progress:0,
        questionTitle:props.questions.title,
        userAnswers:[],
        type:"read_match"
    });

    const instr = useRef();


    useEffect(() => {
            let selected = props.questions.answers;
            setCurrent(0);
            // selected = _.shuffle(selected);

            let currenttime = 4000;

            if(props.questions.timeout !== undefined) {
                currenttime = props.questions.timeout;
            }


            // document.getElementById("intr").pause();
            if(selected.length > 0) {
                setQuestion(selected[0])
                setAudio(selected[0].audio);

                setTimeout(() => {
                    document.getElementById("intr").play();

                }, currenttime);


            }
            setData(selected);

            setTimeout(() => {
                document.getElementById("intr2").play();

            }, 500);
                

    }, [props.questions])

    const checkQuestion = (e) => {

        
        let answerFormat2  = {...answerFormat};
        
        let counterNext = current;
       // let currentData = data;

        counterNext++;

        let allcorrect = true;
        // console.log(question.userAnswers,question.correctAnswers);
        if(JSON.stringify(question.userAnswers.sort()) !== JSON.stringify(question.correctAnswers.sort())) {
            allcorrect = false;
            answerFormat2.pass = false;
        }

        answerFormat2.userAnswers.push({
            userAnswer:question.userAnswers.sort(),
            correctAnswer:question.correctAnswers.sort(),
            correct:allcorrect
        });
        // question.userAnswers.forEach((item) => {
        //     if(question.correctAnswers.indexOf(item) === -1) {
        //         allcorrect = false;
        //     } 
        // });
 




        if(allcorrect === true) {
           // props.playAudio(true);
            // setShowCorrect(true);
            document.getElementById("correct-au").play();
            setQuestion({
                ...question,
                correctAnswer:true
            })
        } else {
            answerFormat2.pass = false;

          //  props.playAudio(false);
            // setShowCorrect(true);
            document.getElementById("wrong-au").play();
            setQuestion({
                ...question,
                correctAnswer:false
            })
        }

        setAnswerFormat(answerFormat2);

        setTimeout(() => {

            if(counterNext < data.length) {
                let selected =  data[counterNext];
                selected.answers = _.shuffle(selected.answers);
                let find = selected.answers.findIndex((item) => item.correct === true);
    
                setQuestion(selected);
                setAudio(selected.audio);
                setShowCorrect(false);
                setUserSelect("");
                setUserAnswersV2([])
                setCurrent(counterNext);  
                document.getElementById("intr").play();

                setTimeout(() => {
                    // document.getElementById("intr2").play();

                    
                },300)

            } else {
                console.log("answerFormat",answerFormat2);
                props.nextExercise(answerFormat2);
               // history.push(baseurl + "read_names")
            }
        }, 3000);


        /*setTimeout(() => {
            history.push(baseurl + "drag")
        }, 3000);*/


        // setTimeout(() => {
        //     setShowCorrect(false);

        //     if(data[counterNext] !== undefined) {
        //         currentData[counterNext].complete = true;
        //         data[counterNext].answers = _.shuffle(data[counterNext].answers);

                
    
        //         setData(currentData);
        //         setCurrent(counterNext);
        //         setQuestion(data[counterNext])
        //         setUserSelect("");
        //     } else {
        //         props.setShowGoodJob(true);
        //     }
        //     setTimeout(() => {
        //         if(document.getElementById("question-audio") !== null) {
        //             document.getElementById("question-audio").play();
        //         }
        //     }, 500);
        // }, 3000);

    }

    const playAudio = (e) => {
        if(document.getElementById("question-audio") !== null) {
            document.getElementById("question-audio").play();
        }
    }

    const checkUserSelect = (userSelect,indx) => {
        if(userSelect === indx) {
            return ("selected multiple-question-item");
        } else {
            return ("multiple-question-item");
        }
    }

    const checkCorrect = (userSelect,indx,correct) => {

        if(correct === true) {
            return("multiple-question-item selected correct")
        } else {
            if(userSelect === indx) {
                return ("selected wronganswer multiple-question-item");
            } else {
                return ("multiple-question-item");
            }
        }
        
    }

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }


    return(<div style={{width:"40vw"}}>
        

 

        <div className="question-title-container">
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img style={props.questions.removeIcon !== true ?{}:{opacity:0}} src={baseurl + "assets/game5/listen.svg"} />
                <h1 className="question-title" dangerouslySetInnerHTML={{ __html: props.questions.title }}></h1>

            </div>
            <div id="audio-bt" onClick={playIntr} style={props.questions.removeIcon !== true ?{}:{opacity:0}}><img src={baseurl + "assets/audio/listen.png"} /></div>
                </div>
                {question.question !== undefined && <div align="center" style={{color:"#0B329D",fontFamily:"gotham-medium",fontSize:25,marginTop:50}}>{question.question}</div>}

        <div className="question-container circle-and-read">
            {question.words !== undefined && 
                <div className="circle-and-read-container" style={{height: "30vh"}}>

                <audio src={`${baseurl}${audio}`} className="hidden-audio" id="intr" />



               {question.words.map((item) => {
                   if(item === "") {
                    return(<div className="text-item options-item" style={{flexWrap:"wrap"}}>
                        {question.options.map((subItem,j) => {
                            return(<div style={
                                question.correctAnswer === undefined?
                                
                                question.userAnswers.indexOf(j) !== -1 ? {
                                    boxShadow: "0px 6px 0px #E4B305",
                                    background: "#F9CB2E"
                                }:{}


                                :
                                question.correctAnswers.indexOf(j) !== -1?{
                                    boxShadow: "0px 6px 0px #608F30",
                                    background: "#7EBE40"
                                }:{
                                    boxShadow: "0px 6px 0px #CA0000",
                                    background: "red"
                                }




                                
                                
                            } onClick={(e) => { 

                                let question2 = {...question};
                                let find = question2.userAnswers.indexOf(j);
                                if(find == -1) {
                                    question2.userAnswers.push(j);
                                } else {
                                    question2.userAnswers.splice(find,1);
                                    //  delete question2.userAnswers[find];
                                }

                                setQuestion(question2)

                                // if(userAnswerV2.indexOf(j) === -1) {
                                //     setUserAnswersV2([...userAnswerV2,j])
                                // } else {
                                //     let userAnswerV22 = [...userAnswerV2];
                                //     userAnswerV22 = userAnswerV22.filter((item) => item !== j);
                                //     setUserAnswersV2(userAnswerV22);
                                // }
                        }}>{subItem}</div>);
                        })}
                    </div>)
                   } else {
                    return(<div className="text-item">{item}</div>)

                   }
                })}

              

                </div> 
            }
            <div className="button-check">
            <button className="check-answer-bt" onClick={checkQuestion} variant="contained" color="primary">Check my answer</button>

            </div>

<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                            {/* <audio src={`${baseurl}assets/tracks/t08/track8v1.mp3`} className="hidden-audio" id="intr2" /> */}
                            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr2" />

        </div>


    </div>)
}

export default ListenAndChooseTheWord;