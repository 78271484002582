import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";

const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]


function ReadAndMatch(props) {
    const [data,setData] = useState([
        {
            img:"item1",
            text:"the King"
        },
        {
            img:"item2",
            text:"the Queen"
        },
        {
            img:"item3",
            text:"Sylvia"
        },
        {
            img:"item4",
            text:"Bob"
        },
        {
            img:"item5",
            text:"Corvax"
        },
        {
            img:"item6",
            text:"Muzzy"
        }
    ]);
    const [data2,setData2] = useState([
        {
            img:"item1",
            text:"the King"
        },
        {
            img:"item2",
            text:"the Queen"
        },
        {
            img:"item3",
            text:"Sylvia"
        },
        {
            img:"item4",
            text:"Bob"
        },
        {
            img:"item5",
            text:"Corvax"
        },
        {
            img:"item6",
            text:"Muzzy"
        }
    ]);
    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState([{},{},{},{},{},{}]);
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [selected,setSelected] = useState({});

    const history = useHistory();


    useEffect(() => {
            
        let data3 = [...data2];
        data3 = _.shuffle(data3);
        setData2(data3);

        setTimeout(() => {
            document.getElementById("intr").play();
        },300)
    }, [props])


    const drag = (e,select) => {
        setSelected(select);
        e.preventDefault();
    }


    const playIntr = (e) => {
        document.getElementById("intr").play();

    }

    const allowDrop = (e) => {     
        // if(e.target.classList.contains("drop-item")) {
        //     e.target.classList.style.opacity = "0.5";
        // } else {
        
        //     e.target.parentElement.style.opacity = "0.5";
        // }
        if(e.target.classList.contains("read-drop-item")) {
        }
        else if(e.target.classList.contains("drop-item")) {
            if(e.target !== undefined) {
                e.target.style.opacity = "0.5";
                e.target.style.transform = "scale(0.9)";

            }
        } else if(e.target.classList.contains("drop-img")) {
            if(e.target.parentElement !== undefined) {
                e.target.parentElement.style.opacity = "0.5";
                e.target.parentElement.style.transform = "scale(0.9)";

            }
        }
        e.preventDefault();
        
    }

    const dragLeave = (e) => {    

        if(e.target.classList.contains("read-drop-item")) {
        }
        else if(e.target.classList.contains("drop-item")) {
            if(e.target !== undefined) {
                e.target.style.opacity = "1";
                e.target.style.transform = "scale(1)";

            }
        } else if(e.target.classList.contains("drop-img")) {
            if(e.target.parentElement !== undefined) {
                e.target.parentElement.style.opacity = "1";
                e.target.parentElement.style.transform = "scale(1)";
            }
        }
        e.preventDefault();
        
    }

    const checkQuestion = (e) => {

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:"Read and match.",
            userAnswers:[],
            type:"Read-and-match"
        }

        let userSelectCheck = JSON.stringify(userSelect);
        let dataCheck = JSON.stringify(data);
        if(userSelectCheck === dataCheck) {
            document.getElementById("correct-au").play();
            let listItem = document.querySelectorAll(".r-item");
            listItem.forEach(element => {
                element.style.background = "green";
                element.querySelector("img").style.background = "green"
            });
        } else {
            let listItem = document.querySelectorAll(".r-item");
            listItem.forEach((element,i) => {
                console.log(i)
                if(userSelect[i].text === data[i].text) {
                    element.style.background = "green";
                    element.querySelector("img").style.background = "green"
                } else {
                    element.style.background = "red";
                    element.querySelector("img").style.background = "red"    
                }
            });
            document.getElementById("wrong-au").play();
            answerFormat.pass = false;
        }

      

        data.forEach((item,i) => {
            answerFormat.userAnswers.push({
                userAnswer:userSelect[i].text,
                correctAnswer:item.text,
                correct:userSelect[i].text === item.text?true:false
            })
        });
        setTimeout(() => {
           // history.push(baseurl + "sorting"); 
           props.nextExercise(answerFormat);
           window.location.hash = "#7";
        }, 2000);


    }

    const drop = (e,id) => {
        document.getElementById("clickbt").play();
        let selectedDom = "";
        let userSelectSelected = [...userSelect];
        let dataSelected = [...data2]

        if(e.target.classList.contains("drop-item")) {
            selectedDom = e.target            
        } else {
            selectedDom = e.target.parentElement.querySelectorAll(".drop-item")
            selectedDom = selectedDom[0];
        }
        selectedDom.parentElement.style.opacity = "1";
        selectedDom.parentElement.style.transform = "scale(1)";
        if(selectedDom.innerHTML !== "") {
            // console.log(data,selectedDom.innerHTML)
            let find = data.findIndex((item) => item.text === selectedDom.innerHTML)
            
            if(find !== -1) {
                dataSelected.push(data[find]);
            }
        }
        selectedDom.innerHTML = selected.text;

        userSelectSelected[id] = selected;

        setUserSelect(userSelectSelected);


        dataSelected = dataSelected.filter((item) => item.text !== selected.text);

        setData2(dataSelected);

        e.preventDefault();
    }

    return(<div style={{width:"60%"}}>
        

 

        <div className="question-title-container" >
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/listen.svg"} />
                <h1 className="question-title">{props.quizsection.subtitle}</h1>

            </div>
            <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
        </div>
        <div className="read-question-data">
            <div className="read-drop-list">
                {data.map((item,i) => {
                    return(<div className="read-drop-item r-item" id={"read-drop-item-" + i} onDragOver={allowDrop} onDragLeave={dragLeave} onDrop={(e) => {drop(e,i)}}>
                        <div className={"drop-item"}></div>
                        <img className={"drop-img"} src={`${baseurl}assets/game6/${item.img}.png`} />
                    </div>)
                })}
            </div>
            <div className="read-drop-text">
                {data2.map((item) => {
                    return(<div draggable={"true"} className="read-drop-item" onDrag={(env) => {drag(env,item)}}>{item.text}</div>)
                })}
            </div>
        </div>

        <div className="button-check">
                <button className="check-answer-bt" onClick={checkQuestion} disabled={userSelect === ""?true:false} variant="contained" color="primary">Check my answer</button>

                </div>

                {/* <audio src={`${baseurl}assets/audio/read_match.mp3`} className="hidden-audio" id="intr" /> */}
                <audio src={baseurl + "assets/levels/level1/unit1/assets/audios/read_match.mp3"} className="hidden-audio" id="intr" />

                
                <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

    </div>)
}

export default ReadAndMatch;