import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import TickIcon from './../components/svgs/tick'
import CrossIcon from './../components/svgs/cross'

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    
    const history = useHistory();
    const [leftItems, setLeftItems] = useState([]);
    const [rightItems, setRightItems] = useState([]);
    const [mainItems, setMainItems] = useState([]);
    const [dragItem,setDragItem] = useState({});


        const [randListV1,setRandListV1] = useState([]);

        const checkQuestion = () => {

            
            let items2 = [...mainItems];
            let allcorrect = true;


            items2.forEach((item,i) => {
                let userAnswer = item.userAnswer.map((item) => item.title);
                let correctAnswer = item.correctAnswer;
                userAnswer = userAnswer.sort();
                correctAnswer = correctAnswer.sort();

                if(JSON.stringify(userAnswer) !== JSON.stringify(correctAnswer)) {
                    allcorrect = false;
                    items2[i].correct = false;
                } else {
                    items2[i].correct = true;
                }

            });
            // items2.forEach((item,m) => {
            //     item.questions.forEach((questionItem,i) => {
            //         questionItem.spans.map((spanItem,j) => {
            //             if(spanItem.type === "input") {
            //                 if(spanItem.userAnswer !== spanItem.text) {
            //                     allcorrect = false;
            //                     items2[m].questions[i].correct = false;
            //                 } else {
            //                     items2[m].questions[i].correct = true;
            //                 }
            //             }

            //         });
            //     });


            // });



            if(allcorrect === true) {
                document.getElementById("correct-au").play();
            } else {
                document.getElementById("wrong-au").play();
            }
            setMainItems(items2);
            setTimeout(() => {
               props.nextExercise();
                // history.push(baseurl + "look_listen_say")
            }, 3000);
            
        }

        const dragStart = (e) => {
            console.log(e.target)
        }

        const dragover = (e) => {
            
        }

        const dragend = (e) => {

        }

    useEffect(() => {
        setLeftItems(props.questions.leftItems);
        // setRightItems(props.questions.rightItems);
        setMainItems(props.questions.mainItems);

        document.getElementById("intr").play();

        // window.addEventListener("dragstart",dragStart);
        // window.addEventListener("dragover",dragover);
        // window.addEventListener("dragend",dragend);
  
  
        // return () => {
        //   window.removeEventListener("dragstart",dragStart);
        //   window.removeEventListener("dragover",dragover);
        //   window.removeEventListener("dragend",dragend);
        // }

    }, [])

    
    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const checkQuestionAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].questions[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);
    }

    const checkAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].answers[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);

    }

    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    const dragItemFunc = (e,location,i,item) => {
        setDragItem({location:location,id:i,item:item})
    }

    const dropItemFunc = (e,i) => {


        let mainItems2 = [...mainItems];

        console.log(dragItem,mainItems2)
        if(dragItem.location === "left") {
            setLeftItems(leftItems => leftItems.filter((item,j) => j !== dragItem.id))
            // setMainItems((mainItems) => {mainItems[i].push(leftItems[dragItem.id]}))
            mainItems2[i].userAnswer.push(leftItems[dragItem.id])
        }
        if(dragItem.location === "right") {
            // setRightItems(rightItems => rightItems.filter((item,j) => j !== dragItem.id))
            // setMainItems((mainItems) => mainItems[i](rightItems[dragItem.id]))
            // mainItems2[i].userAnswer.push(rightItems[dragItem.id])

        }


        setMainItems(mainItems2)
        
        


    }

    const allowDrop = (ev) => {     
        ev.preventDefault();
    }

    return(<div id="complete_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title">{props.questions.title}</h1>
                </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>

                </div>
 
        <div id="complete-items-v1" style={{minWidth:"60vw",paddingTop:"70px"}} className='dragtable' >

            {props.questions.video !== undefined && props.questions.video !== "" &&<div className="video-intro" style={{height:"50vh",width:"100%",marginBottom:50}}>
                        <img src={baseurl + "assets/game1/play.png"} onClick={videoPlay} id="video-waf" />
                        <video id="video-lin" src={baseurl + props.questions.video} controls />

                    </div>}

            <div style={{
                    display: "flex",
                    justifyContent: "space-between"
            }}>
                
                <div className='main-items' style={{width:"100%",
                minHeight: 400,
                marginBottom: 40
            }}>
                    {mainItems.map((item,i) => {

                        let color = "#22CBF2";
                        if(item.correct !== undefined) {
                            if(item.correct === true) {
                                color = "#7EBE40";
                            } else {
                                color = "#FC4F63";
                            }
                        }
                        return(<div className='main-subitems' style={{border:"1px solid #fff"}}>
                            <div style={{background:"#fff",textAlign:"center"}}>
                                
                                
                                {props.questions.mainType === "text"?
                                    <div style={{fontSize:25,padding:"20px",color:"#0B329D",fontFamily:"gotham-medium",padding:"20px 50px",background:"#D1F1F9"}}>
                                        <div style={{background:color,color:"#fff",padding:"10px 0px",position:"relative",
                                        top: -54,
                                        borderRadius: 40,
                                    }}>{item.title}</div>
                                    </div>:
                                    <img src={baseurl + item.image} />
                                }
                            </div>
                            <div onDrop={(e) => dropItemFunc(e,i)} onDragOver={allowDrop}  className='drop-subitems'>
                                {item.userAnswer.map((item) => {
                                    return(<div style={{position:"relative"}}>
                                        
                                        {props.questions.mainType === "image" && <div style={{display:"flex",alignItems:"center",justifyContent:"center",height:250,width:250,margin:"auto" }}><img src={baseurl + item.image} /></div>}
                                        {props.questions.mainType === "text" && <div style={{display:"flex",alignItems:"center",justifyContent:"center",margin:"auto" }}>
                                            <div style={{background:"#22CBF2",color:"#fff",fontFamily:"gotham-medium",padding:"15px 30px",fontSize:22,borderRadius:20,boxShadow:"0px 5px 0px #1bafdb",marginBottom:20}}>{item.title}</div>
                                        </div>}

                                    
                                    
                                    {props.questions.showSubTitle === true && <div style={{position:"absolute",bottom:20,width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                        <div className='bluebt'>{item.title}</div></div>}
                                    </div>)
                                })}
                                </div>                       
                        </div>)
                    })}
                </div>
            </div>
            <div>
                <div className='bottom-items' style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                        {leftItems.map((item,i) => {
                            return(<div style={{position:"relative"}}>
                                {props.questions.mainBottomType === "text" ? 
                                <>
                                 <div  onDrag={(e) => dragItemFunc(e,"left",i,item)} style={{bottom:0,width:"100%",display:"flex",alignItems:"center",justifyContent:"center",position:"relative",zIndex:"11"}}>
                                <div className='bluebt blueshadow' style={{padding:"15px 30px",fontSize:22,margin:"0px 10px"}} draggable={true}>{item.title}</div></div>
                                </>
                               
                                :<img src={baseurl + item.image} />}
                                
                                {/* {props.questions.showSubTitle === true && <div style={{position:"absolute",bottom:30,width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}}>
                                                            <div className='bluebt'>{item.title}</div></div>} */}
                            </div>)
                        })}
                    </div>
            </div>
        </div>

            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

            <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>
            </div>


    </div>)
}
export default DragAndDrop;