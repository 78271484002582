import React,{useState} from 'react'
import SortingEngine from './../engines/ListenAndRead.js';
import VerticalMenu from './../components/VerticalMenu';
import { useEffect } from 'react';

import HeaderTop from './../components/headerTop';


const baseurl = process.env.REACT_APP_FRONT_LINK;

const quizsection = {
    subtitle:"Listen and point"
}
const questions = [{
    "_id":"question_82",
    "type":"listen_choose",
    "name":"Jordan",
    "answers":
    [{"name":"color","correct":true,"fileId":"file_26","fileLink":"attach/1625400172579.png"},
    {"name":"draw","correct":false,"fileId":"file_27","fileLink":"attach/1625400187584.png"},
    {"name":"raise your hand","correct":false,"fileId":"file_28","fileLink":"attach/1625400198207.png"},
    {"name":"read","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"},
    {"name":"listen","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"}

]
}]

function Sorting() {
    const [levels,setLevels] = useState([1,2,3,4,5,6])
    


    useEffect(() => {
    
        document.getElementsByTagName("body")[0].style.background = "#EAF6FC";
       
       }, [])

    return(<div id="mainapp" className="gameapp" style={{color:"#fff"}} >
            <div id="game-container">
  
                <VerticalMenu active={"Exercise"} />
                <div id="mainquestion">

                    {/* <img src={baseurl + "assets/progress/3.png"} className="mainquestion-levels" /> */}
                    <HeaderTop progress={60} />

                    <SortingEngine />
                </div>
            </div>
            

        
    </div>)
}
export default Sorting;