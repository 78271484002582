import React,{useState,useEffect,useRef} from 'react'
import { useAnimate, useAnimateKeyframes, useAnimateGroup } from 'react-simple-animate';

const baseurl = process.env.REACT_APP_FRONT_LINK;

export default function CharacherItem(props) {
    let { style, play } = useAnimate({ start: { transform: "scale(0)",transformOrigin:"center",opacity:0 }, end: { transform: "scale(1)",opacity:1,transformOrigin:"center" },delay:props.delay,duration:0.3 });
    // const { style2, play2 } = useAnimate({ start: { transform: "scale(0)",transformOrigin:"center",opacity:0 }, end: { transform: "scale(1)",opacity:1,transformOrigin:"center" },delay:props.delay,duration:0.3 });
    const audio = useRef(null);
    const item = useRef(null)
    
    useEffect(() => {
        play(true)
     
    }, [])

    const itemClick = () => {
        item.current.classList.add("animate__bounceIn")

        setTimeout(() => {
            item.current.classList.remove("animate__bounceIn")

        }, 600);
        
        style = {
            ...style,
            opacity:0
        }
        let audios = document.getElementsByTagName("audio");
        for(let i =0 ;i<audios.length;i++) {
            audios[i].pause();
            audios[i].currentTime = 0;
        }
        audio.current.play();


    }

    const audioPlay = () => {
        audio.current.play();
    }

    return(<div className={"chara-item item" + props.index}   ><img style={style} src={baseurl + props.imgUrl} onClick={itemClick} ref={item} />
            <audio src={`${baseurl}assets/audio/meet/item${props.index}.mp3`} ref={audio} />

    </div>)
}
