import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import GreenCircle from './../svgs/greenCircle_v4'
import ReactHlsPlayer from 'react-hls-player';

const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);
    const [icon,setIcon] = useState("");
    const [videoLink,setVideoLink] = useState("");
    
    
    const [items,setItems] = useState([
        {
            id:"1",
            word:"bob",
            img:baseurl +"assets/game2/item1.png",
            words:[
                {
                    text:""
                },
                {
                    text:"ob"
                }
            ]
        },
        {
            id:"2",
            word:"Muzzy",
            img:baseurl +"assets/game2/item2.png",
            words:[
                {
                    text:""
                },
                {
                    text:"uzzy"
                }
            ]
        },
        {
            id:"3",
            word:"Corvax",
            img:baseurl +"assets/game2/item3.png",
            words:[
                {
                    text:""
                },
                {
                    text:"orvax"
                }
            ]
        },
        {
            id:"4",
            word:"Queen",
            img:baseurl +"assets/game2/item4.png",
            words:[
                {
                    text:""
                },
                {
                    text:"ueen"
                }
            ]
        },
        {
            id:"5",
            word:"Sylvia",
            img:baseurl +"assets/game2/item5.png",
            words:[
                {
                    text:""
                },
                {
                    text:"ylvia"
                }
            ]
        }
        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,j,i,m) => {
        let randList2 = [...randList];
        randList2[j].words[i].para[m].userAnswer = value;
        setRandList(randList2);
    }

    const checkQuestion = () => {
        let randList2 = [...randList];
        let allcorrect = true;

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"look_write"
        }


        // console.log(randList)

        randList2.forEach((randItem,i) => {
            randItem.words.forEach((wordItem,j) => {
                wordItem.para.forEach((subItem,m) => {
                    if(subItem.type === "input") {

                        subItem.userAnswer = subItem.userAnswer.replaceAll("’","'").trim();
                        subItem.text = subItem.text.replaceAll("’","'").trim();
                        console.log("selected",subItem.userAnswer.toLowerCase(),subItem.text.toLowerCase());
                        if(subItem.userAnswer.toLowerCase() !== subItem.text.toLowerCase()) {
                            allcorrect = false;
                            randList2[i].correct = false;
                            answerFormat.pass = false;
                            
                        } else {
                            randList2[i].correct = true;
                        }

                        answerFormat.userAnswers.push({
                            userAnswer:subItem.userAnswer.toLowerCase(),
                            correctAnswer:subItem.text.toLowerCase(),
                            correct:randList2[i].correct
                        });
                    }
                });
            })
        }); 

        setRandList(randList2);



        if(allcorrect === true) {
            document.getElementById("correct-au").play();
        } else {
            document.getElementById("wrong-au").play();
        }
        // setRandList(items2);
        setCheckAnswers(true);

        setTimeout(() => {
            // history.push(baseurl + "look_notice")
           props.nextExercise(answerFormat);
        }, 3000);
        
    }


    
    const getSentence = (text) => {
       return text.split(' ').map((w, id) => {
            let end = w.search(">");
            let start = w.search(">");

            return {word:w.split("<").pop().split(">"),start:start,end:end}

        //   if (w.startsWith('<')) {
        //     const m = w.match(/[a-z-A-Z]+/);
        //     return { id, type: 'answer', placed: false, displayed: ''};
        //   }
        //   return { id, text: w, type: 'word' };
       });
      };


    useEffect(() => {
        let currentItems = props.questions.answers;


        setIcon(props.questions.icon)
    

        setTimeout(() => {
            document.getElementById("intr").play();
        },300)
        setItems(currentItems)
        setRandList(currentItems);

        if(props.questions.videoCode !== undefined) {

            fetch("https://api.vimeo.com/videos/" + props.questions.videoCode,{
                method: "GET",
                headers: {
                    "Authorization": "Bearer aeeec5987db81bbfd76e0f272e35468c", 
                }
            }).then(response => response.json()).then(data => {
                // console.log(data);
                // setVideoData(data.embed.html)
                // console.log("video212",{
                //     autoplay: true,
                //     controls: true,
                //     responsive: true,
                //     fluid: true,
                //     sources: [{
                //       src: data.files[0].link,
                //       type: 'video/mp4'
                //     }]
                // })

                
                if(data.files !== undefined) {
                    let files = data.files;
                    let findHls = files.findIndex((item) => item.quality === "hls");

                    if(findHls !== -1) {
                        setVideoLink(data.files[findHls].link_secure);
                    }
                }
            
            });

        }
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }


    return(<div id="look_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                {/* <img src={baseurl + "assets/game5/write.svg"} /> */}

                {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}


                    <h1 className="question-title" dangerouslySetInnerHTML={{__html:props.questions.title}}></h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>
{/* 
                {props.questions.video1 !== undefined && <div style={{width:"100%",borderRadius:20}}>

                    <div class="video-intro" style={{height: "50vh", marginBottom: 50,width:"100%"}}>
                        <img src={baseurl +"assets/game1/play.png"} id="video-waf" onClick={videoPlay} />
                            <video id="video-lin" src={baseurl + props.questions.video1} controls></video>
                    </div>
                </div>} */}

                {videoLink !== "" &&
                <div style={{width:"100%",borderRadius:20}}>
         <div className="video-intro" style={{height: "50vh", marginBottom: 50,width:"100%"}}>
          <ReactHlsPlayer
          src={videoLink}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        /></div>
        </div>
        
        }

                {props.questions.topImage !== undefined && <div style={{background:"#fff",width:"100%",marginBottom:100,paddingBottom:20}}>
                    <img src={baseurl + props.questions.topImage} />
                    </div>}

                {props.questions.mainTxt !== undefined && <div><GreenCircle
                title={
                    <>

{props.questions.mainTxt !== undefined && <>
        {props.questions.mainTxt.map((item) => {
          return(
            <tspan x={item.x} y={item.y} fontSize={item.fontSize}>
            {item.text} 
            </tspan>
          

          )
        })}  
        </>} 
                    
                  </>}
                /></div>}

        <div id="look_write-items-v1">
            {randList.map((item,j) => {

                var lines = [];
                item.words.forEach((word) => {
                    let split_word = word.line.split("<")
                    // let split_word2 = split_word.join("").split(">")
                    lines.push(split_word);
                })
                return(
                <div className="look_write-item-v1" style={{marginTop:0,marginBottom:30,alignItems:"center"}}>
                    {item.img2 !== undefined && <div style={{marginRight:10}}><img src={baseurl + item.img2} /></div>}
                    {item.img !== "none"?checkAnswers === false ?<img src={baseurl + "assets/chara/" +item.img + ".png"} />:item.correct === false ?<img style={{border:"5px solid red"}} src={baseurl + "assets/chara/" +item.img + ".png"} />:<img style={{border:"5px solid #7EBE40"}} src={baseurl + "assets/chara/" +item.img + ".png"} />:""}
                    {/* {item.correct === undefined ?<img style={{border:"8px solid #F84E53"}} src={item.img} />:
                    item.correct === true?<img style={{border:"8px solid #83cd83"}} src={item.img} />:<img src={item.img} />
                    } */}
                    <div className="word-v1" style={{display:"inline-block",
                    position:"relative",
                    display: "inline-block",
    background: "white",
    border: "3px solid #22cbf2",
    margin: "0px 20px 20px",
    boxShadow:"rgb(34 203 242) -4px 4px 0px",
    padding:"10px 20px 20px"
                }}
                    
                    >

{j % 2 === 0?
    <img src={ baseurl + "assets/game8/arrow.png"} style={{width: "auto",height: "auto", border: "0px",position: "absolute", left: "-19px", top: "17%",borderRadius: "0px"}} />
:
<img src={ baseurl + "assets/game8/arrow.png"} style={{width: "auto",height: "auto", border: "0px",transform:"rotate(180deg)",position: "absolute", right: "-19px", top: "17%",borderRadius: "0px"}} />
}

                        {item.words.map((para,i) => {
                            return(<div className="editable-input">{para.para.map((word,m) => {

                                let margin = {margin:0};


                                if(props.questions.textSpacing === true) {
                                    margin = {margin:"0px 5px"};
                                }

                                if(word.type === "text") {
                                    return(<span style={{fontSize:20}}>{word.text} </span>);
                                } else {
                                    return(<span className={"editable-input"} ><input spellCheck="false"  onChange={(e) => { changeTxt(e.target.value,j,i,m) }} maxLength={word.text.length} size={word.text.length} style={{width:"auto",borderBottom:"2px dashed #B7BCD1",fontSize:20,...margin}} type="text" /></span>);
                                }
                                
                            })}</div>)
                        })}

{/* <audio src={`${baseurl}assets/muzzy/audio/look_write.mp3`} className="hidden-audio" id="intr" /> */}
{/* <audio src={`${baseurl}assets/levels/level1/unit1/assets/audios/look_write.mp3`} className="hidden-audio" id="intr" /> */}
<audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                    </div>
                </div>)
            })}
        </div>

             

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>

                </div>


    </div>)
}
export default DragAndDrop;