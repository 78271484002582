import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import TickIcon from './../components/svgs/tick'
import CrossIcon from './../components/svgs/cross'
import ReactHlsPlayer from 'react-hls-player';

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    
    const history = useHistory();
    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState([
      
    ]);
    const [items,setItems] = useState([
       
        ]);

        const [randList,setRandList] = useState([]);
        const [randListV1,setRandListV1] = useState([]);
        const [videoLink, setVideoLink] = useState(""); 

        const checkQuestion = () => {

            
            let items2 = [...randListV1];
            let allcorrect = true;

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:props.questions.title,
                userAnswers:[],
                type:"complete"
            }

            
            items2.forEach((item,m) => {

                item.questions.forEach((questionItem,i) => {
                    questionItem.spans.map((spanItem,j) => {
                        // console.log("item.questions",spanItem)

                        if(spanItem.type === "input") {
                            if(spanItem.fullAnswers !== undefined) {
                                items2[m].questions[i].correct = true;

                                answerFormat.userAnswers.push({
                                    userAnswer:spanItem.userAnswer.toLowerCase(),
                                    correctAnswer:"",
                                    correct:items2[m].questions[i].correct
                                  })
                            }
                            else if(spanItem.answers !== undefined) {
                                items2[m].questions[i].correct = true;

                                answerFormat.userAnswers.push({
                                    userAnswer:spanItem.userAnswer.toLowerCase(),
                                    correctAnswer:"",
                                    correct:items2[m].questions[i].correct
                                  })
                            }
                            else if(spanItem.open === true && spanItem.userAnswer !== "") {
                                items2[m].questions[i].correct = true;

                                answerFormat.userAnswers.push({
                                    userAnswer:spanItem.userAnswer.toLowerCase(),
                                    correctAnswer:"",
                                    correct:items2[m].questions[i].correct
                                  })

                            }
                            else if(spanItem.userAnswer.toLowerCase() !== spanItem.text.toLowerCase()) {
                                // console.log(allcorrect,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())

                                allcorrect = false;
                                items2[m].questions[i].correct = false;

                                answerFormat.userAnswers.push({
                                    userAnswer:spanItem.userAnswer.toLowerCase(),
                                    correctAnswer:spanItem.text.toLowerCase(),
                                    correct:items2[m].questions[i].correct
                                  })
                            } else {
                                items2[m].questions[i].correct = true;

                                answerFormat.userAnswers.push({
                                    userAnswer:spanItem.userAnswer.toLowerCase(),
                                    correctAnswer:spanItem.text.toLowerCase(),
                                    correct:items2[m].questions[i].correct
                                  })
                            }
                            
                           
                        }

                    });
                });

                item.answers.forEach((questionItem,i) => {
                    questionItem.spans.map((spanItem,j) => {    
                        // console.log("item.answers",spanItem)

                        if(spanItem.answers !== undefined) {

                            let checksub = false
                            spanItem.answers.forEach((subItem2) => {
                                if(spanItem.userAnswer.toLowerCase() === subItem2.toLowerCase()) {
                                    checksub = true;
                                }
                            });

                            if(checksub === true) {
                                items2[m].questions[i].correct = true;

                                answerFormat.userAnswers.push({
                                    userAnswer:spanItem.userAnswer.toLowerCase(),
                                    correctAnswer:"",
                                    correct:items2[m].questions[i].correct
                                  })
                            } else {
                                allcorrect = false;
                                items2[m].questions[i].correct = false;

                                answerFormat.userAnswers.push({
                                    userAnswer:spanItem.userAnswer.toLowerCase(),
                                    correctAnswer:"",
                                    correct:items2[m].questions[i].correct
                                  })
                            }


                            answerFormat.userAnswers.push({
                                userAnswer:spanItem.userAnswer.toLowerCase(),
                                correctAnswer:spanItem.answers[0],
                                correct:items2[m].questions[i].correct
                              })




                        }
                        else if(spanItem.type === "input") {
                            

                            if(spanItem.userAnswers !== undefined) {
                                console.log(spanItem.userAnswers.indexOf(spanItem.userAnswer),allcorrect);
                                if(spanItem.userAnswers.indexOf(spanItem.userAnswer) !== -1) {
                                    items2[m].questions[i].correct = true;
                                } else {
                                    allcorrect = false;
                                    items2[m].questions[i].correct = false;
                                }
                            }
                            else if(spanItem.open === true && spanItem.userAnswer !== "") {
                                items2[m].questions[i].correct = true;
                            }
                            else if(spanItem.userAnswer.toLowerCase() !== spanItem.text.toLowerCase()) {
                                // console.log(allcorrect,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())

                                allcorrect = false;
                                items2[m].questions[i].correct = false;
                            } else {
                                items2[m].questions[i].correct = true;
                            }
                        }
                    });
                });

            });




            if(allcorrect === true) {
                document.getElementById("correct-au").play();

            } else {
                document.getElementById("wrong-au").play();
                answerFormat.pass = false;

            }
            setRandListV1(items2);
            setTimeout(() => {
              props.nextExercise(answerFormat);
                // history.push(baseurl + "look_listeuin_say")
            }, 3000);
            
        }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i,j) => {
          let userSelect2 = [...userSelect];

          userSelect2[i].words[j] = value;
          setUserSelect(userSelect2);

        //   userSelect2[i] = value;
        //   setUserSelect(userSelect2);

      }

    useEffect(() => {

        if(props.questions.videoCode !== undefined) {

            fetch("https://api.vimeo.com/videos/" + props.questions.videoCode,{
                method: "GET",
                headers: {
                    "Authorization": "Bearer aeeec5987db81bbfd76e0f272e35468c", 
                }
            }).then(response => response.json()).then(data => {
                // console.log(data);
                // setVideoData(data.embed.html)
                // console.log("video212",{
                //     autoplay: true,
                //     controls: true,
                //     responsive: true,
                //     fluid: true,
                //     sources: [{
                //       src: data.files[0].link,
                //       type: 'video/mp4'
                //     }]
                // })

                
                if(data.files !== undefined) {
                    let files = data.files;
                    let findHls = files.findIndex((item) => item.quality === "hls");

                    if(findHls !== -1) {
                        setVideoLink(data.files[findHls].link_secure);
                    }
                }
            
            });

        }

        setTimeout(() => {
            document.getElementById("intr").play();
        },300)
        let currentItems = [...items];

        // currentItems = shuffle(currentItems);

        setRandList(currentItems);
        document.getElementById("intr").play();

        setRandListV1(props.questions.answers)

    }, [props])

    
    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const checkQuestionAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].questions[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);
    }

    const checkAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].answers[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);

    }
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    const keyUpFunc = (e) => {
        if(props.questions.focus === true) {
            let count = e.target.id;

            count = count.replace("tx-","");
            count = parseInt(count);
            count++;

            console.log(e.target.value.length,e.target.size)

            if(e.target.value.length === e.target.size) {
                if(document.getElementById("tx-" + count) !== null) {
                    document.getElementById("tx-" + count).focus();
                }
            }
    
            
        }
    }

    const audioStyle =  {

        background:"#0B329D",width:30,height:30,display:"inline-block",alignItems:"center",justifyContent:"center",borderRadius:"100%",padding:10,marginLeft:10,cursor:"pointer"
    }

    return(<div id="complete_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title" dangerouslySetInnerHTML={{ __html: props.questions.title }}></h1>
                </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>

                </div>  
                {/* {props.questions.video !== "" && <div style={{maxWidth:"60vw",minWidth:"60vw",marginBottom:50}}>
                
                <div class="video-intro" style={{height: "50vh",width:"100%"}}><img onClick={videoPlay} src={baseurl + "assets/game1/play.png"} id="video-waf" /><video style={{width:"100%"}} id="video-lin" src={baseurl + props.questions.video} controls></video></div>
                </div>} */}

                
        {videoLink !== ""?
         <div className="video-intro" style={{height:"50vh",width:"100%",marginBottom:50}}>
          <ReactHlsPlayer
          src={videoLink}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
         /></div>:<>
         {props.questions.video !== undefined && props.questions.video !== "" &&<div className="video-intro" style={{height:"50vh",width:"100%",marginBottom:50}}>
                        <img src={baseurl + "assets/game1/play.png"} onClick={videoPlay} id="video-waf" />
                        <video id="video-lin" src={baseurl + props.questions.video} controls />

                    </div>}
         </>
       
        }
                {props.questions.image !== "" && <div style={{maxWidth:"60vw",display:"flex",alignItems:"center",justifyContent:"center",gap:20,marginBottom:30}}><img src={baseurl + props.questions.image} /></div>}
                {props.questions.images !== undefined && 
                    <div style={{maxWidth:"60vw",display:"flex",alignItems:"center",justifyContent:"center",gap:20,marginBottom:30,padding:"20px 0px",background:"#fff"}}>
                        {props.questions.images.map((item) => <div>
                            <img src={baseurl + item.url} />
                        </div> )}
                </div>}
                {props.questions.pinkTitle !== undefined && <div style={{background:"#9176F9",color:"#fff",display:"inline-block",padding:"10px 20px",fontFamily:"fredokaone",fontSize:30,borderRadius:20,marginBottom:20}}>{props.questions.pinkTitle}</div>}

        <div id="complete-items-v1" style={{minWidth:"60vw"}}>
            


            {randListV1.map((item,m) => {
                let count = 0;
                // console.log("item_v1",item);
                return(<div>
                    {item.questions.map((questionItem,i) => {
                        return(<div className="items-v1" style={{marginBottom:20,display:"flex",flexDirection:"column",position:"relative"}}>

                            <div style={{display:"flex",alignItems:"center"}}>
                            <div>{questionItem.spans.map((spanItem,j) => {

if(spanItem.type === "input") {
    count++;
    return(<span><input id={"tx-" + count} spellCheck="false" onKeyUp={keyUpFunc}  type='text' value={spanItem.userAnswer} style={{width:"auto",textAlign:"left"}} onChange={(e) => { checkQuestionAnswer(e.target.value,m,i,j) }} size={spanItem.text.length} maxLength={spanItem.text.length} /></span>)
} else {
    return(<span dangerouslySetInnerHTML={{__html:spanItem.text}}></span>)
}

})}</div>
{questionItem.audio !== undefined && 
<>
<audio src={baseurl + questionItem.audio} id={"audioitem-" + i} />

<div style={audioStyle} onClick={(e) => document.getElementById("audioitem-" + i).play()}>
<img src={baseurl + "assets/audio/listen.png"} />
</div>
</>

}

                            </div>
                           
                            

                            <div style={{margin:"20px 0px 0px"}}>{item.answers[i].spans.map((spanItem,j) => {

                                if(spanItem.type === "input") {
                                    return(<span><input spellCheck="false"  type='text' style={{width:"auto",textAlign:"left"}} onChange={(e) => { checkAnswer(e.target.value,m,i,j) }} size={spanItem.text.length} maxLength={spanItem.text.length} /></span>)
                                } else {
                                    return(<span dangerouslySetInnerHTML={{__html:spanItem.text}}></span>)
                                }

                            })}</div>
                            {questionItem.correct !== undefined && 
                            <>
                            
                            {questionItem.correct === true &&  <div style={{position:"absolute",right:25,top:25}}><TickIcon /></div>}
                            {questionItem.correct === false &&  <div style={{position:"absolute",right:25,top:25}}><CrossIcon /></div>}


                            </>
                            }


                            
                            </div>)
                    })}
                </div>)
            })}

            {/* {randList.map((item,i) => {
                return(<div className="items-v1">{item.words.map((subItem,j) => {
                    if(subItem === "") {
                        let char_len= item.word.split(" ");
                        
                        return <span><input spellCheck="false"  type="text" onChange={(e) => {changeTxt(e.target.value,i,j)}} maxLength={char_len[j] !== undefined?(char_len[j].length>2?1:2):1} /></span>

                    } else {
                        return <span>{subItem + " "}</span>
                    }
                })}</div>);
            })} */}
        </div>

            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

            <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>
            </div>


    </div>)
}
export default DragAndDrop;