import React,{useState,useEffect} from 'react'
import ListenAndChooseTheWord from './../engines/LookNotice';
import VerticalMenu from './../components/VerticalMenu';
import _ from 'lodash';
import Headertop from '../components/headerTop';

const baseurl = process.env.REACT_APP_FRONT_LINK;

const quizsection = {
    subtitle:"Look and notice."
}

function Game(props) {
    const [levels,setLevels] = useState([1,2,3,4,5,6])
    const [correct,setCorrect] = useState(0);
    const [questions,setQuestions] = useState([
        {
            correct:0,
            words:["I","","Corvex."],
            audio:"assets/tracks/t08/iam_corvex.mp3",
            options:["am","'m"]
        },
        {
            correct:0,
            words:["I","","the Queen."],
            options:["am","'m"],
            audio:"assets/tracks/t08/i_am_the_queen.mp3",
        },
        {
            correct:1,
            words:["I","","Bob."],
            options:["am","'m"],
            audio:"assets/tracks/t08/im_bob.mp3"
        },
        {
            correct:1,
            words:["I","","the King."],
            options:["am","'m"],
            audio:"assets/tracks/t08/im_the_king.mp3"
        }
    ]);


    useEffect(() => {
        document.getElementsByTagName("body")[0].style.background = "#EAF6FC";
        
    }, [])
    return(<div id="mainapp" className="gameapp" style={{color:"#fff"}} >
            <div id="game-container">
                 
                <VerticalMenu active={"Exercise"} />
                <div id="mainquestion">
                    {/* <img src={baseurl + "assets/header/icons/level_menu.png"} className="mainquestion-levels" /> */}
                    {/* <img src={baseurl + "assets/progress/2.png"} className="mainquestion-levels" /> */}
                    <Headertop progress={100} />
                    <ListenAndChooseTheWord nextExercise={props.nextExercise} quizsection={quizsection} questions={questions} correct={correct} />
                    {/* <img src={baseurl + "assets/header/icons/maingroup.png"} /> */}
                </div>
            </div>
            

        
    </div>)
}
export default Game;