import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import BlueArrow from './../svgs/blue'
import BlueBg from './../svgs/blueBg'

const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);

    const [items,setItems] = useState([
        {
            id:"1",
            word:"I&nbsp;<b>am</b>&nbsp;Muzzy.",
            word2:"I<b>'m</b>&nbsp;Muzzy.",
            fileLink1:baseurl + "assets/tracks/t07/iam_muzzy.mp3",
            fileLink2:baseurl + "assets/tracks/t07/im_muzzy.mp3"
        },
        {
            id:"2",
            word:"I&nbsp;<b>am</b>&nbsp;Sylvia.",
            word2:"I'<b>m</b>&nbsp;Sylvia.",
            fileLink1:baseurl + "assets/tracks/t07/iam_selvia.mp3",
            fileLink2:baseurl + "assets/tracks/t07/im_selvia.mp3"
        }

        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i) => {
        let userSelect2 = [...userSelect];
        userSelect2[i] = value;
        setUserSelect(userSelect2);

    }

    const checkQuestion = () => {

//        setTimeout(() => {
            // history.push(baseurl + "circle_hear")
        // }, 3000);

        window.location.hash = "#16";
        
    }


    useEffect(() => {
        let currentItems = [...items];

        // currentItems = shuffle(currentItems);

        setTimeout(() => {
            // document.getElementById("intr").play();
        },300)
        document.getElementById("intr").play();
        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const stopAllAudios = (i) => {
        let audios = document.querySelectorAll("audio");
        audios.forEach((audio) => {
            audio.pause();
            audio.currentTime = 0;
        })
    }

    
    const playAudio = (i) => {
        stopAllAudios();

        
        document.getElementById("filelink1_" + i).play();
        document.getElementById("filelink1_" + i).play();
    }

    const playAudio2 = (i) => {
        stopAllAudios();

        document.getElementById("filelink2_" + i).play();
        document.getElementById("filelink2_" + i).play();

    }

    return(<div style={{width:"50%"}}>
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title">Look, listen, and say.</h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>
                {/* <GreenCircle style={{marginLeft:-30,marginTop:-30}} /> */}

        <div id="look_and_say" className="look_listen_say">
            {randList.map((item,j) => {
                return(
                <div className="item-v1">
                    <div className="look_words" style={{position:"static"}}>
                        <div className="word1" style={{position:"relative"}} onClick={() => playAudio(j)}>
                            <div><BlueBg style={{width:"100%",height:"auto"}} /></div>
                            {/* <div style={{position:"absolute",left:0,top:0,width:"100%",textAlign:"center"}}>{item.word}</div> */}

                            <div style={{position:"absolute",left:0,top:0,width:"100%",textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}} dangerouslySetInnerHTML={{__html:item.word}}></div>

                        </div>
                        <div className="word2" style={{position:"relative"}} onClick={() => playAudio2(j)}>
                            <div><BlueBg style={{width:"100%",height:"auto"}} /></div>
                            <div style={{position:"absolute",left:0,top:0,width:"100%",textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}} dangerouslySetInnerHTML={{__html:item.word2}}></div>
                        </div>
                        <audio id={"filelink1_" + j} src={item.fileLink1}  />
                        <audio id={"filelink2_" + j} src={item.fileLink2}  />


                    </div>
                   
                </div>)
            })}

<audio src={`${baseurl}assets/tracks/t07/track7.mp3`} className="hidden-audio" id="intr" />
<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

        </div>

             

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>

                </div>


    </div>)
}
export default DragAndDrop;