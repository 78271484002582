const initialState = {
    user:{
        id:"",
        type:"guest",
        name:"",
        avatar:""
    }
}
export default function appReducer(state = initialState, action) {

    if(action.type === "update_avatar") {
        console.log("action.avatar",action.avatar)
        state.user.avatar = action.avatar; 
    }

    if(action.type === "update_user") {
        state.user = action.user;
    }

    return {
        ...initialState,
        ...state
    }
}