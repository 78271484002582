function MuzzyTitle(props) {

    const titleStyle = {
        background: "#7EBE40",
        color: "#fff",
        padding: "50px 30px",
        borderRadius: "50%",
        fontFamily: 'gotham-medium',
        maxWidth: 200,
        width: "100%",
        lineHeight: "25px",
        boxShadow: "0px 0px 10px #aaa",
        color:"#fff",
        fontSize:20,
        position:"relative"
    }
    return(
<div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>

<div style={{display:"flex",alignItems:"center"}}>
            <div><img src={process.env.REACT_APP_FRONT_LINK + "assets/levels/level2/unit1/6complete/v3/muzzytitle.png"} /></div>
                    <div style={titleStyle}>
                        <i class="arrow-before"></i>    
                        <div>{props.title}</div>
                    </div>

</div>
<div><img style={{width:400,height:"auto"}} src={process.env.REACT_APP_FRONT_LINK + props.imageRight} /></div>
                </div>

    )
}

export default MuzzyTitle;