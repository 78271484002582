import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    
    const history = useHistory();
    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);

    const [items,setItems] = useState([
        {
            id:"1",
            word:"I’ m Bob.",
            img:baseurl +"assets/game2/item1.png",
            words:[
                {
                    text:"I’"
                },
                {
                    text:""
                },
                {
                    text:"Bob."
                }
            ]
        },
        {
            id:"2",
            word:"I am Corvax.",
            img:baseurl +"assets/game2/item3.png",
            words:[
                {
                    text:"I"
                },
                {
                    text:""
                },
                {
                    text:"Corvax."
                }
            ]
        },
        {
            id:"3",
            word:"I am Sylvia.",
            img:baseurl +"assets/game2/item5.png",
            words:[
                {
                    text:"I"
                },
                {
                    text:""
                },
                
                {
                    text:"Sylvia."
                }
            ]
        },
        {
            id:"4",
            word:"I’ m Muzzy.",
            img:baseurl +"assets/game2/item2.png",
            words:[
                {
                    text:"I’"
                },
                {
                    text:""
                },
                {
                    text:"Muzzy."
                }
            ]
        },

        {
            id:"5",
            word:"I am the Queen.",
            img:baseurl +"assets/game2/item4.png",
            words:[
                {
                    text:"I"
                },
                {
                    text:""
                },
                {
                    text:"the"
                },
                {
                    text:"Queen."
                }
            ]
        }
        ]);

        const [randList,setRandList] = useState([]);
    
        const checkQuestion = () => {

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:"Complete. Write m or am.",
                userAnswers:[],
                type:"watch-video-numbe-the-pictures"
            }

            let items2 = [...randList];
            let allcorrect = true;
            items2.map((subItem,i) => {
                let currentword = "";

                subItem.words.forEach((subItem2) => {

                    if(subItem2.text === "") {
                        currentword += userSelect[i] + " ";
                    } else {
                        currentword += subItem2.text + " ";
                    }
                })
                currentword = currentword.trim();


                if(currentword === subItem.word) {
                    console.log("correct true");
                    subItem.correct = true;
                    answerFormat.userAnswers.push({
                        userAnswer:currentword,
                        correctAnswer:subItem.word,
                        correct:true
                    })
                } else {
                    // console.log("correct false");

                    subItem.correct = false;
                    allcorrect = false;

                    answerFormat.userAnswers.push({
                        userAnswer:currentword,
                        correctAnswer:subItem.word,
                        correct:false
                    })
                }

                return subItem;
            })
            if(allcorrect === true) {
                document.getElementById("correct-au").play();

            } else {
                document.getElementById("wrong-au").play();

            }
            answerFormat.pass = allcorrect;
            setRandList(items2);
            setTimeout(() => {
                //history.push(baseurl + "look_read_write")
                props.nextExercise(answerFormat);
                window.location.hash = "#11"

            }, 3000);
            
        }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i) => {
          let userSelect2 = [...userSelect];
          userSelect2[i] = value;
          setUserSelect(userSelect2);

      }

    useEffect(() => {

        setTimeout(() => {
            document.getElementById("intr").play();
        },300)
        let currentItems = [...items];

        // currentItems = shuffle(currentItems);

        setRandList(currentItems);
        
    }, [])

    
    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    return(<div id="complete_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title">Complete. Write <span>m</span> or <span>am</span>.</h1>
                </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>

                </div>
        <div id="drag-items-v1">
            {randList.map((item,i) => {
                return(
                <div className="drag-item-v1">
                    {item.correct === undefined ?<img src={item.img} />:
                    item.correct === true?<img style={{border:"8px solid #83cd83"}} src={item.img} />:<img style={{border:"8px solid #F84E53"}} src={item.img} />
                                        

                    }

                    <div className="word-v1">
                        <div className="word-arrow"><img src={baseurl +"assets/game2/arrow.png"} /></div>
                        {item.words.map((subWord) => {

                            if(subWord.text === "") {
                                return(<div className={"editable-input"} ><input spellCheck="false"  maxLength={2} type="text" onChange={(e) => {changeTxt(e.target.value,i)}} /></div>)
                            } else {
                                return(<div onChange={changeTxt} className={subWord.text === ""?"editable":"readable"} contentEditable={subWord.text === ""?true:false}>{subWord.text === ""?userSelect[i]:subWord.text}</div>)
                            }

                        })}
                    </div>
                </div>)
            })}
        </div>

            {/* <audio src={`${baseurl}assets/muzzy/audio/complete_write_m_or_am.mp3`} className="hidden-audio" id="intr" /> */}
            {/* <audio src={`${baseurl}assets/muzzy/audio/complete_write_m_or_am.mp3`} className="hidden-audio" id="intr" />  */}

            <audio src={baseurl + "assets/levels/level1/unit1/assets/audios/complete_write_m_am.mp3"} className="hidden-audio" id="intr" />

            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

            <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>
            </div>


    </div>)
}
export default DragAndDrop;