import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Animate } from 'react-simple-animate';

const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]

function ListenAndChooseTheWord(props) {
    const [data,setData] = useState([
       
    ]);
    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState("");
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [answer,setAnswer] = useState(0);
    const history = useHistory();

    const [answerFormat,setAnswerFormat] = useState({
        pass:true,
        progress:0,
        questionTitle:props.questions.title,
        userAnswers:[],
        type:"circle_hear"
    });

    const instr = useRef();

    useEffect(() => {
        document.getElementById("intr2").play();

    },[])
    useEffect(() => {
            let selected = props.questions.answers;
            setCurrent(0);
            // selected = _.shuffle(selected);

            if(selected.length > 0) {
                setQuestion(selected[0])
                setAudio(selected[0].audio);

                let timeout = 4000;

                if(props.questions.timeout !== undefined) {
                    timeout = props.questions.timeout;
                }

                setTimeout(() => {
                    document.getElementById("intr").play();

                }, timeout);


            }
            setData(selected);

                

    }, [props.questions])

    const checkQuestion = (e) => {
        
        let counterNext = current;

        let answerFormat2 = {...answerFormat};
       // let currentData = data;

        counterNext++;
        let allcorrect = true;
    
        if(question.allWords !== undefined) {
            let question2 = {...question};        
            question2.allWords.map((wordItem) => {
                wordItem.line.forEach((lineItem,i) => {
                    if(lineItem.type === "options") {
                        if(lineItem.correct !== lineItem.userAnswer) {
                            allcorrect = false;
                            wordItem.line[i].checkCorrect = true;
                            answerFormat2.pass = false;
                        } else {
                            wordItem.line[i].checkCorrect = true;
                        }
                    }
                });
                return wordItem;
            });

            setQuestion(question2);

            if(allcorrect === true) {
                document.getElementById("correct-au").play();

            } else {
                answerFormat2.pass = false;
                document.getElementById("wrong-au").play();

            }
        }
        else if(question.userAnswer === question.correct) {
           // props.playAudio(true);
            // setShowCorrect(true);
            document.getElementById("correct-au").play();
            setQuestion({
                ...question,
                correctAnswer:true
            })
        } else {
            
          //  props.playAudio(false);
            // setShowCorrect(true);

            answerFormat2.pass = false;
            document.getElementById("wrong-au").play();
            setQuestion({
                ...question,
                correctAnswer:false
            })
        }

        setAnswer(answerFormat2);

        setTimeout(() => {

            if(counterNext < data.length) {
                let selected =  data[counterNext];
                selected.answers = _.shuffle(selected.answers);
                let find = selected.answers.findIndex((item) => item.correct === true);
    
                setQuestion(selected);
                setAudio(selected.audio);
                setShowCorrect(false);
                setUserSelect("");
                setCurrent(counterNext);  
                document.getElementById("intr").play();

                setTimeout(() => {
                    // document.getElementById("intr2").play();

                    
                },300)

            } else {
                props.nextExercise(answerFormat2);
               // history.push(baseurl + "read_names")
            }
        }, 3000);


        /*setTimeout(() => {
            history.push(baseurl + "drag")
        }, 3000);*/


        // setTimeout(() => {
        //     setShowCorrect(false);

        //     if(data[counterNext] !== undefined) {
        //         currentData[counterNext].complete = true;
        //         data[counterNext].answers = _.shuffle(data[counterNext].answers);

                
    
        //         setData(currentData);
        //         setCurrent(counterNext);
        //         setQuestion(data[counterNext])
        //         setUserSelect("");
        //     } else {
        //         props.setShowGoodJob(true);
        //     }
        //     setTimeout(() => {
        //         if(document.getElementById("question-audio") !== null) {
        //             document.getElementById("question-audio").play();
        //         }
        //     }, 500);
        // }, 3000);

    }

    const playAudio = (e) => {
        if(document.getElementById("question-audio") !== null) {
            document.getElementById("question-audio").play();
        }
    }

    const checkUserSelect = (userSelect,indx) => {
        if(userSelect === indx) {
            return ("selected multiple-question-item");
        } else {
            return ("multiple-question-item");
        }
    }

    const checkCorrect = (userSelect,indx,correct) => {

        if(correct === true) {
            return("multiple-question-item selected correct")
        } else {
            if(userSelect === indx) {
                return ("selected wronganswer multiple-question-item");
            } else {
                return ("multiple-question-item");
            }
        }
        
    }

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const selectItem = (Indx,lineIndx,itemIndx) => {
        let question2 = {...question};

        // console.log(item,lineIndx,itemIndx)

        question2.allWords[Indx].line[lineIndx].userAnswer = itemIndx;

        console.log(question2.allWords[Indx].line[lineIndx]);

        setQuestion(question2);
    }


    return(<div style={{width:"40vw"}}>
        

 

        <div className="question-title-container">
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>

                {props.questions.icon !== "none" &&  <img src={baseurl + "assets/game5/listen.svg"} />}
                <h1 className="question-title" dangerouslySetInnerHTML={{__html:props.questions.title}}></h1>

            </div>
                    <div style={{display:"flex",alignItems:"center"}}>

                    {props.questions.unitNo !== undefined && <div class="unit-color"><span>{props.questions.unitNo}</span></div>}
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>

                    </div>
                </div>


        <div className="question-container circle-and-read">

        {question.image !== undefined && <div align="center" className='question-item-img'><img style={{borderRadius: "100%",border: "6px solid #22cbf2"}} src={baseurl + question.image} /></div>}

        {question.allWords !== undefined && <>
            {question.allWords.map((lineItem,Indx) => {
                    // console.log("lineItem",lineItem)
                    return(<div className="text-item options-item" style={{margin:"20px 0px"}}>{lineItem.line.map((item,lineIndx) => {

                        if(item.type === "text") {
                            return(<div className='question-item-op'>{item.word}</div>)
                        } else {
                            return(<>{item.words.map((wordItem,itemIndx) => {

                                let itemClass = "";

                                if(item.checkCorrect === true) {
                                    if(item.userAnswer === item.correct) {
                                        itemClass = "green-answer-option";
                                    }
                                    
                                    if(item.userAnswer !== item.correct){
                                        itemClass = "red-answer-option";
                                    }
                                }
                                 else if(item.checkCorrect === false) {
                                    itemClass = "red-answer-option";
                                 }
                                else if(item.userAnswer === itemIndx) {
                                    itemClass = "correct-answer-option";
                                }


                                return(<div onClick={(e) => selectItem(Indx,lineIndx,itemIndx)} className={itemClass}>{wordItem}</div>)
                            })}</>)
                        }
                    })}</div>)
                })}
        </>}
        <audio src={`${baseurl}${audio}`} className="hidden-audio" id="intr" />

        {question.allWords === undefined && <>

            {question.words !== undefined && 
                <div className="circle-and-read-container" style={{height: "30vh",flexDirection:"column"}}>




                

               {question.words.map((item) => {
                   if(item === "") {
                    return(<>
                    
                    {question.question !== undefined && <div style={{width:"100%",color:"#0B329D",fontFamily:"gotham-medium",textAlign:"center",fontSize:30,marginBottom:30}}>{question.question}</div>}

                    <div className="text-item options-item">

                        {question.textBefore !== undefined && <div className='question-item-op'>{question.textBefore}</div>}
                        

                        {question.options.map((subItem,j) => {
                            return(<div style={
                                question.correctAnswer === undefined?
                                
                                question.userAnswer === j ? {
                                    boxShadow: "0px 6px 0px #E4B305",
                                    background: "#F9CB2E"
                                }:{}


                                :
                                question.correctAnswer === true && question.correct === j?{
                                    boxShadow: "0px 6px 0px #608F30",
                                    background: "#7EBE40"
                                }:{
                                    boxShadow: "0px 6px 0px #CA0000",
                                    background: "red"
                                }
                                
                            } onClick={(e) => { setQuestion({...question,userAnswer:j})
                        }}>{
                            question.optionType === 'image'?<div style={{width:200,height:"auto",background:"none",boxShadow:"0px 0px 0px",padding:"0"}}><img src={baseurl + subItem} /></div>:subItem
                            
                        }</div>);
                        })}

{question.textAfter !== undefined && <div className='question-item-op'>{question.textAfter}</div>}

                    </div></>)
                   } else {
                    return(<div className="text-item">{item}</div>)

                   }
                })}

              

                </div> 
            }</>}
            <div className="button-check">
            <button className="check-answer-bt" onClick={checkQuestion} variant="contained" color="primary">Check my answer</button>

            </div>

<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                            {/* <audio src={`${baseurl}assets/tracks/t08/track8v1.mp3`} className="hidden-audio" id="intr2" /> */}
                            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr2" />

        </div>


    </div>)
}

export default ListenAndChooseTheWord;