import React,{useState,useEffect} from 'react'
import ListenAndChooseTheWord from './../engines/ListenAndChooseTheWord';
import VerticalMenu from './../components/VerticalMenu';
import _ from 'lodash';
import HeaderTop from './../components/headerTop';


const baseurl = process.env.REACT_APP_FRONT_LINK;

const quizsection = {
    subtitle:"Listen and point."
}

function Game(props) {
    const [levels,setLevels] = useState([1,2,3,4,5,6])
    const [correct,setCorrect] = useState(0);
    const [questions,setQuestions] = useState([]);


    useEffect(() => {
        document.getElementsByTagName("body")[0].style.background = "#EAF6FC";

        let questionjson = {
            "_id":"question_82",
            "type":"listen_choose",
            "name":"Jordan",
            "answers":[
                {"name":"color","correct":false,"answerId":"1","fileLink":"assets/game6/b1/item2.png","audio":"assets/audio/audio1/color.mp3"},
                {"name":"draw","correct":false,"answerId":"2","fileLink":"assets/game6/b1/item1.png","audio":"assets/audio/audio1/draw.mp3"},
                {"name":"raise your hand","correct":false,"answerId":"3","fileLink":"assets/game6/b1/item3.png","audio":"assets/audio/audio1/raise_your_hand.mp3"},
                {"name":"read","correct":false,"answerId":"4","fileLink":"assets/game6/b1/item5.png","audio":"assets/audio/audio1/read.mp3"},
                {"name":"listen","correct":false,"answerId":"5","fileLink":"assets/game6/b1/item4.png","audio":"assets/audio/audio1/listen.mp3"},
        
                {"name":"write","correct":false,"answerId":"6","fileLink":"assets/game6/b1/item9.png","audio":"assets/audio/audio1/write.mp3"},
                {"name":"sit down","correct":false,"answerId":"7","fileLink":"assets/game6/b1/item7.png","audio":"assets/audio/audio1/sit_down.mp3"},
                {"name":"stand up","correct":false,"answerId":"8","fileLink":"assets/game6/b1/item8.png","audio":"assets/audio/audio1/stand_up.mp3"}
            ],
            "stageId":"stage_40",
            "catId":"category_1",
            "unitId":"unit_20",
            "quizId":"quizsection_2",
            "created":1625392118,
            "createdTime":"2021-07-04T09:48:38.383Z",
            "audio":"attach/1625394010949.mp3",
            "updated":1625400721,
            "updatedTime":"2021-07-04T12:12:01.076Z"
        }
        let finalData = [];
        questionjson.answers.forEach((item,i) => {
            let p2 = JSON.parse(JSON.stringify(questionjson))

            finalData.push(p2);
        })

        finalData.map((item,i) => {
            item.answers[i].correct = true;
            return item;
        })

        setQuestions(finalData)

        
        
        // let currentQuestions = questions;
        // currentQuestions = ._s
        //let correct = Math.floor(Math.random() * (4 - 0) + 0);
        //currentQuestions[0].answers[correct].correct = true;
        // setCorrect(correct);
        // setQuestions(currentQuestions);
        
    }, [])
    return(<div id="mainapp" className="gameapp" style={{color:"#fff"}} >
            <div id="game-container">
                {/* <div id="vertical-menu">
                    <div id="vertical-logo"><img src={baseurl + "assets/header/logo.svg"} /></div>
                    <div id="vertical-vmenu">
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/read.svg"} />
                            <div>Read</div>
                        </div>
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/watch.png"} />
                        <div>Watch</div>

                        </div>
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/exercise.png"} />
                        <div>Exercise</div>

                        </div>
                    </div>
                    <div id="vertical-bottom">
                        <img src={baseurl + "assets/header/icons/alert.png"} />     
                        <img src={baseurl + "assets/header/icons/profile2.png"} />
                    </div>
                </div> */}
                <VerticalMenu active={"Exercise"} />
                <div id="mainquestion">
                    {/* <img src={baseurl + "assets/header/icons/level_menu.png"} className="mainquestion-levels" /> */}
                    <HeaderTop progress={5} />

                    <ListenAndChooseTheWord quizsection={quizsection} questions={questions} correct={correct} nextExercise={props.nextExercise} />
                    {/* <img src={baseurl + "assets/header/icons/maingroup.png"} /> */}
                </div>
            </div>
            

        
    </div>)
}
export default Game;