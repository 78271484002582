import React,{useState,useEffect} from 'react'
import ListenAndChooseTheWord from './../engines/ReadAndMatch';
import VerticalMenu from './../components/VerticalMenu';

import HeaderTop from './../components/headerTop';


const baseurl = process.env.REACT_APP_FRONT_LINK;

const quizsection = {
    subtitle:"Read and match."
}



function Game(props) {
    const [levels,setLevels] = useState([1,2,3,4,5,6])
    const [correct,setCorrect] = useState(0);

    const [questions,setQuestions] = useState([{
        "_id":"question_82",
        "type":"listen_choose",
        "name":"Jordan",
        "answers":
        [{"name":"color","correct":false,"fileId":"file_26","fileLink":"assets/game4/reszied/item1.png","audio":"assets/muzzy/audio/color.mp3"},
        {"name":"draw","correct":false,"fileId":"file_27","fileLink":"assets/game4/reszied/item2.png","audio":"assets/muzzy/audio/draw.mp3"},
        {"name":"raise your hand","correct":false,"fileId":"file_28","fileLink":"assets/game4/reszied/item3.png","audio":"assets/muzzy/audio/raise_your_hand.mp3"},
        {"name":"read","correct":false,"fileId":"file_29","fileLink":"assets/game4/reszied/item5.png","audio":"assets/muzzy/audio/read.mp3"},
        {"name":"listen","correct":false,"fileId":"file_29","fileLink":"assets/game4/reszied/item4.png","audio":"assets/muzzy/audio/listen.mp3"}
    
    ],
        "stageId":"stage_40",
        "catId":"category_1",
        "unitId":"unit_20",
        "quizId":"quizsection_2",
        "created":1625392118,
        "createdTime":"2021-07-04T09:48:38.383Z",
        "audio":"attach/1625394010949.mp3",
        "updated":1625400721,
        "updatedTime":"2021-07-04T12:12:01.076Z"}
    ])

    useEffect(() => {

        document.getElementsByTagName("body")[0].style.background = "#EAF6FC";

        let currentQuestions = questions;
        let correct = Math.floor(Math.random() * (4 - 0) + 0);
        currentQuestions[0].answers[correct].correct = true;
        setCorrect(correct);
        setQuestions(currentQuestions);

    
           
    }, [])
    return(<div id="mainapp" className="gameapp" style={{color:"#fff"}} >
            <div id="game-container">
                {/* <div id="vertical-menu">
                    <div id="vertical-logo"><img src={baseurl + "assets/header/logo.svg"} /></div>
                    <div id="vertical-vmenu">
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/read.svg"} />
                            <div>Read</div>
                        </div>
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/watch.png"} />
                        <div>Watch</div>

                        </div>
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/exercise.png"} />
                        <div>Exercise</div>

                        </div>
                    </div>
                    <div id="vertical-bottom">
                        <img src={baseurl + "assets/header/icons/alert.png"} />     
                        <img src={baseurl + "assets/header/icons/profile2.png"} />
                    </div>
                </div> */}
                <VerticalMenu active={"Exercise"} />
                <div id="mainquestion">
                    {/* <img src={baseurl + "assets/header/icons/level_menu.png"} className="mainquestion-levels" /> */}
                    {/* <img src={baseurl + "assets/progress/7.png"} className="mainquestion-levels" /> */}
                    <HeaderTop progress={20} className="mainquestion-levels" />
                    <ListenAndChooseTheWord quizsection={quizsection} questions={questions} correct={correct} nextExercise={props.nextExercise} />
                    {/* <img src={baseurl + "assets/header/icons/maingroup.png"} /> */}
                </div>
            </div>
            

        
    </div>)
}
export default Game;