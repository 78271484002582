import React,{useState,useEffect} from 'react'
import MeetTheCharacters from './../engines/MeetTheCharacters';
import VerticalMenu from './../components/VerticalMenu';
import HeaderTop from './../components/headerTop';
import _ from 'lodash';

const baseurl = process.env.REACT_APP_FRONT_LINK;

const quizsection = {
    subtitle:"Listen and point"
}

function MeetTheCharaters() {

    useEffect(() => {
        document.getElementsByTagName("body")[0].style.background = "#EAF6FC";
                
    }, [])
    return(<div id="mainapp" className="gameapp" style={{color:"#fff"}} >
            <div id="game-container">

                <VerticalMenu active={"Exercise"} />
                <div id="mainquestion">
                    <HeaderTop progress={0} />

                    <MeetTheCharacters />
                </div>
            </div>
            

        
    </div>)
}
export default MeetTheCharaters;