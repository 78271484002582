import React,{useState} from 'react'
import SortingEngine from './../engines/Sorting.js';
import VerticalMenu from './../components/VerticalMenu';
import { useEffect } from 'react';
import HeaderTop from './../components/headerTop';



const baseurl = process.env.REACT_APP_FRONT_LINK;

const quizsection = {
    subtitle:"Listen and point"
}
const questions = [{
    "_id":"question_82",
    "type":"listen_choose",
    "name":"Jordan",
    "answers":
    [{"name":"color","correct":true,"fileId":"file_26","fileLink":"attach/1625400172579.png"},
    {"name":"draw","correct":false,"fileId":"file_27","fileLink":"attach/1625400187584.png"},
    {"name":"raise your hand","correct":false,"fileId":"file_28","fileLink":"attach/1625400198207.png"},
    {"name":"read","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"},
    {"name":"listen","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"}

]
}]

function Sorting(props) {
    const [levels,setLevels] = useState([1,2,3,4,5,6])
    
    const [videoLink, setVideoLink] = React.useState("");


    useEffect(() => {
    
        document.getElementsByTagName("body")[0].style.background = "#EAF6FC";

        fetch("https://api.vimeo.com/videos/173725167",{
            method: "GET",
            headers: {
                "Authorization": "Bearer aeeec5987db81bbfd76e0f272e35468c", 
            }
        }).then(response => response.json()).then(data => {

            if(data.files !== undefined) {
                let files = data.files;
                let findHls = files.findIndex((item) => item.quality === "hls");
    
                if(findHls !== -1) {
                    setVideoLink(data.files[findHls].link_secure);
                }
            }
        });
       
       }, [])

    return(<div id="mainapp" className="gameapp" style={{color:"#fff"}} >
            <div id="game-container">
                {/* <div id="vertical-menu">
                    <div id="vertical-logo"><img src={baseurl + "assets/header/logo.svg"} /></div>
                    <div id="vertical-vmenu">
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/read.svg"} />
                            <div>Read</div>
                        </div>
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/watch.png"} />
                        <div>Watch</div>

                        </div>
                        <div className="vertical-vmenu-item"><img src={baseurl + "assets/header/icons/exercise.png"} />
                        <div>Exercise</div>

                        </div>
                    </div>
                    <div id="vertical-bottom">
                        <img src={baseurl + "assets/header/icons/alert.png"} />     
                        <img src={baseurl + "assets/header/icons/profile2.png"} />
                    </div>
                </div> */}
                <VerticalMenu active={"Exercise"} />
                <div id="mainquestion">
                    {/* <img src={baseurl + "assets/header/icons/level_menu.png"} className="mainquestion-levels" /> */}

                    {/* <img src={baseurl + "assets/progress/3.png"} className="mainquestion-levels" /> */}

                    <HeaderTop progress={25} className="mainquestion-levels" />
                    <SortingEngine nextExercise={props.nextExercise} />
                    {/* <img src={baseurl + "assets/header/icons/maingroup.png"} /> */}
                </div>
            </div>
            

        
    </div>)
}
export default Sorting;