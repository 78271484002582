import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={836.976}
      height={86.13}
      viewBox={"0 0 836.976 86.13"}
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path
            data-name="Path 2470"
            d="M2076.482 180.5a12.081 12.081 0 006.824 10.884 6.453 6.453 0 013.56 5.836v3.486a3.355 3.355 0 01-3.355 3.355h-829.266a3.355 3.355 0 01-3.355-3.355V171.3a6.469 6.469 0 014.157-6.107 12.094 12.094 0 000-22.71 6.469 6.469 0 01-4.157-6.107v-13.761a3.355 3.355 0 013.355-3.355h781.094a6.467 6.467 0 015.963 3.823 12.086 12.086 0 0022.186 0 6.467 6.467 0 015.963-3.823h14.06a3.355 3.355 0 013.355 3.355v41.165a6.452 6.452 0 01-3.558 5.835 12.086 12.086 0 00-6.826 10.885z"
            transform="translate(-896.89 176.24)"
            fill="#22cbf2"
            stroke="#22cbf2"
            strokeMiterlimit={10}
            opacity={0.275}
          />
        </clipPath>
      </defs>
      <g data-name="Group 4027">
        <path
          data-name="Path 2461"
          d="M10.883 24.391a12.081 12.081 0 00-6.824-10.884 6.453 6.453 0 01-3.56-5.836V4.185A3.355 3.355 0 013.854.83H833.12a3.355 3.355 0 013.355 3.355v29.4a6.469 6.469 0 01-4.157 6.107 12.094 12.094 0 000 22.71 6.469 6.469 0 014.157 6.107v13.765a3.355 3.355 0 01-3.355 3.355H52.027a6.467 6.467 0 01-5.963-3.823 12.086 12.086 0 00-22.186 0 6.467 6.467 0 01-5.963 3.823H3.855A3.355 3.355 0 01.5 82.274V41.11a6.452 6.452 0 013.558-5.835 12.086 12.086 0 006.825-10.884z"
          fill="#22cbf2"
          stroke="#22cbf2"
          strokeMiterlimit={10}
          opacity={0.17}
        />
        <g
          data-name="Mask Group 39"
          transform="translate(-353.5 -295.5)"
          clipPath="url(#prefix__a)"
        >
          <g
            data-name="Group 3920"
            fill="none"
            stroke="#22cbf2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={30}
          >
            <path
            style={{opacity:0.3}}
              data-name="Path 2467"
              d="M847.29 285.264l39.08 55.143-39.079 55.148"
            />
            {/* <path
              data-name="Path 2468"
              d="M774.46 285.264l39.08 55.143-39.079 55.148"
              opacity={0.55}
            />
            <path
              data-name="Path 2469"
              d="M701.63 285.264l39.08 55.143-39.079 55.148"
              opacity={0.18}
            /> */}
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
