import react,{useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import SoundIcon from './../svgs/SoundIcon';

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState({
        "drop-item-0":{},
        "drop-item-1":{},
        "drop-item-2":{},
        "drop-item-3":{}
    });
    const history = useHistory();

    const [answerTxt,setAnswerTxt] = useState([]);

    const [items,setItems] = useState([
        {
            id:"1",
            word:"sit down",
            text:"down",
            word2:[
                {
                    text:"sit",
                    type:"word"
                },
                {
                    text:"down",
                    type:"selected"
                }
            ],
            img:baseurl + "assets/drag/item1.png",
            fileLink:baseurl + "assets/tracks/t04/sit_down.mp3"
        },
        {
            id:"2",
            word:"stand up",
            text:"stand",
            word2:[
                {
                    text:"stand",
                    type:"selected"
                },
                {
                    text:"up",
                    type:"word"
                }
            ],
            img:baseurl + "assets/drag/item2.png",
            // fileLink:baseurl + "assets/audio/audio1/listen.mp3"

            fileLink:baseurl + "assets/tracks/t04/stand_up.mp3"

        },
        {
            id:"3",
            word:"listen",
            text:"isten",
            word2:[
                {
                    text:"l",
                    type:"word"
                },
                {
                    text:"isten",
                    type:"selected"
                }
            ],
            img:baseurl + "assets/game6/b1/item4.png",
            fileLink:baseurl + "assets/audio/audio1/listen.mp3"
        },
        {
            id:"4",
            word:"color",
            text:"olor",
            word2:[
                {
                    text:"c",
                    type:"word"
                },
                {
                    text:"olor",
                    type:"selected"
                }
            ],
            img:baseurl + "assets/game6/b1/item2.png",
            fileLink:baseurl + "assets/audio/audio1/color.mp3"
        }
    ]);

        const [randList,setRandList] = useState([]);
    

    const drag = (e,select) => {
        setCurrentDrag(select);
        e.preventDefault();

    }
    const drop = (e) => {
        document.getElementById("clickbt").play();

        let userSelectV1 = {...userSelect};
        let currentRandList = [...randList];

        setUserSelect(userSelectV1);

        if(userSelectV1[e.target.id] !== undefined) {
            let replaced = userSelectV1[e.target.id];
            currentRandList.push(replaced);
        }
        currentRandList = currentRandList.filter((item) => item.id !== currentDrag.id);
        userSelectV1[e.target.id] = currentDrag;
        setUserSelect(userSelectV1);

        setRandList(currentRandList)

        document.getElementById(e.target.id).innerHTML = `<img id=${e.target.id} src=${currentDrag.img} />`;
        e.preventDefault();
    }
    const allowDrop = (ev) => {     
        ev.preventDefault();
        
    }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      const checkQuestion = () => {

            let userSelect2 = Object.values(userSelect);
            userSelect2 = JSON.stringify(userSelect2);
            let items2 = JSON.stringify(items);

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:"Listen, stick, and write.",
                userAnswers:[],
                type:"listen-stick-write"
            }

            let allcorrect = true;

            if(userSelect2 === items2) {
                // document.getElementById("correct-au").play();
                allcorrect = true;
            } else {
                // document.getElementById("wrong-au").play();
                allcorrect = false;
            }
            userSelect2 = JSON.parse(userSelect2);
            items2 = JSON.parse(items2);
            items2.forEach((subItem,i) => {
                if(userSelect2[i] !== undefined) {

                    // console.log(userSelect2,subItem);

                    if(userSelect2[i].id === subItem.id && answerTxt[i] === subItem.text) {
                        subItem.correctA = true;
                        document.getElementById("drop-item-" + i).style.border = "5px solid #83cd83";

                    } else {
                        allcorrect = false;
                        subItem.correctA = false;
                        document.getElementById("drop-item-" + i).style.border = "5px solid #F84E53";

                        
                    }
                    answerFormat.userAnswers.push({
                        userAnswer:answerTxt[i],
                        correctAnswer:subItem.text,
                        correct:subItem.correctA
                    })
                } else {
                    allcorrect = false;
                    subItem.correctA = false;
                    document.getElementById("drop-item-" + i).style.border = "5px solid #F84E53";

                    answerFormat.userAnswers.push({
                        userAnswer:"",
                        correctAnswer:subItem.text,
                        correct:subItem.correctA
                    })
                }
            });


            if(allcorrect === true) {
                document.getElementById("correct-au").play();
                //allcorrect = true;
            } else {
                document.getElementById("wrong-au").play();
                //allcorrect = false;
            }

            answerFormat.pass = allcorrect;

            // setItems(items2);


          setTimeout(() => {
           // history.push(baseurl + "LookAndSay")
           props.nextExercise(answerFormat);
          window.location.hash = "#4";
          }, 3000);
      }

    useEffect(() => {
        let currentItems = [...items];

        currentItems = shuffle(currentItems);

        setRandList(currentItems,items);

        let lists = currentItems.map((item) => []);

        setAnswerTxt(lists);


        setTimeout(() => {
            document.getElementById("intr").play();
        },300)
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const changeTxt = (value,i) => {

        let currentList = [...answerTxt];
        currentList[i] = value;
        setAnswerTxt(currentList);
    }

    const playAudio = (i) => {
        document.getElementById("audiodrag-" + i).play();
    }   

    return(<div>
                <div className="question-title-container">
                    <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                    <img src={baseurl + "assets/game5/listen.svg"} />
                    <h1 className="question-title">Listen, stick, and write.</h1>

                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                </div>

        <div id="drag-Lists">
            {items.map((item,i) => {
                return(
                <div className="drop-item">
<div className="drop-subItem" onDrop={drop} id={"item-" + i} onDragOver={allowDrop} id={"drop-item-" + i}></div>                    {/* {item.correctA !== undefined && <div style={item.correctA === true?{border:"3px solid lightgreen"}:{border:"3px solid red"}} className="drop-subItem" onDrop={drop} id={"item-" + i} onDragOver={allowDrop}></div>} */}


<div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
<audio src={item.fileLink} id={"audiodrag-" + i} />
<div style={{cursor:"pointer"}} onClick={() => playAudio(i)}><SoundIcon style={{width:30}} /></div>

                    <div className="drop-title" style={{display:"flex",alignItems:"center",gap:5,justifyContent:"center"}}>{item.word2.map((subWord,j) => {
                        // return <span>{subItem}</span>

                        if(subWord.type === "selected") {
                            let textObj = [];
                            let subdata = subWord.text.split("");
                            // let width = ((1/subdata.length) * 100) - 5;
                            // subdata.map((subItem2) => {
                            //     textObj.push(<div style={{width:width + "%"}}><input spellCheck="false"  type="text" maxLength={1} style={{borderBottom:"2px solid #000",width:"100%"}} /></div>)
                            // });
                            return(<div className={"editable-input editable-input-drag"} style={{width:"60%",textAlign:"left",display:"flex",flexWrap:"wrap"}}><input onChange={(e) => changeTxt(e.target.value,i)} spellCheck="false"  style={{textAlign:"left"}} type="text" maxLength={subdata.length} style={{width:"100%",textAlign:"left",borderBottom:"2px solid #B7BCD1"}} /></div>)

                        } else {
                            
                            return(<div>{subWord.text}</div>)
                        }
                    })}</div>
                </div>
                </div>);
            })}
        </div>
        <div id="drag-items" style={{marginTop:100}}>
            {randList.map((item) => {
                console.log(item);
                if(item.img !== undefined) {
                    return(<div className="drag-item" onDrag={(env) => {drag(env,item)}}><img src={item.img} /></div>)
                }
            })}
        </div>

             
        <audio src={`${baseurl}assets/audio/p4/title.mp3?id=123231213`} className="hidden-audio" id="intr" />
                <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>

                </div>


    </div>)
}
export default DragAndDrop;