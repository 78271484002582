import * as React from "react"

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={19} height={33} {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path
            data-name="Rectangle 1530"
            transform="translate(558 359)"
            fill="#fff"
            stroke="#707070"
            d="M0 0h19v33H0z"
          />
        </clipPath>
        <filter
          id="prefix__b"
          x={-307}
          y={-62}
          width={350}
          height={178}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx={-10} dy={13} />
          <feGaussianBlur stdDeviation={15} result="blur" />
          <feFlood floodColor="#0b329d" floodOpacity={0.133} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        data-name="Mask Group 65"
        transform="translate(-558 -359)"
        clipPath="url(#prefix__a)"
      >
        <g data-name="Group 3986">
          <g transform="translate(558 359)" filter="url(#prefix__b)">
            <rect
              data-name="Rectangle 1501"
              width={250}
              height={88}
              rx={17}
              transform="translate(-252 -30)"
              fill={props.color === undefined?"#45ea98":props.color}
            />
          </g>
          <path
            data-name="Path 2457"
            d="M318.209 410.996h230.934a10.935 10.935 0 0010.935-10.935v-13.2a2.556 2.556 0 013.387-2.407 22.086 22.086 0 017.913 4.639 2.162 2.162 0 003.591-1.956c-1.569-8.862-6.819-16.085-11.528-21a12.283 12.283 0 01-3.363-8.51v-18.692A10.935 10.935 0 00549.143 328H318.209a10.935 10.935 0 00-10.935 10.935v61.13a10.935 10.935 0 0010.935 10.931z"
            fill="#fff"
            // stroke="#45ea98"
            stroke={props.color === undefined?"#45ea98":props.color}

            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
          />
          <text
            data-name="Hello! I\u2019m Sam. What\u2019s your name?"
            transform="translate(434 363)"
            fill="#0b329d"
            fontSize={24}
            fontFamily="GothamRounded-Medium, Gotham Rounded"
            fontWeight={500}
          >
            <tspan x={-89.724} y={0}>
              {"Hello! I\u2019m Sam."}
            </tspan>
            <tspan x={-114.948} y={28}>
              {"What\u2019s your name?"}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
