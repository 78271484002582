import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Animate } from 'react-simple-animate';


const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]

function ListenAndChooseTheWord(props) {
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState("");
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [answerFormat,setAnswerFormat] = useState({
        pass:true,
        progress:0,
        questionTitle:"Listen and point.",
        userAnswers:[],
        type:"listen-stick-write"
    });

    const history = useHistory();

    const instr = useRef();


    useEffect(() => {
            let selected = props.questions;
            setCurrent(0);
            selected = _.shuffle(selected);
            

            if(selected.length > 0) {
                selected[0].complete = true;
                //  selected[0].answers = _.shuffle(selected[0].answers);
                selected[0].answers = _.shuffle(selected[0].answers);
                let find = selected[0].answers.findIndex((item) => item.correct === true);

                setAudio(selected[0].answers[find].audio);

                setQuestion(selected[0])

               // document.getElementById("intr2").play();
               document.getElementById("intr2").play();

               setTimeout(() => {

               }, 500);

               setTimeout(() => {
                document.getElementById("intr").play();

            },3000)


                // setTimeout(() => {
                //     document.getElementById("intr").play();
                // },1800)
           
            }
            setData(selected);

            // console.log(props.questions)

            // setTimeout(() => {
            //     if(document.getElementById("question-audio") !== null) {
            //         document.getElementById("question-audio").play();
            //     }
            // }, 500);
                

    }, [props.questions])

    const checkQuestion = (e) => {
        
        let counterNext = current;
       // let currentData = data;

       let answerFormat2 = {...answerFormat};

     


        counterNext++;


        console.log("selected",question.answers[userSelect]);
        if(question.answers[userSelect].correct === true) {
           // props.playAudio(true);
           

            answerFormat2.userAnswers.push({
                userAnswer:question.answers[userSelect].name,
                correctAnswer:question.answers[userSelect].name,
                correct:true
            })

            setShowCorrect(true);
            document.getElementById("correct-au").play();
        } else {
            answerFormat2.pass = false;
          //  props.playAudio(false);
            setShowCorrect(true);
            document.getElementById("wrong-au").play();

            let findCorrect = question.answers.findIndex((subItem) => subItem.correct === true);

            answerFormat2.userAnswers.push({
                userAnswer:question.answers[userSelect].name,
                correctAnswer:question.answers[findCorrect].name,
                correct:false
            })
        }

        setTimeout(() => {

            if(counterNext < data.length) {
                setAnswerFormat(answerFormat2);
                let selected =  data[counterNext];
                selected.answers = _.shuffle(selected.answers);
                let find = selected.answers.findIndex((item) => item.correct === true);
    
                setQuestion(selected);
                setAudio(selected.answers[find].audio);
                setShowCorrect(false);
                setUserSelect("");
                setCurrent(counterNext);  
                document.getElementById("intr").play();

                setTimeout(() => {
                },300)

            } else {
               // history.push(baseurl + "drag")


               

               props.nextExercise(answerFormat2); 

                window.location.hash = "#3"
            }
        }, 3000);


        /*setTimeout(() => {
            history.push(baseurl + "drag")
        }, 3000);*/


        // setTimeout(() => {
        //     setShowCorrect(false);

        //     if(data[counterNext] !== undefined) {
        //         currentData[counterNext].complete = true;
        //         data[counterNext].answers = _.shuffle(data[counterNext].answers);

                
    
        //         setData(currentData);
        //         setCurrent(counterNext);
        //         setQuestion(data[counterNext])
        //         setUserSelect("");
        //     } else {
        //         props.setShowGoodJob(true);
        //     }
        //     setTimeout(() => {
        //         if(document.getElementById("question-audio") !== null) {
        //             document.getElementById("question-audio").play();
        //         }
        //     }, 500);
        // }, 3000);

    }

    const playAudio = (e) => {
        if(document.getElementById("question-audio") !== null) {
            document.getElementById("question-audio").play();
        }
    }

    const checkUserSelect = (userSelect,indx) => {
        if(userSelect === indx) {
            return ("selected multiple-question-item");
        } else {
            return ("multiple-question-item");
        }
    }

    const checkCorrect = (userSelect,indx,correct) => {

        if(correct === true) {
            return("multiple-question-item selected correct")
        } else {
            if(userSelect === indx) {
                return ("selected wronganswer multiple-question-item");
            } else {
                return ("multiple-question-item");
            }
        }
        
    }

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }


    return(<div>
        

 

        <div className="question-title-container">
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/listen.svg"} />
                <h1 className="question-title">Listen and point.</h1>

            </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                </div>

        <div className="question-container">
            {question.answers !== undefined && 
                <div>
                <div className="multiple-question-data">

                    {props.quizsection.video !== undefined && props.quizsection.video !== "" && props.quizsection.video !== "undefined" && <div className="question-video"><video controls src={ process.env.REACT_APP_FRONT_LINK + ""} /></div>}
                    
                    {question.audio !== "" && question.audio !== undefined && <audio style={{display:"none"}} id="question-audio" src={process.env.REACT_APP_BACKEND_LINK + "/" + question.audio} style={{display:"none"}} />}
                    {question.answers.map((item,indx) => {

                        return (<div 
                            className={showCorrect === true?checkCorrect(userSelect,indx,item.correct):checkUserSelect(userSelect,indx)}
                        onClick={() => {
                            document.getElementById("clickbt").play();
                            setUserSelect(indx);}}>
                                <Animate play={true} easeType={"cubic-bezier(0.55, 0.06, 0.68, 0.19)"} start={{transform:"scale(0)",opacity:0}} end={{transform:"scale(1)",opacity:1}} duration={0.3} delay={indx * 0.2}>
                            <div className="multiple-question-img">
                                 {showCorrect === false && <img className={userSelect === indx?"answer-img selected":"answer-img"} src={baseurl + item.fileLink} />}
                                 {showCorrect === true && <img className={item.correct === true?"answer-img selected correct":userSelect === indx?"answer-img selected wronganswer":"answer-img"} src={baseurl + item.fileLink} />}
                                 <div className="multiple-question-title" style={{
                                         textAlign: "center",
                                         fontSize: 26
                                 }}><div>{item.name}</div></div>
                            </div>
                            </Animate>
                        </div>)
                    })}
                </div>

                <audio src={`${baseurl}${audio}`} className="hidden-audio" id="intr" />

                <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />


                <div className="button-check">
                <button className="check-answer-bt" onClick={checkQuestion} disabled={userSelect === ""?true:false} variant="contained" color="primary">Check my answer</button>

                </div>

                </div> 
            }
                            <audio src={`${baseurl}assets/audio/audio1/listen_point.mp3`} className="hidden-audio" id="intr2" />

        </div>


    </div>)
}

export default ListenAndChooseTheWord;