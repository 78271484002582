import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import ArrowLeftSide from './../svgs/arrowLeftSide'


let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {

    const history = useHistory();

    const intr = useRef(null);
    const audioPlayer = useRef(null)

    const [locations,setLocations] = useState([]);

    const [data,setData] =  useState({
        audio:"",
        speechs:[]
        // audio:"assets/tracks/t06/t06.mp3",
        // speechs:[
        //     {
        //         imageLink:"assets/vertical/pic.png",
        //         audio:"assets/tracks/t06/t06_v1.mp3",
        //         locations:[
        //             {
        //                 location:"leftside",
        //                 top:"10vh",
        //                 spans:[
        //                     {
        //                         text:"Hello!"
        //                     },
        //                     {
        //                         text:"I'm",
                               
        //                     },
        //                     {
        //                         text:"",
        //                     }
        //                 ],
        //             },
        //             {
        //                 location:"rightside",
        //                 top:"10vh",
        //                 spans:[
        //                     {
        //                         text:"Hi!"
        //                     },
        //                     {
        //                         text:"I'm"
        //                     },
        //                     {
        //                         text:""
        //                     }
        //                 ]
        //             }
        //         ]
        //     }
        // ]
    })
    const arrayMoveMutable = (array, fromIndex, toIndex) => {
        const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;
    
        if (startIndex >= 0 && startIndex < array.length) {
            const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;
    
            const [item] = array.splice(fromIndex, 1);
            array.splice(endIndex, 0, item);
        }
        return array;
    }

      



        const [randList,setRandList] = useState([]);
    

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      const checkQuestion = () => {


        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"talk-friend"
        }

         //    history.push(baseurl + "listen_read"); 
         props.nextExercise(answerFormat);
      }

    useEffect(() => {
        // intr.current.play();
        document.getElementById("str").play(); 

        setData(props.questions)

        let current_locations = []
        data.speechs.forEach((mainItem,m) => {
            mainItem.locations.forEach((item,i) => {
                item.spans.forEach((subItem,j) => {
                    subItem.id = `item-${m}-${i}-${j}`;
                    current_locations.push(subItem);
                })
            })
        });
        
        // console.log(current_locations);
        

        setLocations(current_locations);




        // setTimeout(() => {
            // intr.current.play();
        // },300)
        
    }, [])

    useEffect(() => {
        
        setTimeout(() => {

            audioPlayer.current.addEventListener("ended",(event) => {
                if(document.getElementsByClassName("word-highlight").length > 0) {
                    document.getElementsByClassName("word-highlight")[0].classList.remove("word-highlight")
                }
            });
            audioPlayer.current.addEventListener("timeupdate",(event) => {
                let currentTime = audioPlayer.current.currentTime;
                let cure = [...locations] 
                let find = locations.findIndex((item) => {
                    let findv2 = false;
                    
                    if(item.end >= currentTime) {
                        findv2 = true;
                    }
    
                    return findv2 === true;
                    
                });
                if(find !== -1) {
                    if(document.getElementsByClassName("word-highlight").length > 0) {
                        document.getElementsByClassName("word-highlight")[0].classList.remove("word-highlight")
                    }
                    document.getElementById(locations[find].id).classList.add("word-highlight");
                }
            });
        }, 200);

    }, [audioPlayer,locations])

    const playIntr = (e) => {
        // audioPlayer.current.currentTime = 5.5;
        // audioPlayer.current.play();
        document.getElementById("str").play(); 

    }
    const pause = (e) => {
        audioPlayer.current.pause();
    }
    return(<div style={{width:"70%"}} id="talk-to-friend">
                <div className="question-title-container">
                    <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                    <img src={baseurl + "assets/game5/listen.svg"} />
                    <h1 className="question-title" dangerouslySetInnerHTML={{__html:props.questions.title}}></h1>

                    </div>
                    <div>
                        <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                    </div>

                </div>




             
        <audio src={`${baseurl}assets/tracks/t06/track6222.mp3`} className="hidden-audio" id="intr" ref={intr} />
                <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />



                {data.speechs.map((item,m) => {
                    return(<div className="book-container" style={{position:"relative"}}>
                                        <img src={`${baseurl}${item.imageLink}`} className="image-style img-container"  />

                                        {item.locations.map((subItem,i) => {


                                            let leftRight = {}

                                            if(subItem.location === "leftside") {
                                                leftRight = {left:subItem.left}
                                            } else {
                                                leftRight = {right:subItem.right}
                                            }

                                                        
                                        return(<div style={{top:subItem.top,width:"auto",gap:10,flexWrap:"wrap",...leftRight}} className={subItem.location}>

                                            
                                                
                                            {subItem.location === "leftside"?
                                            <ArrowLeftSide className="leftiside-arrow" style={{position: "absolute",right: -19}} color="#22CBF2" />:
                                            <ArrowLeftSide className="rightside-arrow" color="#22CBF2" />}
                                            
                                            {subItem.lines.map((lineItem) => {
                                                return(<div>
                                                    {lineItem.spans.map((subItemV2,j) => {

if(subItemV2.text !== "") {
    return(<span id={`item-${m}-${i}-${j}`} dangerouslySetInnerHTML={{__html: subItemV2.text + " "}}></span>)
} else {
    // return(<span><input spellCheck="false"  type="text" 
    // style={{border:"0",borderBottom:"2px dashed #B7BCD1",fontFamily:"gotham-medium",fontSize:20,width:120,color:"#0B329D"}} /></span>)

    return(<span style={{margin:"0"}}><span className="emptydots"></span></span>)

}

                                                    })}
                                                </div>)
                                            })}

                                            {/* {subItem.spans.map((subItemV2,j) => {
                                                if(subItemV2.text !== "") {
                                                    return(<span id={`item-${m}-${i}-${j}`} dangerouslySetInnerHTML={{__html: subItemV2.text + " "}}></span>)
                                                } else {
                                                    return(<span><input spellCheck="false"  type="text" 
                                                    style={{border:"0",borderBottom:"2px dashed #B7BCD1",fontFamily:"gotham-medium",fontSize:20,width:120,color:"#0B329D"}} /></span>)
                                                }
                                        })} */}
                                                            
                                        </div>)
                                        })}


                    </div>)
                })}
                <audio src={baseurl + data.audio} ref={audioPlayer} />

                {/* <div align="center" style={{position:"relative"}}>

               

                  
                <img src={`${baseurl}${data[0].imageLink}`} className="image-style" />
                {data[0].locations.map((item,i) => {

                
                    return(<div className={item.location}>{item.spans.map((subItem,j) => {
                        return(<span id={`item-${i}-${j}`} dangerouslySetInnerHTML={{__html: subItem.text + " "}}></span>)
                    })}
                                        
                    </div>)
                })}
                                        <audio src={baseurl + data[0].audio} ref={audioPlayer} />

                </div> */}



{/* <audio src={baseurl + "assets/levels/level1/unit1/assets/audios/say_hello_to_a_friend.mp3"}  id="str" /> */}
<audio src={baseurl + props.questions.audio}  id="str" />

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>

                </div>


    </div>)
}
export default DragAndDrop;