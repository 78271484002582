import react,{useCallback, useEffect, useRef,useState } from 'react'
import CurvedArrow from "react-curved-arrow";
import DrawLine from "./DrawLine";
import _, { findLastKey } from 'lodash'
import GreenCircleV2 from './../svgs/greenCircle_v4'

function MatchingDemo(props) {

    const [items, setItems] = useState([]);
    const [items2, setItems2] = useState([]);

    const [finalLines, setFinalLines] = useState([]);

    const [lines, setLines] = useState([]);

    const baseurl = process.env.REACT_APP_FRONT_LINK;

    const [p1, setP1] = useState({
      x: 0, y: 0
    })

    const [p2, setP2] = useState({ x: 700, y: 700 })
    
    const [showLine, setshowLine] = useState(false)
    const [startIndex, setStartIndex] = useState("")
    const [endIndex, setEndIndex] = useState("")
    const [dragStartItem, setDragStartItem] = useState({})
    const [dragEndItem, setDragEndItem] = useState({})

    
    useEffect(() => {

      // console.log(props.questions)
      let finalLines1 = [];
      props.questions.toItem.forEach((item) => {
        finalLines1.push({})
      })
      setFinalLines([...finalLines1])
      setItems(props.questions.toItem)
      setItems2(_.shuffle(props.questions.fromItem))

      document.getElementById("intr").play();

      // let current_lines = [];
      // let intial = {
      //     x1:0,
      //     y1:0,
      //     x2:50,
      //     y2:50,
      //     show:true
      // }

      // items.forEach((item) => {
        
      //   current_lines.push(intial)
      // });

      // setLines(current_lines)
    }, [])


    const mousemove = (e) => {
      setP2({x:e.clientX,y:e.clientY})
    }

    const mouseup = (e) => {
      if(e.target.id.includes("end-")) {

        let startIndex2 = parseInt(localStorage.getItem("startIndex"));
        console.log(startIndex2);
        
         let i = e.target.id;
         i = i.replace("end-","");
         i = parseInt(i); 


         setEndIndex(i);
      let finalLines2 = finalLines;


      let findTo = finalLines2.findIndex((item) => item.to === "to" + i);

      let findFrom = finalLines2.findIndex((item) => item.from === "from" + startIndex2);

      if(findTo !== -1) {
        finalLines2[findTo] = ""
      }
      if(findFrom !== -1) {
        finalLines2[findFrom] = ""
      }
      
      if(finalLines2.length > 0) {
        finalLines2[startIndex2] = {}
        finalLines2[startIndex2].from = "from" + startIndex2;
        finalLines2[startIndex2].to = "to" + i;
      

      }

      let items_1 = [...items];
      let items_2 = [...items2]

      if(items_2.length > 0 && items_1.length > 0 && finalLines2.length > 0) {
        items_1[i].userAnswer = items_2[startIndex2].text;
      

        setItems(items_1);
        
        setFinalLines(finalLines2);

      }

      }
       

      setshowLine(false);

    }

    const mousedown = (e) => {
      if(e.target.id.includes("start-")) {
        console.log(e.target.id)
        let diemenions = e.target.getBoundingClientRect();
        setP1({x:diemenions.x + (diemenions.width / 2),y:diemenions.y})
        setP2({x:diemenions.x + (diemenions.width / 2),y:diemenions.y})
  
         document.getElementsByTagName("body")[0].style.overflow = "hidden";
        setshowLine(true);
        // let i = parseInt(e.target.id.replace("start-",""));

        let i = e.target.id;
        i = i.replace("start-","");
        i = parseInt(i); 

        localStorage.setItem("startIndex",i);


        // setStartIndex(i);
      }
    }

    const dragStart = useCallback((e) => {
      let currentId = e.target.id;
      currentId = parseInt(currentId.replace("start-","")); 


      if(items2[currentId] !== undefined) {
        localStorage.setItem("dragStartItem",JSON.stringify(items2[currentId]));

      }
    });

    const dragover = useCallback((e) => {
      if(e.target.id.search("end-") !== -1) {

        let currentId = e.target.id;
        currentId = parseInt(currentId.replace("end-","")); 

        if(items[currentId] !== undefined) {
          // console.log(items[currentId])
          // setDragEndItem(items[currentId])
          localStorage.setItem("dragEndItem",JSON.stringify(items[currentId]))
        }
  
      }
    });

    const dragend = useCallback((e) => {
        let dragStartItem = localStorage.getItem("dragStartItem");
        let dragEndItem = localStorage.getItem("dragEndItem");

        if(dragStartItem !== "") {
          dragStartItem = JSON.parse(dragStartItem);
        }

        if(dragEndItem !== "") {
          dragEndItem = JSON.parse(dragEndItem);
        }

        // console.log(dragStartItem,dragEndItem)

        let items_v1 = [...items]
        let items_v2 = [...items2]

        if(dragEndItem !== "" && dragStartItem !== "") {
            let findIndex = items_v1.findIndex((item) => item.text === dragEndItem.text)

            if(findIndex !== -1) {
              items_v1[findIndex].userSelect = dragStartItem;
              // console.log(items_v2,dragStartItem)
              items_v2.map((item) => {
                if(item.text === dragStartItem.text) {
                  item.hide = true;
                }
                return item;
                
              });

              setItems(items_v1);
              setItems2(items_v2);

            }

        }

        localStorage.setItem("dragStartItem","");
        localStorage.setItem("dragEndItem","");


    });


    useEffect(() => {
      // window.addEventListener("mousemove",mousemove);

      //   window.addEventListener("mouseup",mouseup);
      //   window.addEventListener("mousedown",mousedown);
      window.addEventListener("dragstart",dragStart);
      window.addEventListener("dragover",dragover);
      window.addEventListener("dragend",dragend);


        document.getElementById("game-container").style.paddingBottom = "0px";
      return () => {
        window.removeEventListener("dragstart",dragStart);
        window.removeEventListener("dragover",dragover);
        window.removeEventListener("dragend",dragend);



        // window.removeEventListener("mousedown",mousedown);
        // window.removeEventListener("mouseup",mouseup);
        // window.removeEventListener("mousemove",mousemove);
        document.getElementById("game-container").style.paddingBottom = "50px";

      }
    }, [finalLines])

    const itemStyle2 = {color:"#000",flexDirection:"column",width:"10vw",margin:20,display:"flex",justifyContent:"center",alignItems:"center",
    position:"relative",zIndex:111111
  };

  const checkQuestion = (e) => {
    let allcorrect = true;
    let items_2 = [...items];
    let finalLines2 = [...finalLines];

    let answerFormat = {
      pass:true,
      progress:0,
      questionTitle:props.questions.title,
      userAnswers:[],
      type:"match_pairs"
  }


    items_2.forEach((item,i) => {
      if(item.userSelect !== undefined) {
        if(item.userSelect.text !== item.correct) {
          allcorrect = false;
          items_2[i].correctAnswer = false;
          finalLines2[i].correctAnswer = false;
        } else {
          items_2[i].correctAnswer = true;
          finalLines2[i].correctAnswer = true;
        }

        answerFormat.userAnswers.push({
          userAnswer:item.userSelect.text,
          correctAnswer:item.correct,
          correct:items_2[i].correctAnswer
        })

      } else {
        allcorrect = false;
        items_2[i].correctAnswer = false;
        finalLines2[i].correctAnswer = false;

        answerFormat.userAnswers.push({
          userAnswer:"",
          correctAnswer:item.correct,
          correct:items_2[i].correctAnswer
        })
      }

    })

    setFinalLines(finalLines2)
    setItems(items_2);

    if(allcorrect === true) {
      document.getElementById("correct-au").play();

  } else {
      document.getElementById("wrong-au").play();

  }
  answerFormat.pass = allcorrect;
  setTimeout(() => {
    props.nextExercise(answerFormat);
}, 3000);



  }
    const startLine = (e,i) => {

      // let diemenions = e.target.getBoundingClientRect();
      // setP1({x:diemenions.x + (diemenions.width / 2),y:diemenions.y})
      // setP2({x:diemenions.x + (diemenions.width / 2),y:diemenions.y})

      // document.getElementsByTagName("body")[0].style.overflow = "hidden";
      // setshowLine(true);
      // setStartIndex(i);
    }
    const endLine = (e,i) => {
      // setEndIndex(i);
      // let finalLines2 = finalLines;

      // let findTo = finalLines2.findIndex((item) => item.to === "to" + i);

      // let findFrom = finalLines2.findIndex((item) => item.from === "from" + startIndex);

      // if(findTo !== -1) {
      //   finalLines2[findTo] = ""
      // }
      // if(findFrom !== -1) {
      //   finalLines2[findFrom] = ""
      // }

      // finalLines2[startIndex] = {}
      // finalLines2[startIndex].from = "from" + startIndex;
      // finalLines2[startIndex].to = "to" + i;

      // // console.log(items[i],items2[startIndex])

      // let items_1 = [...items];
      // let items_2 = [...items2]
      // items_1[i].userAnswer = items_2[startIndex].text;
      

      // setItems(items_1);

      // setFinalLines(finalLines2);
      // // document.getElementsByTagName("body")[0].style.overflow = "scroll";
      // setshowLine(false);

      

    }
    return (
        <>
        <div style={{width:"70%"}}>
                <div className="question-title-container">
                    <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                    <img src={baseurl + "assets/game5/listen.svg"} />
                    <h1 className="question-title">{props.questions.title}</h1>

                    </div>
                    <div>
                        <div id="audio-bt" onClick={() => {
                                document.getElementById("intr").play();

                        }}><img src={baseurl + "assets/audio/listen.png"} /></div>
                    </div>

                </div>

        <div>
                        {props.questions.mainTxt !== undefined &&
                               <GreenCircleV2 width={200} height={"auto"} style={{marginTop:-20}} title={
                                <>

{props.questions.mainTxt !== undefined && <>
                    {props.questions.mainTxt.map((item) => {
                      return(
                        <tspan x={item.x} y={item.y} fontSize={item.fontSize}>
                        {item.text} 
                        </tspan>
                      

                      )
                    })}  
                    </>} 
                                
                              </>} />
                        }
 


          
        <div style={{display:"flex",alignItems:"flex-start"}}>
          {items.map((item,i) => (   
              <div onClick={(e) => {endLine(e,i)}} className={'to' + i} style={{minHeight:100,...itemStyle2}}>
                <img id={"end-" + i} style={item.correctAnswer === undefined?{border:"5px solid #22CBF2",borderRadius:20}:item.correctAnswer === true?{border:"5px solid #45EA98",borderRadius:20}:{border:"5px solid #F84E53",borderRadius:20}}
              
              src={baseurl + item.fileLink} />
              {item.userSelect !== undefined && <img 
              
              style={item.correctAnswer === undefined?{border:"5px solid #22CBF2",borderRadius:20,position:"absolute",top:150}:item.correctAnswer === true?{border:"5px solid #45EA98",borderRadius:20,position:"absolute",top:150}:{border:"5px solid #F84E53",borderRadius:20,position:"absolute",top:150}}
              src={baseurl + item.userSelect.fileLink} />}
                            {props.questions.hidetext === undefined && <div className='itemtext' style={{fontFamily:"gotham-medium",background:"#22CBF2",padding:"5px 20px",color:"#fff",borderRadius:10,marginTop:-10}}>{item.text}</div>}

              </div>))}
        </div>






        
        <div style={{display:"flex",marginTop:200}}>
          {items2.map((item,i) => (   
              <div 
              onClick={(e) => { startLine(e,i); }}
              className={'from' + i} style={item.hide === true?{...itemStyle2,display:"none"}:{...itemStyle2}}>
                
                <img id={"start-" + i} style={{border:"5px solid #22CBF2",borderRadius:20}} src={baseurl + item.fileLink} />
                                          {props.questions.hidetext === undefined && <div className='itemtext' style={{fontFamily:"gotham-medium",background:"#22CBF2",padding:"5px 20px",color:"#fff",borderRadius:10,marginTop:-10}}>{item.text}</div>}

              </div> 
          ))}
        </div>
        {/* <CurvedArrow fromSelector=".to0" toSelector=".from2" middleY={-50} toOffsetY={-60} fromOffsetY={60} width={4} />
        <CurvedArrow fromSelector=".to1" toSelector=".from1" middleY={-50} toOffsetY={-60} fromOffsetY={60} width={4} /> */}
        {finalLines.map((item) => {
          return(<>
            {item.from !== undefined ?<CurvedArrow fromSelector={`.${item.from}`} color={item.correctAnswer === undefined?"#B7BCD1":item.correctAnswer === true?"#45EA98":"#F84E53"} toSelector={`.${item.to}`} middleY={-50} toOffsetY={0} fromOffsetY={75} width={4} />:""}
          </>)
        })}

        </div>
        {showLine === true && <DrawLine p1={p1} p2={p2} setP2={setP2} />}


        <div className="button-check" style={{marginTop:10}}>
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>
            </div>
            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />


        {/* <div style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}}>

          
            
                           <svg
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d={`M ${sx} ${sy} C ${c1x} ${c1y}, ${c2x} ${c2y}, ${ex} ${ey}`}
                stroke={color}
                strokeWidth={arrowHeadSize / 2}
                fill="none"
              />
              <polygon
                points={`0,${-arrowHeadSize} ${arrowHeadSize *
                  2},0, 0,${arrowHeadSize}`}
                transform={`translate(${ex}, ${ey}) rotate(${ae})`}
                fill={color}
              />
            </svg> 

            );


        </div> */}


</div>
        </>
    )
  
}
export default MatchingDemo;