import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import Label from './../svgs/Label';
import AudioRecorder from 'audio-recorder-polyfill'

window.MediaRecorder = AudioRecorder;
let recorder2

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["11","","","",""]);
    const [recording,setRecording] = useState(false);
    const [showDone,setShowDone] = useState({});
    const [src,setSrc] = useState("");

    const [items,setItems] = useState([
        {
            id:"1",
            word:"I’m Bob",
            img:baseurl +"assets/game2/item1.png",
            words:[
                {
                    text:""
                },
                {
                    text:"ob"
                }
            ]
        },
        {
            id:"2",
            word:"I’m Muzzy",
            img:baseurl +"assets/game2/item2.png",
            words:[
                {
                    text:""
                },
                {
                    text:"uzzy"
                }
            ]
        },
        {
            id:"3",
            word:"I am Corvax",
            img:baseurl +"assets/game2/item3.png",
            words:[
                {
                    text:""
                },
                {
                    text:"orvax"
                }
            ]
        },
        {
            id:"4",
            word:"I am the Queen",
            img:baseurl +"assets/game2/item4.png",
            words:[
                {
                    text:""
                },
                {
                    text:"ueen"
                }
            ]
        },
        {
            id:"5",
            word:"I am the Sylvia",
            img:baseurl +"assets/game2/item5.png",
            words:[
                {
                    text:""
                },
                {
                    text:"ylvia"
                }
            ]
        }
        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (e) => {
          e.preventDefault();
      }






      const recorder = () => {
        
        // Request permissions to record audio
          navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
              recorder2 = new MediaRecorder(stream)
              setRecording(true);
          
              // Set record to <audio> when recording will be finished
              recorder2.addEventListener('dataavailable', e => {
                  setSrc(URL.createObjectURL(e.data));
                  var reader = new FileReader();
                  reader.readAsDataURL(e.data); 
                  reader.onloadend = function() {
                      var base64data = reader.result;                

                      axios.post("https://beta.thelearnbook.com:4000/demo/speech",{audio:base64data}).then((res2) => {
                          if(res2.data.transcription !== undefined) {
                              let transcription = res2.data.transcription.toLowerCase();
                            //   if(currenttxt === transcription) {
                            //       check = true;
                            //   }
                          }

                         


                      });
                  }
                 
                  
              })
          
              // Start recording
              recorder2.start()
          })

  }


    useEffect(() => {
        let currentItems = [...items];

        currentItems = shuffle(currentItems);

        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }
    
    const stopRecorder = () => {
        recorder2.stop()
        // Remove “recording” icon from browser tab
        recorder2.stream.getTracks().forEach(i => i.stop())
        setRecording(false);
    }

    return(<div id="look_write">
                <div className="question-title-container">
                    <h1 className="question-title">Read the names</h1>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>
        <div id="readnames-items">
            {/* {randList.map((item) => {
                return(
                <div className="readnames-item">
                    <div className="word-v1">
                       
                       <Label title={"test"} />


                    </div>
                </div>)
            })} */}
            <div className="readnames-item">
                    <div className="word-v1">
                        <Label title={"test"} />

                    </div>
            </div>
        </div>

        <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%",padding:"30px 0px",height:70}}>
            {recording === true && <img onClick={stopRecorder} style={{height:"100%"}} src={process.env.REACT_APP_FRONT_LINK + "assets/icons/pause.png"} />}
            {recording === false && <img onClick={recorder} style={{height:"100%"}} src={process.env.REACT_APP_FRONT_LINK + "assets/icons/record.png"} />}

        </div>
        <audio src={src} controls />

             

        {/* <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary">Check my answer</button>

                </div>
 */}

    </div>)
}
export default DragAndDrop;