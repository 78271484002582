import React,{useState} from 'react'

function SplitText() {
    const [result,setResult] = useState("");
    const [textarea,setTextarea] = useState("");
    const [textarea2,setTextarea2] = useState("");

    const exportData = (e) => {
        let selected = textarea2;
        let selected2 = textarea2;

        let finalList = [];
        selected2 = JSON.parse(selected2);
        selected2.forEach((item) => {
            finalList.push({
                start: 0,
                end: item[2],
                text: item[0]
            })
        });

        console.log(finalList);
        // selected = selected.split(" ");
        // let final = selected.map((item) => {
        //     let obj = {};
        //     obj.start = 0;
        //     obj.end = 0;
        //     obj.text = item;
        //     return obj
        // });
        // console.log(final);
    }
    return(<div style={{display:"flex"}}>

        <textarea style={{width:"96%",margin:"1% 2% 0 2%",height:"50vh",boxSizing:"border-box",flex:1}} value={textarea} id="textarea" onChange={(e) => setTextarea(e.target.value)} />
        <textarea style={{width:"96%",margin:"1% 2% 0 2%",height:"50vh",boxSizing:"border-box",flex:1}} value={textarea2} id="textarea" onChange={(e) => setTextarea2(e.target.value)} />

        <button style={{margin:"1% 2% 0 2%"}} onClick={exportData}>Export</button>
        <div>{result}</div>
    </div>)
}
export default SplitText;