import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import BlueArrow from './../svgs/blue'
import BlueBg from './../svgs/blueBg'
import Shape1 from './shapes/shape1'

const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);
    const [userAnswers,setUserAnswers] = useState([]);
    const [dragItem,setDragItem] = useState({});

    const [items,setItems] = useState([
        // {
        //     id:"1",
        //     word:"I am Muzzy.",
        //     word2:"I'm Muzzy.",
        //     fileLink1:baseurl + "assets/tracks/t07/iam_muzzy.mp3",
        //     fileLink2:baseurl + "assets/tracks/t07/im_muzzy.mp3"
        // },
        // {
        //     id:"2",
        //     word:"I am Sylvia.",
        //     word2:"I'm Sylvia.",
        //     fileLink1:baseurl + "assets/tracks/t07/iam_selvia.mp3",
        //     fileLink2:baseurl + "assets/tracks/t07/im_selvia.mp3"
        // }

        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i) => {
        let userSelect2 = [...userSelect];
        userSelect2[i] = value;
        setUserSelect(userSelect2);

    }

    const checkQuestion = () => {

//        setTimeout(() => {
            // history.push(baseurl + "circle_hear")
        // }, 3000);

        document.getElementById("correct-au").play();

        setTimeout(() => {

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:props.questions.title,
                userAnswers:[],
                type:"dragshape"
            }

            props.nextExercise(answerFormat);
        }, 3000);
    }


    useEffect(() => {

        setItems(props.questions.answers)
        let currentItems = props.questions.answers;
        let finalList = [];
        for(let i = 0; i < currentItems.length; i++) {
            finalList.push({})
        }   


        setUserAnswers(finalList);
        // currentItems = shuffle(currentItems);

        setTimeout(() => {
            document.getElementById("intr").play();

        },300)

        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const stopAllAudios = (i) => {
        let audios = document.querySelectorAll("audio");
        audios.forEach((audio) => {
            audio.pause();
            audio.currentTime = 0;
        })
    }

    
    const playAudio = (i) => {
        stopAllAudios();

        
        document.getElementById("filelink1_" + i).play();
        document.getElementById("filelink1_" + i).play();
    }

    const playAudio2 = (i) => {
        stopAllAudios();

        document.getElementById("filelink2_" + i).play();
        document.getElementById("filelink2_" + i).play();

    }

    const dragItemFunc = (e,item,i) => {
        // console.log(e,item,i)
        setDragItem({id:i,item:item})
    }

    const dropItemFunc = (e,i) => {

        e.target.style.opacity = "1"

        let userAnswersV2 = [...userAnswers];

        let randListV2 = [...randList];

  
        randListV2 = randListV2.filter((item,i) => i !== dragItem.id)

        if(userAnswersV2[i].item !== undefined) {
            randListV2.push(userAnswersV2[i].item)
        }

        userAnswersV2[i] = dragItem;

        setUserAnswers(userAnswersV2);
        setRandList(randListV2);

    }

    return(<div style={{width:"50%"}}>
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title">Complete your food groups.</h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>
                {/* <GreenCircle style={{marginLeft:-30,marginTop:-30}} /> */}

        <div id="look_and_say" className="look_listen_say">

            <div style={{display:"flex"}}>
                
                <div style={{flex:1,display:"flex",justifyContent:"center"}}><Shape1 baseurl={baseurl} userAnswers={userAnswers} dropItemFunc={dropItemFunc} /></div>
                <div>
                    {randList.map((item,i) => {
                        return(<div style={{marginBottom:20}} draggable={true} onDrag={(e) => dragItemFunc(e,item,i)}>
                            <img style={{width:160,height:"auto",border:"6px solid #22CBF2",borderRadius:20}} src={baseurl + item.url} />
                            <div style={{color:"#fff",display:"flex",justifyContent:"center",alignItems:"center",width:"100%"}}><div style={{width:100,padding:"10px 0px",textAlign:"center",borderRadius:10,background:"rgb(34, 203, 242)",fontFamily:"gotham-medium"}}>{item.text}</div></div>
                        </div>)
                    })}
                </div>
            </div>
          

<audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

        </div>

             
    
        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>

                </div>


    </div>)
}
export default DragAndDrop;