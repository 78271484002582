import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Animate } from 'react-simple-animate';

const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]

function ListenAndChooseTheWord(props) {
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState("");
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [icon,setIcon] = useState("");
    const [answerFormat,setAnswerFormat] = useState({
        pass:true,
        progress:0,
        questionTitle:props.questions.title,
        userAnswers:[],
        type:"listen-and-repeat"
    });

    const [timeouts,setTimeOuts] = useState([]);

    const history = useHistory();

    const instr = useRef();

    useEffect(() => {
        
        setTimeOuts([
            ...timeouts,
            setTimeout(() => {
                document.getElementById("intr2").play();
    
    
            },500)
        ])
        



        let timeout = 3000;

        if(props.questions.timeout !== undefined) {
            timeout = props.questions.timeout;
        }

        setTimeOuts([
            ...timeouts,
            setTimeout(() => {
                document.getElementById("intr").play();
    
    
            },timeout)
        ])
        


       


    }, [data])
    useEffect(() => {
            let selected = props.questions;
        // console.log(props.questions)
            setIcon(props.questions.icon);
            let finalData = [];

            setCurrent(0);

            if(selected !== undefined) {
                selected.answers.forEach((item,i) => {
                    let p2 = JSON.parse(JSON.stringify(selected))
        
                    finalData.push(p2);
                })
        
                finalData.map((item,i) => {
                    item.answers[i].correct = true;
                    return item;
                })
            }    
           
            // selected = _.shuffle(selected);
            
            setData(finalData);

            if(finalData.length > 0) {
                finalData[0].complete = true;
                finalData[0].answers = _.shuffle(finalData[0].answers);
                let find = finalData[0].answers.findIndex((item) => item.correct === true);

                setAudio(finalData[0].answers[find].audio);

                setQuestion(finalData[0])



             
           
            } 
 
            return () => {
                setCurrent(0);
                setData([]);
                setQuestion({});
                timeouts.forEach((timeoutItem) => {
                    clearTimeout(timeoutItem);
                });
                let audios = document.getElementsByTagName("audio");
                if(document.getElementById("intr") !== null) {
                    document.getElementById("intr").pause();
                }
                if(document.getElementById("intr2") !== null) {
                    document.getElementById("intr2").pause();
                }
                let i = 0;
                while(i < audios.length) {
                    audios[i].pause();
                    i++;
                }
                
            }
                

    }, [props.questions])




    const checkQuestion = (e) => {
        console.log("subItem.answers","Hello");

        let counterNext = current;
       // let currentData = data;
        counterNext++;
        let allcorrect = true;

        let answerFormat2 = {...answerFormat};

        if(question.answers[userSelect].correct === true) {
           // props.playAudio(true);


           if(props.questions.hideText !== true) {
                question.answers[userSelect].lines.forEach((lineItem,i) => {
                    lineItem.text.forEach((subItem) => {
                        if(subItem.type === "input") {
                            subItem.userAnswer = subItem.userAnswer.replaceAll("’","'");
                            subItem.text = subItem.text.replaceAll("’","'");
                            if(subItem.answers !== undefined) {
                                // console.log("subItem.answers",subItem.answers,subItem.userAnswer);
                                let find = subItem.answers.findIndex((item) => item.toLowerCase() === subItem.userAnswer.toLowerCase())

                                if(find === -1) {
                                    allcorrect = false;
                                    answerFormat2.userAnswers.push({
                                        userAnswer:subItem.userAnswer.toLowerCase(),
                                        correctAnswer:subItem.answers[0],
                                        correct:false
                                    })

                                } else {
                                    answerFormat2.userAnswers.push({
                                        userAnswer:subItem.userAnswer.toLowerCase(),
                                        correctAnswer:subItem.userAnswer.toLowerCase(),
                                        correct:true
                                    })
                                }

                                
                            }
                            else if(subItem.userAnswer.toLowerCase() !== subItem.text.toLowerCase()) {
                                // console.log("subItem.answers",subItem.userAnswer.toLowerCase(),subItem.text.toLowerCase());

                                allcorrect = false;

                                answerFormat2.userAnswers.push({
                                    userAnswer:subItem.userAnswer.toLowerCase(),
                                    correctAnswer:subItem.text.toLowerCase(),
                                    correct:false
                                })

                               
                            } else {
                                answerFormat2.userAnswers.push({
                                    userAnswer:subItem.userAnswer.toLowerCase(),
                                    correctAnswer:subItem.text.toLowerCase(),
                                    correct:true
                                })
                            }


                            

                        }
                    })
                });
                                   
            
           } else {
                answerFormat2.userAnswers.push({
                    userAnswer:question.answers[userSelect].name,
                    correctAnswer:question.answers[userSelect].name,
                    correct:true
                })
           }




           setAnswerFormat(answerFormat2);
           
            setShowCorrect(true);
            if(allcorrect === false) {
                document.getElementById("wrong-au").play();
                answerFormat2.pass = false;
            } else {
                document.getElementById("correct-au").play();
            }
        } else {


            // console.log(question.answers[userSelect]);


            let findCorrect = question.answers.findIndex((item) => item.correct === true);
            
                answerFormat2.userAnswers.push({
                    userAnswer:question.answers[userSelect].name,
                    correctAnswer:question.answers[findCorrect].name,
                    correct:false
                })
            
          //  props.playAudio(false);

          setAnswerFormat(answerFormat2);
            setShowCorrect(true);
            document.getElementById("wrong-au").play();
            answerFormat2.pass = false;

        }
        setTimeOuts([
            ...timeouts,
            setTimeout(() => {

                if(counterNext < data.length) {
                    let selected =  data[counterNext];
                    selected.answers = _.shuffle(selected.answers);
                    let find = selected.answers.findIndex((item) => item.correct === true);
        
                    setQuestion(selected);
                    setAudio(selected.answers[find].audio);
                    setShowCorrect(false);
                    setUserSelect("");
                    setCurrent(counterNext);  
                    document.getElementById("intr").play();
    
    
                } else {
                    props.nextExercise(answerFormat2);
                  //  history.push(baseurl + "drag")
                }
            }, 3000)
        ]);
       



    }

    const playAudio = (e) => {
        if(document.getElementById("question-audio") !== null) {
            document.getElementById("question-audio").play();
        }
    }

    const checkUserSelect = (userSelect,indx) => {
        if(userSelect === indx) {
            return ("selected multiple-question-item");
        } else {
            return ("multiple-question-item");
        }
    }

    const checkCorrect = (userSelect,indx,correct) => {

        if(correct === true) {
            return("multiple-question-item selected correct")
        } else {
            if(userSelect === indx) {
                return ("selected wronganswer multiple-question-item");
            } else {
                return ("multiple-question-item");
            }
        }
        
    }

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const updateText = (value,indx,j,i) => {
        let question2 = {...question}
        question2.answers[indx].lines[j].text[i].userAnswer = value;

        setQuestion(question2);


    }


    return(<div style={{width:"70%"}}>
        

 

        <div className="question-title-container">
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                {/* <img src={baseurl + "assets/game5/listen.svg"} /> */}
                {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}


                <h1 className="question-title">{question.title}</h1>

            </div>

           
                    <div id="audio-bt" onClick={playIntr}>
                        <img src={baseurl + "assets/audio/listen.png"} />
                    </div>
                </div>

                {props.questions.image !== undefined && <div align={props.questions.textAlignImage} style={{marginBottom:"30px"}}>
                <img src={baseurl + props.questions.image} />
                </div>}
                
                {props.questions.options !== undefined && <div style={{background:"#B3EBF5",borderRadius:20,display:"flex",overflow:"hidden",alignItems:"center",margin:"5px 0px 30px",width:"100%"}}>
                    <div style={{display:"flex",boxSizing:"border-box",alignItems:"center",justifyContent:"center",padding:"20px 20px",flexWrap:"wrap",width:"100%"}}>{props.questions.options.map((optionItem) => {
                    return(<div style={{padding:"8px 20px",fontFamily:"gotham-medium",color:"#0B329D"}}>{optionItem}</div>);
                })}</div>
                                    {props.questions.sideImage !== undefined && <div><img style={{maxHeight:props.questions.maxHeight}} src={baseurl + props.questions.sideImage} /></div>}

                </div>}

        <div className="question-container">
            {question.answers !== undefined && 
                <div>
                <div className="multiple-question-data list-repeat">

                    
                    {question.audio !== "" && question.audio !== undefined && <audio style={{display:"none"}} id="question-audio" src={process.env.REACT_APP_BACKEND_LINK + "/" + question.audio} style={{display:"none"}} />}
                    {question.answers.map((item,indx) => {
                        
                        return (<div 
                            style={{width:props.questions.width}}
                            className={showCorrect === true?checkCorrect(userSelect,indx,item.correct):checkUserSelect(userSelect,indx)}
                        onClick={() => {
                            document.getElementById("clickbt").play();
                            setUserSelect(indx);}}>
                                <Animate play={true} easeType={"cubic-bezier(0.55, 0.06, 0.68, 0.19)"} start={{transform:"scale(0)",opacity:0}} end={{transform:"scale(1)",opacity:1}} duration={0.3} delay={indx * 0.2}>
                            <div className="multiple-question-img">
                                 {showCorrect === false && <img className={userSelect === indx?"answer-img selected":"answer-img"} src={baseurl + item.fileLink} />}
                                 {showCorrect === true && <img className={item.correct === true?"answer-img selected correct":userSelect === indx?"answer-img selected wronganswer":"answer-img"} src={baseurl + item.fileLink} />}
                                 {props.questions.hideText !== true &&
                                 <div className="multiple-question-title" style={{
                                         textAlign: "center",
                                         fontSize: 26
                                 }}>
                                     
                                     {/* <div style={{    minWidth: 145}} dangerouslySetInnerHTML={{ __html: item.name }}></div> */}
                                        <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
                                            {/* {console.log("item2222",item.lines)} */}
                                            {item.lines.map((lineItem,j) => <div style={{display:"flex",flexDirection:"row"}}>

                                                {lineItem.text.map((subItem,i) => {
                                                    return(<div>

                                                        {subItem.type === "input"?<><input spellCheck={false} size={subItem.text.length} value={subItem.userAnswer} onChange={(e) => updateText(e.target.value,indx,j,i)} type='text' style={{background:"none",border:"0",borderBottom:"1px dashed white"}} />&nbsp;</> :<div>{subItem.text}&nbsp;</div>}
                                                    </div>)
                                                })}
                                           
                                            
                                        </div>)}
                                        </div>
                                 </div>}
                            </div>
                            </Animate>
                        </div>)
                    })}
                </div>

                <audio src={`${baseurl}${audio}`} className="hidden-audio" id="intr" />

                <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />


                <div className="button-check">
                <button className="check-answer-bt" onClick={checkQuestion} disabled={userSelect === ""?true:false} variant="contained" color="primary">Check my answer</button>

                </div>

                </div> 
            }
                            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr2" />

        </div>


    </div>)
}

export default ListenAndChooseTheWord;