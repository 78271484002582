import react,{useEffect, useRef,useState } from 'react'
import CurvedArrow from "react-curved-arrow";
import DrawLine from "./DrawLine";
import _ from 'lodash'
import GreenCircleV2 from './../svgs/greenCircle_v4'

function MatchingDemo(props) {

    const [items, setItems] = useState([]);
    const [items2, setItems2] = useState([]);

    const [finalLines, setFinalLines] = useState([]);


    const baseurl = process.env.REACT_APP_FRONT_LINK;

    const [p1, setP1] = useState({
      x: 0, y: 0
    })

    const [p2, setP2] = useState({ x: 700, y: 700 })
    
    const [showLine, setshowLine] = useState(false)
    const [startIndex, setStartIndex] = useState("")
    const [endIndex, setEndIndex] = useState("")

    const [itemTopWidth, setItemTopWidth] = useState({})

    useEffect(() => {

      if(props.questions.itemTopWidth !== undefined) {
        setItemTopWidth(props.questions.itemTopWidth);
      }

      // console.log(props.questions)
      let finalLines1 = [];
      props.questions.toItem.forEach((item) => {
        finalLines1.push({})
      })
      setFinalLines([...finalLines1])
      setItems(props.questions.toItem)
      setItems2(_.shuffle(props.questions.fromItem))

      document.getElementById("intr").play();

      // let current_lines = [];
      // let intial = {
      //     x1:0,
      //     y1:0,
      //     x2:50,
      //     y2:50,
      //     show:true
      // }

      // items.forEach((item) => {
        
      //   current_lines.push(intial)
      // });

      // setLines(current_lines)
    }, [props])


    const mousemove = (e) => {
      setP2({x:e.clientX,y:e.clientY + window.scrollY})
    }

    const mouseup = (e) => {
      if(e.target.id.includes("end-")) {

        let startIndex2 = parseInt(localStorage.getItem("startIndex"));
        
         let i = e.target.id;
         i = i.replace("end-","");
         i = parseInt(i); 


         setEndIndex(i);
      let finalLines2 = finalLines;


      let findTo = finalLines2.findIndex((item) => item.to === "to" + i);

      let findFrom = finalLines2.findIndex((item) => item.from === "from" + startIndex2);

      if(findTo !== -1) {
        finalLines2[findTo] = ""
      }
      if(findFrom !== -1) {
        finalLines2[findFrom] = ""
      }
      
      if(finalLines2.length > 0) {
        finalLines2[startIndex2] = {}
        finalLines2[startIndex2].from = "from" + startIndex2;
        finalLines2[startIndex2].to = "to" + i;
      

      }

      let items_1 = [...items];
      let items_2 = [...items2]

      if(items_2.length > 0 && items_1.length > 0 && finalLines2.length > 0) {
        items_1[i].userAnswer = items_2[startIndex2].text;
      

        setItems(items_1);
        
        setFinalLines(finalLines2);
      }

      }
      document.getElementsByTagName("body")[0].style.overflowY = "scroll";


      setshowLine(false);

    }

    const mousedown = (e) => {
      if(e.target.id.includes("start-")) {
        let diemenions = e.target.getBoundingClientRect();
        console.log(window.scrollY,diemenions.y)
        setP1({x:diemenions.x + (diemenions.width / 2),y:diemenions.y + window.scrollY})
        setP2({x:diemenions.x + (diemenions.width / 2),y:diemenions.y + window.scrollY})
  
         document.getElementsByTagName("body")[0].style.overflow = "hidden";
        setshowLine(true);
        // let i = parseInt(e.target.id.replace("start-",""));

        let i = e.target.id;
        i = i.replace("start-","");
        i = parseInt(i); 

        localStorage.setItem("startIndex",i);


        // setStartIndex(i);
      }
    }


    useEffect(() => {
      window.addEventListener("mousemove",mousemove);

        window.addEventListener("mouseup",mouseup);
        window.addEventListener("mousedown",mousedown);
        document.getElementById("game-container").style.paddingBottom = "0px";
      return () => {
        window.removeEventListener("mousedown",mousedown);
        window.removeEventListener("mouseup",mouseup);
        window.removeEventListener("mousemove",mousemove);
        document.getElementById("game-container").style.paddingBottom = "50px";

      }
    }, [finalLines])

    const itemStyle2 = {color:"#000",flexDirection:"column",width:"10vw",height:100,margin:20,display:"flex",justifyContent:"center",alignItems:"center",
    position:"relative",zIndex:111111
  };

  const checkQuestion = (e) => {
    let allcorrect = true;
    let items_2 = [...items];
    let finalLines2 = [...finalLines];
    let items_22 = [...items2];

    let answerFormat = {
      pass:true,
      progress:0,
      questionTitle:props.questions.title,
      userAnswers:[],
      type:"match_pairs"
  }




    items_2.forEach((item,i) => {

      let findIndex = items_22.findIndex((item2) => item2.text === item.userAnswer);

      if(item.userAnswer !== item.correct) {
        allcorrect = false;
        items_2[i].correctAnswer = false;

        if(findIndex !== -1) {
          finalLines2[findIndex].correctAnswer = false;
          answerFormat.pass = false;
        }
      //  
      } else {
        items_2[i].correctAnswer = true;

        if(findIndex !== -1) {
          finalLines2[findIndex].correctAnswer = true;
        }

        //finalLines2[i].correctAnswer = true;
      }

      answerFormat.userAnswers.push({
        userAnswer:item.userAnswer,
        correctAnswer:item.correct,
        correct:finalLines2[findIndex].correctAnswer
      });
    })

    setFinalLines(finalLines2)
    setItems(items_2);

    if(allcorrect === true) {
      document.getElementById("correct-au").play();

  } else {
      document.getElementById("wrong-au").play();

  }
  setTimeout(() => {
    props.nextExercise(answerFormat);
}, 3000);



  }
    const startLine = (e,i) => {

      // let diemenions = e.target.getBoundingClientRect();
      // setP1({x:diemenions.x + (diemenions.width / 2),y:diemenions.y})
      // setP2({x:diemenions.x + (diemenions.width / 2),y:diemenions.y})

      // document.getElementsByTagName("body")[0].style.overflow = "hidden";
      // setshowLine(true);
      // setStartIndex(i);
    }
    const endLine = (e,i) => {
 

      

    }
    return (
        <>
        <div style={{width:"70%"}}>
                <div className="question-title-container">
                    <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                    <img src={baseurl + "assets/game5/listen.svg"} />

                    
                    <h1 className="question-title">{props.questions.title}</h1>

                  
                    </div>
                    
                    <div>
                        <div id="audio-bt" onClick={() => {
                                document.getElementById("intr").play();

                        }}><img src={baseurl + "assets/audio/listen.png"} /></div>
                    </div>

                </div>

        <div>
          <div>
          {props.questions.topImage !== undefined &&
              <img style={props.questions.topImageWidth !== undefined?{width:props.questions.topImageWidth,marginBottom:30}:{width:"30%",marginBottom:30}} src={baseurl + props.questions.topImage} />
          }
            {props.questions.hideGreen !== undefined &&
            
            <GreenCircleV2 width={200} height={"auto"} style={{marginTop:-20}} title={
              <>

{props.questions.mainTxt !== undefined && <>
  {props.questions.mainTxt.map((item) => {
    return(
      <tspan x={item.x} y={item.y} fontSize={item.fontSize}>
      {item.text} 
      </tspan>
    

    )
  })}  
  </>} 
              
            </>} />
            }

          </div>
        <div style={{display:"flex"}}>
          {items.map((item,i) => {   

            let width = 92;
            if(props.questions.width === "auto") {
              width = "auto";
              itemStyle2.flex = 1;
            }

            if(props.questions.itemTopWidth !== undefined) {
              width = props.questions.itemTopWidth;
            }
              return(<div onClick={(e) => {endLine(e,i)}} className={'to' + i} style={{...itemStyle2,width:width}}>
                <img id={"end-" + i} style={item.correctAnswer === undefined?{border:"5px solid #22CBF2",borderRadius:20}:item.correctAnswer === true?{border:"5px solid #45EA98",borderRadius:20}:{border:"5px solid #F84E53",borderRadius:20}}
              
              src={baseurl + item.fileLink} />
                            {props.questions.hidetext === undefined && <div className='itemtext' style={{fontFamily:"gotham-medium",background:"#22CBF2",padding:"5px 20px",color:"#fff",borderRadius:10,marginTop:-10}}>{item.text}</div>}
                            {props.questions.inputText === true && <div className='itemtext' style={{fontFamily:"gotham-medium",background:"#22CBF2",padding:"5px 20px",color:"#fff",borderRadius:10,marginTop:-10}}><input type='text' style={{background:"none",border:0,width:"100%",borderBottom:"1px dashed #fff",fontFamily:"gotham-medium",color:"#fff",textAlign:"center",fontSize:20}} /></div>}
              </div>)})}
        </div>


        
        <div style={props.questions.toType === "text"?{display:"flex",marginTop:200,flexWrap:"wrap"}:{display:"flex",marginTop:200}}>
          {items2.map((item,i) => {
            let textStyle = {};
            if(item.type === "text") {
              textStyle = {width:92,height:80,marginTop:0,marginBottom:0,alignContent:"flex-start"}
            }
            return(
              <div 
              onClick={(e) => { startLine(e,i); }}
              className={'from' + i} style={{...itemStyle2,...textStyle}}>
                {/* <img id={"start-" + i} className='nodrag' style={{border:"5px solid #22CBF2",borderRadius:20}} src={baseurl + item.fileLink} /> */}
                     {item.type === "text"?
                     
                     
                     <div id={"start-" + i} style={{width:"90%",margin:"0px 20px 0px",height:"50px",display:"flex",textAlign:"center",justifyContent:"center",alignItems:"center",fontFamily:"gotham-medium",background:"#22CBF2",padding:"5px 20px",color:"#fff",borderRadius:10}}>{item.text}</div>:
                     <img id={"start-" + i} className='nodrag' style={{border:"5px solid #22CBF2",borderRadius:20}} src={baseurl + item.fileLink} />}               
                                          {props.questions.hidetext === undefined && <div className='itemtext' style={{fontFamily:"gotham-medium",background:"#22CBF2",padding:"5px 20px",color:"#fff",borderRadius:10,marginTop:-10}}>{item.text}</div>}
                                          {props.questions.hidetext2 === undefined && <div className='itemtext' style={{fontFamily:"gotham-medium",background:"#22CBF2",padding:"5px 20px",color:"#fff",borderRadius:10,marginTop:-10}}>{item.text}</div>}

              </div> 
          )})}
        </div>
        {/* <CurvedArrow fromSelector=".to0" toSelector=".from2" middleY={-50} toOffsetY={-60} fromOffsetY={60} width={4} />
        <CurvedArrow fromSelector=".to1" toSelector=".from1" middleY={-50} toOffsetY={-60} fromOffsetY={60} width={4} /> */}
        {finalLines.map((item,i) => {
          
          return(<>
            {item.from !== undefined ?<CurvedArrow fromSelector={`.${item.from}`} color={item.correctAnswer === undefined?"#B7BCD1":item.correctAnswer === true?"#45EA98":"#F84E53"} toSelector={`.${item.to}`} middleY={-50} toOffsetY={0} fromOffsetY={props.questions.toOffsetY !== undefined?props.questions.toOffsetY:75} width={4} />:""}
          </>)
        })}

        </div>
        {showLine === true && <DrawLine p1={p1} p2={p2} setP2={setP2} />}


        <div className="button-check" style={{marginTop:10}}>
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>
            </div>
            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />


        {/* <div style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}}>

          
            
                           <svg
              width="100%"
              height="100%"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d={`M ${sx} ${sy} C ${c1x} ${c1y}, ${c2x} ${c2y}, ${ex} ${ey}`}
                stroke={color}
                strokeWidth={arrowHeadSize / 2}
                fill="none"
              />
              <polygon
                points={`0,${-arrowHeadSize} ${arrowHeadSize *
                  2},0, 0,${arrowHeadSize}`}
                transform={`translate(${ex}, ${ey}) rotate(${ae})`}
                fill={color}
              />
            </svg> 

            );


        </div> */}


</div>
        </>
    )
  
}
export default MatchingDemo;