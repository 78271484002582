import React,{useState,useEffect} from 'react'
import Header from './../components/header';
import {Link,useHistory} from 'react-router-dom'
import WhiteLayer from "./../components/levelLayer";
import {useSelector} from 'react-redux';
import HeaderV2 from './../components/headerV2';

const baseurl = process.env.REACT_APP_FRONT_LINK;

function Home(props) {
    const [levels,setLevels] = useState([1,2,3,4,5,6])
    const user = useSelector((state) => state.user);
    const history = useHistory();

    useEffect(() => {
        
        document.getElementsByTagName("body")[0].style.background = "#22CCF2";
   
       }, [])
    useEffect(() => {
        let list = document.getElementsByClassName("level-item");
        document.getElementById("avatar").style.opacity = "1";
        document.getElementById("avatar").style.transform = "translate(0px,0px)";

        for(let i = 0; i<list.length;i++) {
            setTimeout(() => {
                list[i].style.opacity = "1"; 
                list[i].style.transform = "scale(1)"; 

            }, i * 300);
        }
    }, [])

    const selectLevel = (i) => {
        document.getElementById("click-bt").play();

        setTimeout(() => {
            history.push(baseurl + "L" + (i + 1) + "/U1#1")
        }, 500); 
    }

    const redirectLink = (link) => {
        document.getElementById("click-bt").play();
        setTimeout(() => {
            history.push(link)
        }, 500); 
    }

    return(
    <>
    <div id="paints"><HeaderV2 /></div>                    

    <div id="mainapp" style={{color:"#fff"}} >
        <Header />
        <div id="white-layer"><WhiteLayer /></div>

        <div className="container">
            <div id="level-container">
                <div id="avatar"><img src={`${baseurl}assets/avatars/big/profile${user.avatar}.png`} /></div>
                <div id="levels-list">
                    {levels.map((item,i) => {
                        
                        if(i === 0) {
                            return(<div className="level-item" onClick={(e) => {selectLevel(i)}} to={baseurl + "video"}>
                            
                                <div className="level-number">{item}</div>
                                <div className="level-txt">LEVEL</div>
                            </div>)
                        } else {
                            return(<div className="level-item" onClick={() => {redirectLink(`${baseurl}exersises/level${i}/unit1`)}} >
                            
                                <div className="level-number">{item}</div>
                                <div className="level-txt">LEVEL</div>
                            </div>)
                        }
                        
                    })}
                </div>
            </div>
            

        </div>
        <audio src={baseurl + "assets/audio/click.mp3"} id="click-bt" style={{display:"none"}} />

        
    </div>
    </>
    )
}
export default Home;