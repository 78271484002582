import * as React from "react"

function Stars(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={92.642}
      height={97.015}
      viewBox="0 0 92.642 97.015"
      {...props}
    >
      <g data-name="Group 4026">
        <g data-name="Group 4021">
          <path
            data-name="Path 2522"
            d="M41.181 8.997a6.488 6.488 0 0110.249 0l13.525 17.416a6.488 6.488 0 002.784 2.072l24.45 6.391s1.428 7.651-.787 11.106L79.209 65.007a6.488 6.488 0 00-1.019 3.214l-1.001 22.592a6.488 6.488 0 01-8.249 5.956l-20.866-5.91a6.488 6.488 0 00-3.535 0l-20.866 5.907a6.488 6.488 0 01-8.25-5.953l-1-22.589a6.488 6.488 0 00-1.019-3.214L1.21 45.983C-1.004 42.528.497 35.945.497 35.945l24.376-7.459a6.488 6.488 0 002.785-2.073z"
            fill="#f4ad32"
          />
          <path
            data-name="Path 2521"
            d="M41.182 2.509a6.488 6.488 0 0110.249 0l13.524 17.416a6.488 6.488 0 002.784 2.072l20.541 7.944a6.488 6.488 0 013.123 9.554L79.21 58.519a6.488 6.488 0 00-1.019 3.214L77.19 84.325a6.488 6.488 0 01-8.249 5.956l-20.867-5.91a6.488 6.488 0 00-3.535 0l-20.866 5.907a6.488 6.488 0 01-8.249-5.953l-1-22.589a6.488 6.488 0 00-1.019-3.214L1.21 39.495a6.488 6.488 0 013.123-9.553l20.541-7.944a6.488 6.488 0 002.789-2.073z"
            fill="#f9cb2e"
          />
        </g>
      </g>
    </svg>
  )
}

export default Stars
