import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect
} from "react-router-dom";
import Home from './pages/home';
import Login from './pages/login';
import Header from './components/header';
import Levels from './pages/levels';
import Game from './pages/game';
import Video from './pages/video';
import Drag from './pages/Drag';
import ChooseAndHear from './pages/ChooseAndHear';
import Read from './pages/read';
import LookAndWrite from './pages/LookAndWrite';
import LookAndReadAndWrite from './pages/LookAndReadAndWrite';

import ReadTheNames from './pages/readTheNames';
import ReadAndMatch from './pages/ReadAndMatch';
import GoodJob from './pages/goodjob';
import Meetcharacters from './pages/MeetTheCharaters'
import Sorting from './pages/sorting'
import Complete from './pages/Complete'
import WatchVideo from './pages/WatchVideo'
import LookAndSay from './pages/LookAndSay'

import ListenReadAndSay from './pages/ListenReadAndSay'
import LookSay from './pages/LookSay'
import TalkToFriend from './pages/TalkToFriend'
import ListenAndRead from './pages/ListenAndRead';
import CircleHear from './pages/CircleHear';
import LookNotice from './pages/LookAndNotice';
import LookListenSay from './pages/LookListenSay';
import ReadNames from './pages/ReadNames';
import SplitText from './pages/SplitText'
import CompineText from './pages/CompineText';
import SpeechText from './pages/SpeechText';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import VideoCode from './pages/videoCode';

import ExerciseContainer from './pages/ExerciseContainer';

import "./css/style.scss";
import { useEffect, useState } from "react";

const baseurl = process.env.REACT_APP_FRONT_LINK;
const backendurl = process.env.REACT_APP_BACKEND_URL;

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [direct, setDirect] = useState(false);

  useEffect(() => {
    let userId = localStorage.getItem("userId");


    axios({
      method: "get",
      url: `${backendurl}/user/get?id=${userId}`
    }).then((res) => {
      if (res.data !== null) {
        dispatch({ type: "update_user", user: res.data })
      } else {
        setDirect(true);
      }
    });
  }, []);
  return (
    <div className="app">

      <Router>
        <Switch>


          {/* <Route path={baseurl} exact><Home /></Route>
          <Route path={baseurl + "login"} exact><Login /></Route> */}

          <Route path={baseurl} exact><Login /></Route>
          <Route path={baseurl} exact><Home /></Route>


          <Route path={baseurl + "levels"} exact><Levels /></Route>

          <Route path={baseurl + "splitText"} exact><SplitText /></Route>
          <Route path={baseurl + "compineText"} exact><CompineText /></Route>
          <Route path={baseurl + "speechText"} exact><SpeechText /></Route>


          {/* <Route path={baseurl + "game"} exact><Game /></Route>
            <Route path={baseurl + "read"} exact><Read /></Route> */}
          <Route path={baseurl + "video"} exact><Video /></Route>
          <Route path={baseurl + "WatchVideo"} exact><WatchVideo /></Route>
          {/* <Route path={baseurl + "LookAndSay"} exact><LookAndSay /></Route> */}

          {/* <Route path={baseurl + "ListenReadAndSay"} exact><ListenReadAndSay /></Route>
            <Route path={baseurl + "drag"} exact><Drag /></Route>
            <Route path={baseurl + "choose_and_hear"} exact><ChooseAndHear /></Route>
            <Route path={baseurl + "look_write"} exact><LookAndWrite /></Route>
           
           
           
            <Route path={baseurl + "read_match"} exact><ReadAndMatch /></Route>
            <Route path={baseurl + "meet_characters"} exact><Meetcharacters /></Route>
            <Route path={baseurl + "sorting"} exact><Sorting /></Route>
            <Route path={baseurl + "complete"} exact><Complete /></Route>
            <Route path={baseurl + "look_say"} exact><LookSay /></Route>
            <Route path={baseurl + "look_read_write"} exact><LookAndReadAndWrite /></Route>
            <Route path={baseurl + "talk_to_friend"} exact><TalkToFriend /></Route>
            <Route path={baseurl + "listen_read"} exact><ListenAndRead /></Route>
            <Route path={baseurl + "circle_hear"} exact><CircleHear /></Route>
            <Route path={baseurl + "look_notice"} exact><LookNotice /></Route>
            <Route path={baseurl + "look_listen_say"} exact><LookListenSay /></Route>
            <Route path={baseurl + "read_names"} exact><ReadNames /></Route>

 */}
          <Route path={baseurl + "exercise/:level/:unit"} exact><ExerciseContainer /></Route>
          <Route path={baseurl + ":level/:unit"} exact><ExerciseContainer /></Route>

          <Route path={baseurl + "video_code"} exact><VideoCode /></Route>

          {/* <Route path={baseurl + "goodjob"} exact><GoodJob /></Route>  */}

          {/* <Route path={baseurl + "read_names"} exact><ReadTheNames /></Route> */}

        </Switch>
      </Router>
      <audio id="clickbt" src={baseurl + "assets/audio/click.mp3"} style={{ display: "none" }} />
    </div>
  );
}

export default App;
