import React,{useState} from 'react'
// import ListenAndChooseTheWord from './../engines/ListenAndChooseTheWord';
import VerticalMenu from './../components/VerticalMenu';
import { useEffect } from 'react';
import SearchIcon from './../svgs/SearchIcon'
import { useHistory } from "react-router-dom";

import HeaderTop from './../components/headerTop';


const baseurl = process.env.REACT_APP_FRONT_LINK;

const quizsection = {
    subtitle:"Listen and point"
}
const questions = [{
    "_id":"question_82",
    "type":"listen_choose",
    "name":"Jordan",
    "answers":
    [{"name":"color","correct":true,"fileId":"file_26","fileLink":"attach/1625400172579.png"},
    {"name":"draw","correct":false,"fileId":"file_27","fileLink":"attach/1625400187584.png"},
    {"name":"raise your hand","correct":false,"fileId":"file_28","fileLink":"attach/1625400198207.png"},
    {"name":"read","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"},
    {"name":"listen","correct":false,"fileId":"file_29","fileLink":"attach/1625400217646.png"}

], 
    "stageId":"stage_40",
    "catId":"category_1",
    "unitId":"unit_20",
    "quizId":"quizsection_2",
    "created":1625392118,
    "createdTime":"2021-07-04T09:48:38.383Z",
    "audio":"attach/1625394010949.mp3",
    "updated":1625400721,
    "updatedTime":"2021-07-04T12:12:01.076Z"}
]
function Video(props) {

    const history = useHistory();
    const [icon,setIcon] = useState("");
    const [levels,setLevels] = useState([1,2,3,4,5,6])
    
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    const checkQuestion = (e) => {
        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"look-and-say"
        }

        props.nextExercise(answerFormat);
    }

    useEffect(() => {
        document.getElementById("intr").play();
     document.getElementsByTagName("body")[0].style.background = "#EAF6FC";
     setIcon(props.questions.icon);


    }, [])

    const playAudio = () => {
                document.getElementById("intr").play();

    }
    
    return(<div id="mainapp" className="gameapp" style={{color:"#fff"}} >
            <div>
                <div id="mainquestion">

                    <div className="question-title-container" style={{width:"52vw"}}>
                        
        <div style={{display:"flex"}}>
            
        {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}
        <h1 className="question-title" style={{paddingLeft:15}} dangerouslySetInnerHTML={{__html:props.questions.title}}></h1>
            </div>

        <div id="audio-bt" onClick={playAudio}><img src={baseurl + "assets/audio/listen.png"} /></div>
             
        </div>
                    <div className="img-intro" style={{height:"50vh"}}>
                        <img src={baseurl + props.questions.img} style={{height:"100%"}} />
                    </div>

                    <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>
            </div>
                </div>

            </div>
            {props.questions.newaudio !== undefined?<audio id="intr" src={baseurl + props.questions.audio} />:
            <audio id="intr" src={baseurl + "assets/levels/level1/unit1/assets/audios/look_and_say_who_do_you_see.mp3"} />}
            


        
    </div>)
}
export default Video;