import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import GreenCircle from './../svgs/greenCircle'
import BlueArrow from './../svgs/blue'

const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);


    const [icon,setIcon] = useState("");


    const [items,setItems] = useState([
        // {
        //     id:"1",
        //     word:"I am Muzzy.",
        //     word2:"I'm Muzzy.",
        //     fileLink1:baseurl + "assets/tracks/t07/iam_muzzy.mp3",
        //     fileLink2:baseurl + "assets/tracks/t07/im_muzzy.mp3"
        // },
        // {
        //     id:"2",
        //     word:"I am Sylvia.",
        //     word2:"I'm Sylvia.",
        //     fileLink1:baseurl + "assets/tracks/t07/iam_selvia.mp3",
        //     fileLink2:baseurl + "assets/tracks/t07/im_selvia.mp3"
        // },
        // {
        //     id:"2",
        //     word:"I am Bob.",
        //     word2:"I'm Bob.",
        //     fileLink1:"",
        //     fileLink2:""
        // }

        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i) => {
        let userSelect2 = [...userSelect];
        userSelect2[i] = value;
        setUserSelect(userSelect2);

    }

    const checkQuestion = () => {

//        setTimeout(() => {
           // history.push(baseurl + "choose_and_hear")
        // }, 3000);

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:"Look and say.",
            userAnswers:[],
            type:"look-and-say"
        }
        console.log("answerFormat",answerFormat);

        props.nextExercise(answerFormat);
    }


    useEffect(() => {
        setIcon(props.questions.icon)
        
        setItems(props.questions.answers)
        let currentItems = props.questions.answers;

        // currentItems = shuffle(currentItems);

        setTimeout(() => {
            document.getElementById("intr").play();
        },300)

        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    
    const playAudio = (i) => {

        let audios = document.getElementsByTagName("audio");
        for(let i =0 ;i<audios.length;i++) {
            audios[i].pause();
            audios[i].currentTime = 0;
        }

        document.getElementById("filelink1_" + i).play();
        document.getElementById("filelink1_" + i).play();
    }

    const playAudio2 = (i) => {

        let audios = document.getElementsByTagName("audio");
        for(let i =0 ;i<audios.length;i++) {
            audios[i].pause();
            audios[i].currentTime = 0;
        }
        document.getElementById("filelink2_" + i).play();
        document.getElementById("filelink2_" + i).play();

    }

    return(<div style={{width:"50%"}}>
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>

                {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/write.svg"} />}


                    <h1 className="question-title">Look and say.</h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>
                {/* <GreenCircle style={{marginLeft:-30,marginTop:-30}} /> */}

        <div id="look_and_say">
            {randList.map((item,j) => {
                return(
                <div className="item-v1">
                    <BlueArrow width={"100%"} height={"auto"} />
                    <div className="look_words">
                        <div className="word1" onClick={() => playAudio(j)} dangerouslySetInnerHTML={{__html:item.word}}></div>
                        <div className="word2" onClick={() => playAudio2(j)} dangerouslySetInnerHTML={{__html:item.word2}}></div>
                        <audio id={"filelink1_" + j} src={baseurl + item.fileLink1}  />
                        <audio id={"filelink2_" + j} src={baseurl + item.fileLink2}  />


                    </div>
                   
                </div>)
            })}

{/* <audio src={`${baseurl}assets/muzzy/audio/look_write.mp3`} className="hidden-audio" id="intr" /> */}
<audio src={baseurl + "assets/levels/level1/unit1/assets/audios/look_and_say.mp3"} className="hidden-audio" id="intr" />

<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

        </div>

             

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>

                </div>


    </div>)
}
export default DragAndDrop;