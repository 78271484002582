import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import GreenCircle from './../svgs/greenCircle'
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);
    const [answerFormat,setAnswerFormat] = useState({
        pass:true,
        progress:0,
        questionTitle:"Listen and point.",
        userAnswers:[],
        type:"listen-stick-write"
    });

    const [items,setItems] = useState([
        {
            id:"2",
            word:"Muzzy",
            img:baseurl +"assets/game2/item2.png",
            words:[
                {
                    text:""
                },
                {
                    text:"uzzy"
                }
            ]
        },
        {
            id:"5",
            word:"Sylvia",
            img:baseurl +"assets/game2/item5.png",
            words:[
                {
                    text:""
                },
                {
                    text:"ylvia"
                }
            ]
        },
        {
            id:"1",
            word:"bob",
            img:baseurl +"assets/game2/item1.png",
            words:[
                {
                    text:""
                },
                {
                    text:"ob"
                }
            ]
        },

        {
            id:"3",
            word:"Corvax",
            img:baseurl +"assets/game2/item3.png",
            words:[
                {
                    text:""
                },
                {
                    text:"orvax"
                }
            ]
        }
        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i) => {
        let userSelect2 = [...userSelect];
        userSelect2[i] = value;
        setUserSelect(userSelect2);

    }

    const checkQuestion = () => {
        let answerFormat2 = {...answerFormat};
        let items2 = [...randList];
        let allcorrect = true;
        // console.log(userSelect)
        items2.map((subItem,i) => {
            let currentword = "";

            subItem.words.forEach((subItem2) => {

                if(subItem2.text === "") {
                    currentword += userSelect[i];
                } else {
                    currentword += subItem2.text;
                }
            })
            currentword = currentword.trim();
            let secondword = subItem.word.toLowerCase();

            if(currentword.toLowerCase() === secondword.toLowerCase()) {
                subItem.correct = true;
            } else {
                subItem.correct = false;
                allcorrect = false;
            }

            answerFormat2.userAnswers.push({
                userAnswer:currentword.toLowerCase(),
                correctAnswer:secondword.toLowerCase(),
                correct:subItem.correct
            });

            return subItem;
        })
        if(allcorrect === true) {
            document.getElementById("correct-au").play();
        } else {
            document.getElementById("wrong-au").play();
        }
        answerFormat2.pass = allcorrect;
        setRandList(items2);
        setCheckAnswers(true);

        setTimeout(() => {
          //  history.push(baseurl + "look_notice")
          props.nextExercise(answerFormat2);
          window.location.hash = "#19";
        }, 3000);
        
    }


    useEffect(() => {
        let currentItems = [...items];

        // currentItems = shuffle(currentItems);

        setTimeout(() => {
            document.getElementById("intr").play();
        },300)

        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    return(<div id="look_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title">Look and write.</h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>
                {/* <GreenCircle style={{marginLeft:-30,marginTop:-30}} /> */}

        <div id="look_write-items-v1">
            {randList.map((item,j) => {
                return(
                <div className="look_write-item-v1">

                    {checkAnswers === false ?<img src={item.img} />:item.correct === false ?<img style={{border:"5px solid #F84E53"}} src={item.img} />:<img style={{border:"5px solid #83cd83"}} src={item.img} />}
                    {/* {item.correct === undefined ?<img style={{border:"8px solid #F84E53"}} src={item.img} />:
                    item.correct === true?<img style={{border:"8px solid #83cd83"}} src={item.img} />:<img src={item.img} />
                    } */}
                    
                    <div className="word-v1">
                        {item.words.map((subWord,i) => {


                            if(subWord.text === "") {
                                return(<div className={"editable-input"} ><input spellCheck="false"  maxLength={1} style={{borderBottom:"2px solid #B7BCD1",textTransform:"capitalize"}} type="text" onChange={(e) => {changeTxt(e.target.value,j)}} /></div>)
                            } else {
                                return(<div className={subWord.text === ""?"editable":"readable"} contentEditable={subWord.text === ""?true:false}>{subWord.text === ""?userSelect[i]:subWord.text}</div>)
                            }

                        })}
{/* <audio src={`${baseurl}assets/muzzy/audio/look_write.mp3`} className="hidden-audio" id="intr" /> */}
<audio src={`${baseurl}assets/levels/level1/unit1/assets/audios/look_write.mp3`} className="hidden-audio" id="intr" />

{/* <audio src={`${baseurl}assets/muzzy/audio/look_write.mp3`} className="hidden-audio" id="intr" /> */}
<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                    </div>
                </div>)
            })}
        </div>

             

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>

                </div>


    </div>)
}
export default DragAndDrop;