import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={386.976}
      height={85}
      viewBox="0 0 386 85"
      {...props}
    >
      <path
        data-name="Path 2501"
        d="M376.092 61.74a12.081 12.081 0 006.824 10.884 6.453 6.453 0 013.56 5.836v3.486a3.355 3.355 0 01-3.355 3.355H3.855A3.355 3.355 0 01.5 81.946V52.54a6.469 6.469 0 014.157-6.107 12.094 12.094 0 000-22.71A6.469 6.469 0 01.5 17.616V3.855A3.355 3.355 0 013.855.5h331.094a6.467 6.467 0 015.963 3.823 12.086 12.086 0 0022.186 0A6.467 6.467 0 01369.061.5h14.06a3.355 3.355 0 013.355 3.355V45.02a6.452 6.452 0 01-3.558 5.835 12.086 12.086 0 00-6.826 10.885z"
        fill="#22cbf2"
        stroke="#22cbf2"
        strokeMiterlimit={10}
        opacity={0.4}
      />
    </svg>
  )
}

export default SvgComponent
