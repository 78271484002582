import React,{useState} from 'react'
// import ListenAndChooseTheWord from './../engines/ListenAndChooseTheWord';
import VerticalMenu from './../components/VerticalMenu';
import { useEffect } from 'react';
import SearchIcon from './../svgs/SearchIcon'
import { useHistory } from "react-router-dom";

import Image from "react-image-enlarger";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import Player from '@vimeo/player';
import ReactHlsPlayer from 'react-hls-player';


const baseurl = process.env.REACT_APP_FRONT_LINK;
 


function Video(props) {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const {options, onReady} = props;
  
    const history = useHistory();
    const handlePlayerReady = (player) => {
        playerRef.current = player;
    
        // You can handle player events here, for example:
        player.on('waiting', () => {
          videojs.log('player is waiting');
        });
    
        player.on('dispose', () => {
          videojs.log('player will dispose');
        });
      };
    

const [icon,setIcon] = useState("");

const [zoomed, setZoomed] = React.useState(false);
const [zoomed2, setZoomed2] = React.useState(false);
const [video, setVideo] = React.useState("");
const [videoLink, setVideoLink] = React.useState("");

const [videData, setVideoData] = React.useState("");
const [videoLoaded,setVideoLoaded] = React.useState(false)
    
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    const checkQuestion = (e) => {
        //history.push(baseurl + "read_match")

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"watch_video"
        }

        props.nextExercise(answerFormat);
    }
    const playAudio = () => {
        document.getElementById("intr").play();

    }

    useEffect(() => {

        // const handstickPlayer = new Player('handstick');
        // handstickPlayer.on('play', function() {
        //     console.log('played the handstick video!');
        // });
    
        // const playerTwoPlayer = new Player('playertwo');
        // playerTwoPlayer.on('play', function() {
        //     console.log('played the player 2.0 video!');
        // });
    
        setIcon(props.questions.icon);

        if(props.questions.videoCode !== undefined) {

            fetch("https://api.vimeo.com/videos/" + props.questions.videoCode,{
                method: "GET",
                headers: {
                    "Authorization": "Bearer aeeec5987db81bbfd76e0f272e35468c", 
                }
            }).then(response => response.json()).then(data => {
                // console.log(data);
                // setVideoData(data.embed.html)
                // console.log("video212",{
                //     autoplay: true,
                //     controls: true,
                //     responsive: true,
                //     fluid: true,
                //     sources: [{
                //       src: data.files[0].link,
                //       type: 'video/mp4'
                //     }]
                // })

                
                if(data.files !== undefined) {
                    let files = data.files;
                    let findHls = files.findIndex((item) => item.quality === "hls");

                    if(findHls !== -1) {
                        setVideoLink(data.files[findHls].link_secure);
                    }
                }
            
            });

        } else {
            setVideo(baseurl + props.questions.video);
        }
    
        
     document.getElementsByTagName("body")[0].style.background = "#EAF6FC";

    }, [])

    useEffect(() => {

        if(icon !== "") {
            console.log("icon")
            setTimeout(() => {
                document.getElementById("intr").play();
            }, 500);
        }

    
    },[icon])
    return(
    
            
             <div id="mainquestion">

                    <div className="question-title-container" style={{width:"52vw"}}>
                        <div style={{display:"flex",alignItems:"center"}}>

                        {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}
                        <h1 className="question-title" style={{marginLeft:15}}>{props.questions.title}</h1>

                        </div>

        <div id="audio-bt" onClick={playAudio}><img src={baseurl + "assets/audio/listen.png"} /></div>
             
        </div>

        {videoLink !== ""?
         <div className="video-intro" style={{height:"50vh"}}>
          <ReactHlsPlayer
          src={videoLink}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        /></div>:
        <div className="video-intro" style={{height:"50vh"}}>
        <img src={baseurl + "assets/game1/play.png"} onClick={videoPlay} id="video-waf" />
        <video id="video-lin" controls  src={video} >
            {/* <source src="https://cph-p2p-msl.akamaized.net/hls/live/2000341/test/master.m3u8"  type="application/x-mpegURL"></source> */}
        </video>

    </div>
        }
      


     

                  
                    
                    {props.questions.enlargeImage === true && <div style={{marginTop:50,display:"flex",justifyContent:"center",alignItems:"center",gap:50}}>
                   <div> <Image
      style={{ width: "200px", height: "auto" }}
      zoomed={zoomed}
      src={baseurl + "assets/levels/level2/unit0/talk/pic1.jpg"}
      alt="The best dog ever"
      onClick={() => setZoomed(true)}
      onRequestClose={() => setZoomed(false)}
    />
                       </div>

                       <div> <Image
      style={{ width: "200px", height: "auto" }}
      zoomed={zoomed2}
      src={baseurl + "assets/levels/level2/unit0/talk/pic2.jpg"}
      alt="The best dog ever"
      onClick={() => setZoomed2(true)}
      onRequestClose={() => setZoomed2(false)}
    />
                       </div>
                    </div>}

                    {props.questions.bottomImage !== undefined && 
                        <div style={{marginTop:50}}><img src={baseurl + props.questions.bottomImage} /></div>
                    }
                  

                    <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>
            </div>
            <audio id="intr" src={baseurl + props.questions.audio} />

                </div>


)
}
export default Video;