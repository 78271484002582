import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import TickIcon from './../components/svgs/tick'
import CrossIcon from './../components/svgs/cross'
import { CompassCalibrationOutlined, ContactSupportOutlined } from '@material-ui/icons';

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

const audioStyle=  {

    background:"#0B329D",width:30,height:30,display:"inline-block",alignItems:"center",justifyContent:"center",borderRadius:"100%",padding:10,marginRight:10,cursor:"pointer"
}
function DragAndDrop(props) {
    
    const history = useHistory();
    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState([
    
    ]);
    const [items,setItems] = useState([
       
        ]);

        const [randList,setRandList] = useState([]);
        const [randListV1,setRandListV1] = useState([]);

        const checkQuestion = () => {

            
            let items2 = [...randListV1];
            let allcorrect = true;

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:props.questions.title,
                userAnswers:[],
                type:"circle_words"
            }


            items2.map((item,m) => {
                item.questions.forEach((questionItem,i) => {
                    questionItem.spans.map((spanItem,j) => {
                        
                        if(spanItem.multipleAnswer === true) {
                            let subCorrect = false;
                            spanItem.correctAnswer.forEach((witem) => {

                                if(_.isEqual(spanItem.userAnswer,witem)) {
                                    item.questions[i].correct = true;
                                    subCorrect = true;
                                }
                                
                                
    
    
                            
                            });


                            let userAnswerText = "";
                            let correctAnswerText = "";

                            spanItem.userAnswer.forEach((item,i) => {

                                if(i === 0) {
                                    userAnswerText += item.value;
                                    correctAnswerText += spanItem.correctAnswer[0][i].value;
                                } else {
                                    userAnswerText += ", " + item.value;
                                    correctAnswerText += ", " + spanItem.correctAnswer[0][i].value;    
                                }
                            });


                            if(subCorrect === false) {
                                allcorrect = false;
                                item.questions[i].correct = false;
                            }

                            answerFormat.userAnswers.push({
                                userAnswer:userAnswerText,
                                correctAnswer:correctAnswerText,
                                correct:item.questions[i].correct
                            })

                        }
                        else if(_.isEqual(spanItem.userAnswer,spanItem.correctAnswer)) {
                            item.questions[i].correct = true;

                            let userAnswerText = "";
                            let correctAnswerText = "";

                            spanItem.userAnswer.forEach((item,i) => {

                                if(i === 0) {
                                    userAnswerText += item.value;
                                    correctAnswerText += spanItem.correctAnswer[i].value;
                                } else {
                                    userAnswerText += ", " + item.value;
                                    correctAnswerText += ", " + spanItem.correctAnswer[i].value;    
                                }
                            });

                            answerFormat.userAnswers.push({
                                userAnswer:userAnswerText,
                                correctAnswer:correctAnswerText,
                                correct:item.questions[i].correct
                            })

                        } else {
                            item.questions[i].correct = false;
                            allcorrect = false;

                            let userAnswerText = "";
                            let correctAnswerText = "";

                            spanItem.userAnswer.forEach((item,i) => {

                                if(i === 0) {
                                    userAnswerText += item.value;
                                    correctAnswerText += spanItem.correctAnswer[i].value;
                                } else {
                                    userAnswerText += ", " + item.value;
                                    correctAnswerText += ", " + spanItem.correctAnswer[i].value;    
                                }
                            });

                            answerFormat.userAnswers.push({
                                userAnswer:userAnswerText,
                                correctAnswer:correctAnswerText,
                                correct:item.questions[i].correct
                            })
                        }

                    });
                });

                return item;

             

            });



            if(allcorrect === true) {
                document.getElementById("correct-au").play();

            } else {
                document.getElementById("wrong-au").play();

            }
            answerFormat.pass = allcorrect;
            setRandListV1(items2);
            setTimeout(() => {
               props.nextExercise(answerFormat);
                // history.push(baseurl + "look_listen_say")
            }, 3000);
            
        }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i,j) => {
          let userSelect2 = [...userSelect];

          userSelect2[i].words[j] = value;
          setUserSelect(userSelect2);

        //   userSelect2[i] = value;
        //   setUserSelect(userSelect2);

      }

    useEffect(() => {
        setTimeout(() => {
            document.getElementById("intr").play();
        },300)
        let currentItems = [...items];

        // currentItems = shuffle(currentItems);

        setRandList(currentItems);
        document.getElementById("intr").play();

        setRandListV1(props.questions.answers)

    }, [props])
    
    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const checkQuestionAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].questions[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);
    }

    const characherCheck = (val,m,i,j,d) => {
        
        if(val !== " ") {
            let randListV2 = [...randListV1];
        


            let find = randListV2[m].questions[i].spans[j].userAnswer.findIndex((item) => item.position === d)
    
            if(find === -1) {
                randListV2[m].questions[i].spans[j].userAnswer.push({
                    position:d,
                    value:val
                })
            } else {

                if(props.questions.underLine === true) {

                    if(randListV2[m].questions[i].spans[j].userAnswer[find].underLine !== true) {
                        randListV2[m].questions[i].spans[j].userAnswer[find].underLine = true;  
                    } else {
                        randListV2[m].questions[i].spans[j].userAnswer = randListV2[m].questions[i].spans[j].userAnswer.filter((item) => item.position !== d );
                    }
                    
               
                    } else {
                        randListV2[m].questions[i].spans[j].userAnswer = randListV2[m].questions[i].spans[j].userAnswer.filter((item) => item.position !== d );
                    }

              //  randListV2[m].questions[i].spans[j].userAnswer = randListV2[m].questions[i].spans[j].userAnswer.filter((item) => item.position !== d );
            }
    
            randListV2[m].questions[i].spans[j].userAnswer = randListV2[m].questions[i].spans[j].userAnswer.sort((a,b) => a.position - b.position);
    
    
            setRandListV1(randListV2);
        }

    }

    const checkAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].answers[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);

    }
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    return(<div id="complete_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title" dangerouslySetInnerHTML={{ __html: props.questions.title }}></h1>
                </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>

                </div>  
                {props.questions.video !== "" && <div style={{maxWidth:"60vw",minWidth:"60vw",marginBottom:50}}>
                
                <div class="video-intro" style={{height: "50vh",width:"100%"}}><img onClick={videoPlay} src={baseurl + "assets/game1/play.png"} id="video-waf" /><video style={{width:"100%"}} id="video-lin" src={baseurl + props.questions.video} controls></video></div>
                </div>}
                {props.questions.image !== "" && <div style={{maxWidth:"60vw",display:"flex",alignItems:"center",justifyContent:"center",gap:20,marginBottom:30}}><img src={baseurl + props.questions.image} /></div>}
                
                <div style={{display:"flex",alignItems:"flex-start"}}>
               
                
        <div id="complete-items-v1" style={{minWidth:"60vw"}}>

            {randListV1.map((item,m) => {
                // console.log("item_v1",item);
                return(<div>
                    {item.questions.map((questionItem,i) => {

                        let rowStyle = {}
                        if(questionItem.image !== undefined) {
                            rowStyle = {flexDirection:"row",alignItems:"center",justifyContent:"space-between",width:"100%"};
                        }
                        return(<div className="items-v1" style={{marginBottom:20,display:"flex",flexDirection:"column",position:"relative",...rowStyle}}>

                            <div style={{display:"flex",alignItems:"center"}}>
                                
                                
                            {questionItem.audio !== undefined &&
                            <>
                            <audio src={baseurl + questionItem.audio} id={"audioitem-" + i} />
                                                                <div style={audioStyle} onClick={(e) => document.getElementById("audioitem-" + i).play()}>
                                <img src={baseurl + "assets/audio/listen.png"} />
                                </div>
                            </>
                            }
                                {questionItem.spans.map((spanItem,j) => {


                                let spans = spanItem.text.split(" ");
                                return(<div>
                                    {
                                        spans.map((item,d) => {

                                            let selected = {};
                                            let find = spanItem.userAnswer.findIndex((subItem) => subItem.position === d && subItem.value === item);

                                            if(find !== -1) {
                                                selected = {color:"rgb(34 203 242)"}

                                                if(spanItem.userAnswer[find].underLine === true) {
                                                    selected.borderBottom = "4px solid rgb(34 203 242)";
                                                }
                                                if(props.questions.underLineFirst === true) {
                                                    selected.borderBottom = "4px solid rgb(34 203 242)";
                                                }
                                            }
                                            

                                            

                                            return <span onClick={(e) => characherCheck(item,m,i,j,d)} style={{userSelect:"none",margin:0,padding:0,cursor:"pointer",...selected}} dangerouslySetInnerHTML={{__html:item + "&nbsp;"}}></span>
                                        })
                                    }
                                </div>)
                                
                                if(spanItem.type === "input") {

                                    let border = {};
                                    if(spanItem.border === true) {
                                        border = {border:"3px dashed #1BAFDB",borderRadius:10}
                                    }
                                    return(<span><input spellCheck="false"  type='text' style={{width:"auto",...border}} value={spanItem.userAnswer} onChange={(e) => { checkQuestionAnswer(e.target.value,m,i,j) }} size={spanItem.text.length} maxLength={spanItem.text.length} />
                                    
                                    </span>)
                                } else {
                                    return(<span dangerouslySetInnerHTML={{__html:spanItem.text}}></span>)
                                }

                            })}

                          
                                

                            </div>
                            

                            {questionItem.image !== undefined && <div><img style={{width:150,height:"auto"}} src={baseurl + questionItem.image} /></div>}

                            {props.questions.showAnswers === true &&
                            <div style={{margin:"20px 0px 0px"}}>{item.answers[i].spans.map((spanItem,j) => {

                                if(spanItem.type === "input") {
                                    return(<span><input spellCheck="false"  type='text' style={{width:"auto"}} onChange={(e) => { checkAnswer(e.target.value,m,i,j) }} size={spanItem.text.length} maxLength={spanItem.text.length} /></span>)
                                } else {
                                    return(<span>{spanItem.text}</span>)
                                }

                            })}</div>
                            }
                            
                            {questionItem.correct !== undefined && 
                            <>
                            {questionItem.correct === true &&  <div style={{position:"absolute",right:25,top:25}}><TickIcon /></div>}
                            {questionItem.correct === false &&  <div style={{position:"absolute",right:25,top:25}}><CrossIcon /></div>}


                            </>
                            }


                            
                            </div>)
                    })}
                </div>)
            })}

            {/* {randList.map((item,i) => {
                return(<div className="items-v1">{item.words.map((subItem,j) => {
                    if(subItem === "") {
                        let char_len= item.word.split(" ");
                        
                        return <span><input spellCheck="false"  type="text" onChange={(e) => {changeTxt(e.target.value,i,j)}} maxLength={char_len[j] !== undefined?(char_len[j].length>2?1:2):1} /></span>

                    } else {
                        return <span>{subItem + " "}</span>
                    }
                })}</div>);
            })} */}
        </div>


        {props.questions.images !== undefined && 
                    <div style={{maxWidth:"60vw",flexDirection:"column",marginLeft:20,display:"flex",alignItems:"center",justifyContent:"center",gap:20,marginBottom:30,padding:"20px 0px"}}>
                        {props.questions.images.map((item) => <div>
                            <img src={baseurl + item.url} />
                        </div> )}
                </div>}

        </div>

            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

            <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>
            </div>


    </div>)
}
export default DragAndDrop;