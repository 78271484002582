import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import TickIcon from './../components/svgs/tick'
import CrossIcon from './../components/svgs/cross'

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    
    const history = useHistory();
    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState([
      
    ]);
    const [items,setItems] = useState([
       
        ]);

        const [randList,setRandList] = useState([]);
        const [randListV1,setRandListV1] = useState([]);

        const checkQuestion = () => {

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:props.questions.title,
                userAnswers:[],
                type:"who_do_you_want_to_be"
            }
            props.nextExercise(answerFormat);
            
        }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i,j) => {
          let userSelect2 = [...userSelect];

          userSelect2[i].words[j] = value;
          setUserSelect(userSelect2);

        //   userSelect2[i] = value;
        //   setUserSelect(userSelect2);

      }

    useEffect(() => {
        setTimeout(() => {
            document.getElementById("intr").play();
        },300)
        let currentItems = [...items];

        // currentItems = shuffle(currentItems);

        setRandList(currentItems);
        document.getElementById("intr").play();

        setRandListV1(props.questions.answers)

    }, [])

    
    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const checkQuestionAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].questions[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);
    }

    const checkAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].answers[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);

    }
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    return(<div id="complete_write">
   
              
                {props.questions.image !== "" && <div style={{maxWidth:"60vw",display:"flex",alignItems:"center",justifyContent:"center",gap:20,marginBottom:30}}><img src={baseurl + props.questions.image} /></div>}
                {props.questions.image2 !== "" && <div style={{maxWidth:"60vw",display:"flex",alignItems:"center",justifyContent:"center",gap:20,marginBottom:30}}><img src={baseurl + props.questions.image2} /></div>}

                <div style={{display:"flex",alignItems:"flex-start"}}>
               
                
        <div id="complete-items-v1" style={{minWidth:"60vw"}}>

        
        </div>


        {props.questions.images !== undefined && 
                    <div style={{maxWidth:"60vw",flexDirection:"column",marginLeft:20,display:"flex",alignItems:"center",justifyContent:"center",gap:20,marginBottom:30,padding:"20px 0px"}}>
                        {props.questions.images.map((item) => <div>
                            <img src={baseurl + item.url} />
                        </div> )}
                </div>}

        </div>

            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

            <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>
            </div>


    </div>)
}
export default DragAndDrop;