import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import Tick from './../svgs/tick'
import MuzzyTitle from './../components/MuzzyTitle'

const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]

function ListenAndChooseTheWord(props) {
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState("");
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [answer,setAnswer] = useState(0);
    const history = useHistory();

    const instr = useRef();

    useEffect(() => {

    },[])
    useEffect(() => {
            setCurrent(0);       
            setData(props.questions.questionData);
            document.getElementById("intr2").play();
    }, [props.questions])

    const checkQuestion = (e) => {

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"multiple_checkboxes"
        }
        

        
        
        
       // let currentData = data;
       let currentData = [...data];
        let checkAll = true;
            currentData.forEach((item) => {

            
            if(item.userAnswer !== "" && item.correct === false) {

                item.checkAnswer = false;
                checkAll = false;
            } else if(item.correct === true) {
                item.checkAnswer = true;
            }

            if(props.questions.allcorrect === true) {
                item.checkAnswer = true;
                checkAll = true;
            } 

            answerFormat.userAnswers.push({
                userAnswer:item.userAnswer,
                correctAnswer:item.correct,
                correct:item.checkAnswer
              })

            
       });

       setData(currentData);


        if(checkAll === true) {
            document.getElementById("correct-au").play();
        } else {
            document.getElementById("wrong-au").play();
        }
        answerFormat.pass = checkAll;

       //document.getElementById("correct-au").play();
     

        setTimeout(() => {

            props.nextExercise(answerFormat);
        }, 3000);


        /*setTimeout(() => {
            history.push(baseurl + "drag")
        }, 3000);*/


        // setTimeout(() => {
        //     setShowCorrect(false);

        //     if(data[counterNext] !== undefined) {
        //         currentData[counterNext].complete = true;
        //         data[counterNext].answers = _.shuffle(data[counterNext].answers);

                
    
        //         setData(currentData);
        //         setCurrent(counterNext);
        //         setQuestion(data[counterNext])
        //         setUserSelect("");
        //     } else {
        //         props.setShowGoodJob(true);
        //     }
        //     setTimeout(() => {
        //         if(document.getElementById("question-audio") !== null) {
        //             document.getElementById("question-audio").play();
        //         }
        //     }, 500);
        // }, 3000);

    }

    const playAudio = (e) => {
        if(document.getElementById("question-audio") !== null) {
            document.getElementById("question-audio").play();
        }
    }

    const checkUserSelect = (userSelect,indx) => {
        if(userSelect === indx) {
            return ("selected multiple-question-item");
        } else {
            return ("multiple-question-item");
        }
    }

    const checkCorrect = (userSelect,indx,correct) => {

        if(correct === true) {
            return("multiple-question-item selected correct")
        } else {
            if(userSelect === indx) {
                return ("selected wronganswer multiple-question-item");
            } else {
                return ("multiple-question-item");
            }
        }
        
    }

    const playIntr = (e) => {
        document.getElementById("intr2").play();
    }

    const updateUserAnswer = (i) => {
        let data2 = [...data];

        if(props.questions.mainType !== undefined) {
            data2.map((item) => {
                item.userAnswer = "";
            });
        }

        if(data2[i].userAnswer === "") {
            data2[i].userAnswer = data2[i].text;
        } else {
            data2[i].userAnswer = "";
        }

        setData(data2);
    }

    return(<div>
        

 

        {props.questions.title !== "" && <div className="question-title-container" style={{width:"50vw",margin:"auto"}}>
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                
                <img src={baseurl + "assets/game5/listen.svg"} />
                <h1 className="question-title">{props.questions.title}</h1>

            </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                </div>}

                {props.questions.muzzyTitle !== undefined && <MuzzyTitle title={props.questions.muzzyTitle} imageRight={props.questions.imageRight} />}


                {props.questions.topImage !== undefined && <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}><img src={baseurl + props.questions.topImage} /></div>}

        <div className="question-container look-notice">
            <div 
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection:"column"
        }}
            >
            <div style={{
                textAlign:"left",
                width:"50vw"
            }}>
              
                           

            </div>
            <div id="checkboxs-list" style={{display:"flex",alignItems:"center",justifyContent:"center",flexWrap:"wrap",width:"60vw"}}>
                {data.map((item,i) => {

                    

                    let transform_style = {}
                    let container_style = {}

                    if(item.checkAnswer !== undefined) {
                        if(item.checkAnswer === true) {
                            container_style = {border:"5px solid #83cd83"};

                           
                        } else {
                            container_style = {border:"5px solid #F84E53"};
                        }
                    }

                    if(item.type === "text") {
                        container_style.width = props.questions.textWidth;
                        container_style.height = props.questions.textHeight;
                    }
                    
                    return(<div style={{margin:20,cursor:"pointer",...container_style}} onClick={(e) => {updateUserAnswer(i)}}>
                        {item.userAnswer !== "" && <Tick width={30} height={30} color={"#fff"} />}
                        {item.type === "image" && <img style={{...transform_style}} src={baseurl + item.url} />}
                        {item.type === "text" && <span style={{color:"#0B329D",fontFamily:"gotham-medium",fontSize:30,textAlign:"center"}}>{item.text}</span>}
                    </div>)
                })}
            </div>


            </div>



            <div className="button-check">
            <button className="check-answer-bt" onClick={checkQuestion} variant="contained" color="primary">Next</button>

            </div>

<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr2" />

        </div>


    </div>)
}

export default ListenAndChooseTheWord;