import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import TickIcon from './../components/svgs/tick'
import CrossIcon from './../components/svgs/cross'

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    
    const history = useHistory();
    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState([
      
    ]);
    const [items,setItems] = useState([
       
        ]);

        const [randList,setRandList] = useState([]);
        const [randListV1,setRandListV1] = useState([]);

        const checkQuestion = () => {

            
            let items2 = [...randListV1];
            let allcorrect = true;

            items2.forEach((item,m) => {
                item.questions.forEach((questionItem,i) => {
                    questionItem.spans.map((spanItem,j) => {
                        // console.log("item.questions",spanItem)

                        if(spanItem.type === "input") {
                            if(spanItem.fullAnswers !== undefined) {
                                items2[m].questions[i].correct = true;
                            }
                            else if(spanItem.answers !== undefined) {
                                items2[m].questions[i].correct = true;
                            }
                            else if(spanItem.open === true) {
                                items2[m].questions[i].correct = true;
                            }
                            else if(spanItem.userAnswer.toLowerCase() !== spanItem.text.toLowerCase()) {
                                // console.log(allcorrect,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())

                                allcorrect = false;
                                items2[m].questions[i].correct = false;
                            } else {
                                items2[m].questions[i].correct = true;
                            }
                        }

                    });
                });

                item.answers.forEach((questionItem,i) => {
                    questionItem.spans.map((spanItem,j) => {    
                        // console.log("item.answers",spanItem)

                        if(spanItem.answers !== undefined) {

                            let checksub = false
                            spanItem.answers.forEach((subItem2) => {
                                if(spanItem.userAnswer.toLowerCase() === subItem2.toLowerCase()) {
                                    checksub = true;
                                }
                            });

                            if(checksub === true) {
                                items2[m].questions[i].correct = true;
                            } else {
                                allcorrect = false;
                                items2[m].questions[i].correct = false;
                            }




                        }
                        else if(spanItem.type === "input") {
                            

                            if(spanItem.userAnswers !== undefined) {
                                console.log(spanItem.userAnswers.indexOf(spanItem.userAnswer),allcorrect);
                                if(spanItem.userAnswers.indexOf(spanItem.userAnswer) !== -1) {
                                    items2[m].questions[i].correct = true;
                                } else {
                                    allcorrect = false;
                                    items2[m].questions[i].correct = false;
                                }
                            }
                            else if(spanItem.open === true) {
                                items2[m].questions[i].correct = true;
                            }
                            else if(spanItem.userAnswer.toLowerCase() !== spanItem.text.toLowerCase()) {
                                // console.log(allcorrect,spanItem.userAnswer.toLowerCase(),spanItem.text.toLowerCase())

                                allcorrect = false;
                                items2[m].questions[i].correct = false;
                            } else {
                                items2[m].questions[i].correct = true;
                            }
                        }
                    });
                });

            });




            if(allcorrect === true) {
                document.getElementById("correct-au").play();

            } else {
                document.getElementById("wrong-au").play();

            }
            setRandListV1(items2);
            setTimeout(() => {

                let answerFormat = {
                    pass:true,
                    progress:0,
                    questionTitle:props.questions.title,
                    userAnswers:[],
                    type:"download_attach"
                }
               props.nextExercise(answerFormat);
                // history.push(baseurl + "look_listen_say")
            }, 3000);
            
        }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i,j) => {
          let userSelect2 = [...userSelect];

          userSelect2[i].words[j] = value;
          setUserSelect(userSelect2);

        //   userSelect2[i] = value;
        //   setUserSelect(userSelect2);

      }

    useEffect(() => {
        setTimeout(() => {
            document.getElementById("intr").play();
        },300)

    }, [])

    
    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const checkQuestionAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].questions[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);
    }

    const checkAnswer = (val,m,i,j) => {
        let randListV2 = [...randListV1];
        randListV2[m].answers[i].spans[j].userAnswer = val;
        setRandListV1(randListV2);

    }
    const videoPlay = (e) => {
        document.getElementById("video-lin").play();
        document.getElementById("video-waf").style.display = "none";
    }

    return(<div id="complete_write" style={{width:"52vw"}}>
            

                <div align="center"><img src={baseurl + props.questions.image} /></div>
               
                

            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />


            <div className="button-check">
                <a download={true} className="check-answer-bt" style={{background:"#bfd437"}} variant="contained" color="primary" href={baseurl + props.questions.downloadLink}>Print</a>
            </div>
            <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>
            </div>


    </div>)
}
export default DragAndDrop;