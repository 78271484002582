import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Animate } from 'react-simple-animate';

const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]

function ListenAndChooseTheWord(props) {
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState("");
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [icon,setIcon] = useState("");
    const [userAnswers,setUserAnswers] = useState([]);

    const history = useHistory();

    const instr = useRef();

    useEffect(() => {
        

        setTimeout(() => {
            document.getElementById("intr2").play();


        },500)
        setTimeout(() => {
            document.getElementById("intr").play();


        },3000)


    }, [data])
    useEffect(() => {
            let selected = props.questions;
        // console.log(props.questions)
            setIcon(props.questions.icon);
            let finalData = [];

            if(selected !== undefined) {
                selected.answers.forEach((item,i) => {
                    let p2 = JSON.parse(JSON.stringify(selected))
        
                    finalData.push(p2);
                })
        
                finalData.map((item,i) => {
                    item.answers[i].correct = true;
                    return item;
                })
            }    
           
            // selected = _.shuffle(selected);
            
            setData(finalData);

            if(finalData.length > 0) {
                finalData[0].complete = true;
                finalData[0].answers = _.shuffle(finalData[0].answers);
                let find = finalData[0].answers.findIndex((item) => item.correct === true);

                setAudio(finalData[0].answers[find].audio);

                setQuestion(finalData[0])



             
           
            }

        
                

    }, [props.questions])

    const checkQuestion = (e) => {
        
        let counterNext = current;
       // let currentData = data;

        counterNext++;

        // console.log("question.answers",question.answers[userSelect]);


        let findCorrect = question.answers.findIndex((item) => item.correct === true);

        if(question.answers[userSelect].correct === true) {
            // props.playAudio(true);
            setShowCorrect(true);
            document.getElementById("correct-au").play();
        } else {
            //  props.playAudio(false);
            setShowCorrect(true);
            document.getElementById("wrong-au").play();
        }
        question.answers[userSelect].correctAnswer = question.answers[findCorrect].name;

        setUserAnswers([...userAnswers,question.answers[userSelect]]);

        
 


        setTimeout(() => {

            if(counterNext < data.length) {








                let selected =  data[counterNext];
                selected.answers = _.shuffle(selected.answers);
                let find = selected.answers.findIndex((item) => item.correct === true);
    
                setQuestion(selected);
                setAudio(selected.answers[find].audio);
                setShowCorrect(false);
                setUserSelect("");
                setCurrent(counterNext);  
                document.getElementById("intr").play();

                setTimeout(() => {
                },300)

            } else {
              
                let answerFormat = {
                    pass:true,
                    progress:0,
                    questionTitle:question.title,
                    userAnswers:[],
                    type:"listen-and-point"
                }
                let correctAnswers = 0;

                userAnswers.forEach((item) => {
                    answerFormat.userAnswers.push({
                        userAnswer:item.name,
                        correctAnswer:item.correctAnswer,
                        correct:item.correct
                    })
                    if(item.correct === true) {
                        correctAnswers += 1;
                    } else {
                        answerFormat.pass = false;
                    }
                });
                // answerFormat.progress = Math.floor((correctAnswers / answerFormat.userAnswers.length) * 100);

                props.nextExercise(answerFormat); 
            }
        }, 3000);



    }

    const playAudio = (e) => {
        if(document.getElementById("question-audio") !== null) {
            document.getElementById("question-audio").play();
        }
    }

    const checkUserSelect = (userSelect,indx) => {
        if(userSelect === indx) {
            return ("selected multiple-question-item");
        } else {
            return ("multiple-question-item");
        }
    }

    const checkCorrect = (userSelect,indx,correct) => {

        if(correct === true) {
            return("multiple-question-item selected correct")
        } else {
            if(userSelect === indx) {
                return ("selected wronganswer multiple-question-item");
            } else {
                return ("multiple-question-item");
            }
        }
        
    }

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }


    return(<div>
        

 

        <div className="question-title-container">
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                {/* <img src={baseurl + "assets/game5/listen.svg"} /> */}
                {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}


                <h1 className="question-title">{question.title}</h1>

            </div>
                    <div id="audio-bt" onClick={playIntr}>
                        <img src={baseurl + "assets/audio/listen.png"} />
                    </div>
                </div>

        <div className="question-container">
            {question.answers !== undefined && 
                <div>
                <div className="multiple-question-data">

                    
                    {question.audio !== "" && question.audio !== undefined && <audio style={{display:"none"}} id="question-audio" src={process.env.REACT_APP_BACKEND_LINK + "/" + question.audio} style={{display:"none"}} />}
                    {question.answers.map((item,indx) => {

                        return (<div 
                            className={showCorrect === true?checkCorrect(userSelect,indx,item.correct):checkUserSelect(userSelect,indx)}
                        onClick={() => {
                            document.getElementById("clickbt").play();
                            setUserSelect(indx);}}>
                                <Animate play={true} easeType={"cubic-bezier(0.55, 0.06, 0.68, 0.19)"} start={{transform:"scale(0)",opacity:0}} end={{transform:"scale(1)",opacity:1}} duration={0.3} delay={indx * 0.2}>
                            <div className="multiple-question-img">
                                 {showCorrect === false && <img className={userSelect === indx?"answer-img selected":"answer-img"} src={baseurl + item.fileLink} />}
                                 {showCorrect === true && <img className={item.correct === true?"answer-img selected correct":userSelect === indx?"answer-img selected wronganswer":"answer-img"} src={baseurl + item.fileLink} />}
                                 <div className="multiple-question-title" style={{
                                         textAlign: "center",
                                         fontSize: 26
                                 }}><div style={{    minWidth: 145}} dangerouslySetInnerHTML={{ __html: item.name }}></div></div>
                            </div>
                            </Animate>
                        </div>)
                    })}
                </div>

                <audio src={`${baseurl}${audio}`} className="hidden-audio" id="intr" />

                <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />


                <div className="button-check">
                <button className="check-answer-bt" onClick={checkQuestion} disabled={userSelect === ""?true:false} variant="contained" color="primary">Check my answer</button>

                </div>

                </div> 
            }
                            <audio src={`${baseurl}assets/audio/audio1/listen_point.mp3`} className="hidden-audio" id="intr2" />

        </div>


    </div>)
}

export default ListenAndChooseTheWord;