import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Animate } from 'react-simple-animate';
import CurvedArrow from "react-curved-arrow";
import DrawLine from "./DrawLine";

const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]

function ListenAndChooseTheWord(props) { 
    const [data,setData] = useState([
       
    ]);
    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState("");
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [answer,setAnswer] = useState(0);
    const history = useHistory();

    const instr = useRef();

    useEffect(() => {
        document.getElementById("intr2").play();

    },[])
    useEffect(() => {



        setData(props.questions.inputs);

                

    }, [props.questions])

    const checkQuestion = (e) => {
        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"map_drag"
        }

        let data_v2 = [...data];
        let allcorrect = true;
        data_v2.map((item,index) => {
            // console.log(item.text.toLowerCase(),item.userAnswer.toLowerCase())
            if(item.text.toLowerCase() === item.userAnswer.toLowerCase()) {
                item.correct = true;
            } else {
                allcorrect = false;
                item.correct = false;
            }

            answerFormat.userAnswers.push({
                userAnswer:item.userAnswer.toLowerCase(),
                correctAnswer:item.text.toLowerCase(),
                correct:item.correct                
            })

            return item;
        });

        setData(data_v2);

        if(allcorrect === true) {
            document.getElementById("correct-au").play();
        } else {
            document.getElementById("wrong-au").play();
        }

        answerFormat.pass = allcorrect;

        

        setTimeout(() => {

        answerFormat.pass = allcorrect;
             props.nextExercise(answerFormat);
            
        }, 3000);


   

    }

    const playAudio = (e) => {
        if(document.getElementById("question-audio") !== null) {
            document.getElementById("question-audio").play();
        }
    }

    const checkUserSelect = (userSelect,indx) => {
        if(userSelect === indx) {
            return ("selected multiple-question-item");
        } else {
            return ("multiple-question-item");
        }
    }

    const checkCorrect = (userSelect,indx,correct) => {

        if(correct === true) {
            return("multiple-question-item selected correct")
        } else {
            if(userSelect === indx) {
                return ("selected wronganswer multiple-question-item");
            } else {
                return ("multiple-question-item");
            }
        }
        
    }

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const updateText = (value,i) => {

        let data_v2 = [...data];

        data_v2[i].userAnswer = value;

        setData(data_v2);
    }


    return(<div style={{width:"40vw"}}>
        

 

        <div className="question-title-container">
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>

                {props.questions.icon !== "none" &&  <img src={baseurl + "assets/game5/listen.svg"} />}
                <h1 className="question-title" dangerouslySetInnerHTML={{__html:props.questions.title}}></h1>

            </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                </div>
                <div style={{position:"relative"}}>
                    <img style={{width:"50vw"}} src={baseurl + props.questions.mainImage} />
                    <div id="points">
                        {props.questions.points.map((pointItem,i) => {
                            return(<>
                             <div className='point-item' id={"pointItem-" + i} style={{background:"#878786",borderRadius:"100%",position:"absolute",width:"0.5vw",height:"0.5vw",border:"2px solid #fff",right:pointItem.right,bottom:pointItem.bottom,left:pointItem.left}}></div>

                            </>
                           )
                        })}
                        {data.map((pointItem,i) => {

                            let check_obj = {};
                            if(pointItem.correct !== undefined) {
                                if(pointItem.correct === true) {
                                    check_obj.background = "rgb(126, 190, 64)";
                                } else {
                                    check_obj.background = "red";

                                }
                                check_obj.color = "#fff";

                            }

                            return(<>
                             
                             <div style={{position:"absolute",width:"6vw",right:pointItem.right,bottom:pointItem.bottom,left:pointItem.left,}}>
                                 <input spellCheck={"false"} value={pointItem.userAnswer} onChange={(e) => {

                                     updateText(e.target.value,i)
                                 }} type="text" id={"to-" + i} style={{fontSize:20,fontFamily:"gotham-medium",width:"100%",height:"100%",textAlign:"center",...check_obj}} />
                                </div>


                            </>
                           )
                        })}




                    </div>  
                </div>

                {
                

                    props.questions.points.map((pointItem,i) => {


                        
                        
                        
                        
                                            return(                
                                                <>
                                            <CurvedArrow fromSelector={"#pointItem-" + i} color={"#B7BCD1"} toSelector={"#to-" + i} middleY={0} toOffsetY={20} fromOffsetY={0} width={3} />
                        
                                                </>
                                            )
                                            })
                    
                                        }

                                        <div id="county" style={{background:"#22CBF2",display:"flex",marginTop:50,justifyContent:"center",alignItems:"center",gap:20,padding:30}}>
                                            {props.questions.country.map((countryItem) => {
                                                return(<div style={{fontFamily:"gotham-medium",fontSize:20}}>{countryItem}</div>)
                                            })}
                                        </div>

        <div className="question-container circle-and-read">
            
            <div className="button-check">
            <button className="check-answer-bt" onClick={checkQuestion} variant="contained" color="primary">Check my answer</button>

            </div>

<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                            {/* <audio src={`${baseurl}assets/tracks/t08/track8v1.mp3`} className="hidden-audio" id="intr2" /> */}
                            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr2" />

        </div>


    </div>)
}

export default ListenAndChooseTheWord;