import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Animate } from 'react-simple-animate';
import GreenCircleV2 from './../svgs/greenCircle_v4'
const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]

function ListenAndChooseTheWord(props) {
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState("");
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [answer,setAnswer] = useState(0);
    const history = useHistory();

    const instr = useRef();

    useEffect(() => {

    },[])
    useEffect(() => {
            setCurrent(0);       
            document.getElementById("intr2").play();
    }, [props.questions])

    const checkQuestion = (e) => {
        
        let counterNext = current;
       // let currentData = data;

        counterNext++;


        if(question.userAnswer === question.correct) {
           // props.playAudio(true);
            // setShowCorrect(true);
            document.getElementById("correct-au").play();
            setQuestion({
                ...question,
                correctAnswer:true
            })
        } else {
            
          //  props.playAudio(false);
            // setShowCorrect(true);
            document.getElementById("wrong-au").play();
            setQuestion({
                ...question,
                correctAnswer:false
            })
        }

        setTimeout(() => {

            if(counterNext < data.length) {
                let selected =  data[counterNext];
                selected.answers = _.shuffle(selected.answers);
                let find = selected.answers.findIndex((item) => item.correct === true);
    
                setQuestion(selected);
                setAudio(selected.audio);
                setShowCorrect(false);
                setUserSelect("");
                setCurrent(counterNext);  
                document.getElementById("intr2").play();

                setTimeout(() => {
                    // document.getElementById("intr2").play();

                    
                },300)

            } else {

                let answerFormat = {
                    pass:true,
                    progress:0,
                    questionTitle:props.questions.title,
                    userAnswers:[],
                    type:"look_notice"
                }
               // history.push(baseurl + "goodjob")
               props.nextExercise(answerFormat);
            }
        }, 3000);


        /*setTimeout(() => {
            history.push(baseurl + "drag")
        }, 3000);*/


        // setTimeout(() => {
        //     setShowCorrect(false);

        //     if(data[counterNext] !== undefined) {
        //         currentData[counterNext].complete = true;
        //         data[counterNext].answers = _.shuffle(data[counterNext].answers);

                
    
        //         setData(currentData);
        //         setCurrent(counterNext);
        //         setQuestion(data[counterNext])
        //         setUserSelect("");
        //     } else {
        //         props.setShowGoodJob(true);
        //     }
        //     setTimeout(() => {
        //         if(document.getElementById("question-audio") !== null) {
        //             document.getElementById("question-audio").play();
        //         }
        //     }, 500);
        // }, 3000);

    }

    const playAudio = (e) => {
        if(document.getElementById("question-audio") !== null) {
            document.getElementById("question-audio").play();
        }
    }

    const checkUserSelect = (userSelect,indx) => {
        if(userSelect === indx) {
            return ("selected multiple-question-item");
        } else {
            return ("multiple-question-item");
        }
    }

    const checkCorrect = (userSelect,indx,correct) => {

        if(correct === true) {
            return("multiple-question-item selected correct")
        } else {
            if(userSelect === indx) {
                return ("selected wronganswer multiple-question-item");
            } else {
                return ("multiple-question-item");
            }
        }
        
    }

    const playIntr = (e) => {
        document.getElementById("intr2").play();
    }


    return(<div>
        

 

        <div className="question-title-container" style={{width:"50vw",margin:"auto"}}>
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/listen.svg"} />
                <h1 className="question-title">{props.questions.title}</h1>

            </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                </div>

        <div className="question-container look-notice">
            <div 
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection:"column"
        }}
            >
            <div style={{
                textAlign:"left",
                width:"50vw"
            }}>
              
                            <GreenCircleV2 width={200} height={"auto"} style={{marginTop:-20}} title={
                                <>
                                 <tspan x={-65} y={0} fontSize={25}>
                                 How many 
                                 </tspan>
                                 <tspan x={-65} y={35} fontSize={25}>
                                 are there?
                                 </tspan>
                              </>} />

            </div>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexWrap:"wrap",width:"60vw"}}>
                {props.questions.images.map((item,i) => {

                    let transform_style = {transform:"rotate(2deg)"}
                    if(i % 2 === 0) {
                        transform_style = {transform:"rotate(-2deg)"}
                    }
                    return(<div style={{margin:20}}><img style={{border:"5px solid #C3BAFA",borderRadius:30,...transform_style}} src={baseurl + item.url} /></div>)
                })}
            </div>


            </div>



            <div className="button-check">
            <button className="check-answer-bt" onClick={checkQuestion} variant="contained" color="primary">Next</button>

            </div>

<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr2" />

        </div>


    </div>)
}

export default ListenAndChooseTheWord;