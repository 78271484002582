import React,{useState} from 'react';

function VideoCode() {
    const [videoTxt,setVideoTxt] = useState("");

    return(<div style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100vh",flexDirection:"column"}}>
        <div style={{display:"flex",gap:10}}>
            <input type='text' value={videoTxt} onChange={(e) => setVideoTxt(e.target.value)} style={{padding:"10px"}} />
            <button style={{height:"40px",padding:"0px 20px"}}>Submit</button>
        </div>
        <div style={{paddingTop:20}}>test</div>
    </div>);
}

export default VideoCode;