import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import BlueArrow from './../svgs/blue'
import BlueBg from './../svgs/blueBg'

const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);

    const [items,setItems] = useState([
        // {
        //     id:"1",
        //     word:"I am Muzzy.",
        //     word2:"I'm Muzzy.",
        //     fileLink1:baseurl + "assets/tracks/t07/iam_muzzy.mp3",
        //     fileLink2:baseurl + "assets/tracks/t07/im_muzzy.mp3"
        // },
        // {
        //     id:"2",
        //     word:"I am Sylvia.",
        //     word2:"I'm Sylvia.",
        //     fileLink1:baseurl + "assets/tracks/t07/iam_selvia.mp3",
        //     fileLink2:baseurl + "assets/tracks/t07/im_selvia.mp3"
        // }

        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i) => {
        let userSelect2 = [...userSelect];
        userSelect2[i] = value;
        setUserSelect(userSelect2);

    }

    const checkQuestion = () => {

//        setTimeout(() => {
            // history.push(baseurl + "circle_hear")
        // }, 3000);

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"look_listen_and_say"
        }
            props.nextExercise(answerFormat);
    }


    useEffect(() => {
        document.getElementById("intr").play();

        setItems(props.questions.answers)
        let currentItems = props.questions.answers;

        // currentItems = shuffle(currentItems);

        setTimeout(() => {
        },300)

        setRandList(currentItems);
        
    }, [props])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const stopAllAudios = (i) => {
        let audios = document.querySelectorAll("audio");
        audios.forEach((audio) => {
            audio.pause();
            audio.currentTime = 0;
        })
    }

    
    const playAudio = (i) => {
        stopAllAudios();

        
        document.getElementById("filelink1_" + i).play();
        document.getElementById("filelink1_" + i).play();
    }

    const playAudio2 = (i) => {
        stopAllAudios();

        document.getElementById("filelink2_" + i).play();
        document.getElementById("filelink2_" + i).play();

    }

    return(<div style={{width:props.questions.width}}>
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title">{props.questions.title}</h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>
                {/* <GreenCircle style={{marginLeft:-30,marginTop:-30}} /> */}

                {props.questions.topImage !== undefined && <div style={{
                        background: "#fff",
                        borderRadius: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                }}><img src={baseurl + props.questions.topImage} /></div>}

        <div id="look_and_say" className="look_listen_say">
            {randList.map((item,j) => {

item.word2 = item.word2.replaceAll(" ","&nbsp;")
item.word = item.word.replaceAll(" ","&nbsp;")

let background = {};
let text_color = {};

if(item.showArrow === true) {
    background = {background:"#9AE5F8"}
    text_color = {color:"#0b329d"}
}

let word1_style = {};
if(props.questions.wordWidth !== undefined) {
    word1_style = {width:props.questions.wordWidth};
}

if(props.questions.layerWidth !== undefined) {
    word1_style.width = props.questions.layerWidth;
}

let word2_style = {width:420};
if(props.questions.wordWidth !== undefined) {
    word2_style.width = props.questions.wordWidth;
}
if(item.word2 === "") {
    background.gap = 0;
}


                return(
                <div className="item-v1">
                    <div className="look_words" style={{position:"static",justifyContent:"center",padding:0,...background}}>
                        <div className="word1" style={{position:"relative",...word1_style}} onClick={() => playAudio(j)}>
                            <div>
                                
                                {/* <BlueBg style={{width:"100%",height:"auto"}} /> */}
                            <div style={item.showArrow === true?{height:"75px",...word2_style}:{height:"75px",background:"#9AE5F8",...word2_style}}></div>
                            </div>
                            {/* <div style={{position:"absolute",left:0,top:0,width:"100%",textAlign:"center"}}>{item.word}</div> */}

                            <div style={{position:"absolute",left:0,top:0,width:"100%",textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}} dangerouslySetInnerHTML={{__html:item.word}}></div>

                        </div>
                        {item.showArrow === true && <div style={{display:"flex",alignContent:"center",alignItems:"center"}}><img src={baseurl + "assets/icons/arrow_blue1.png"} /></div>}
                        {item.word2 !== "" ?
                        <div className="word2" style={{position:"relative",...word1_style}} onClick={() => playAudio2(j)}>
                            <div>
                                
                                {/* <BlueBg style={{width:"100%",height:"auto"}} /> */}
                            
                            <div style={item.showArrow === true?{width:"420px",height:"75px"}:{width:"420px",height:"75px",background:"#22CBF2",...word1_style}}></div>
                            </div>
                            <div style={{position:"absolute",left:0,top:0,width:"100%",textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center",height:"100%",...text_color}}>
                                <div dangerouslySetInnerHTML={{__html:item.word2}}></div>
                                {item.image2 !== undefined &&<div style={{position:"absolute",right:-60,width:60,height:60,display:"flex",alignItems:"center",marginLeft:10,flexWrap:"wrap",flex:1}}><img style={{width:60,height:60}} src={baseurl + item.image2} /></div>}


                            </div>
                        </div>:<div></div>}

                        {item.word3 !== undefined ?
                        <div className="word2" style={{position:"relative",...word1_style}} onClick={() => playAudio2(j)}>
                            <div>
                                
                                {/* <BlueBg style={{width:"100%",height:"auto"}} /> */}
                            
                            <div style={item.showArrow === true?{width:"420px",height:"75px"}:{width:"420px",height:"75px",background:"#22CBF2",...word1_style}}></div>
                            </div>
                            <div style={{position:"absolute",left:0,top:0,width:"100%",textAlign:"center",display:"flex",alignItems:"center",justifyContent:"center",height:"100%",...text_color}}>
                                <div dangerouslySetInnerHTML={{__html:item.word3}}></div>
                                {item.image2 !== undefined &&<div style={{position:"absolute",right:-60,width:60,height:60,display:"flex",alignItems:"center",marginLeft:10,flexWrap:"wrap",flex:1}}><img style={{width:60,height:60}} src={baseurl + item.image2} /></div>}


                            </div>
                        </div>:<div></div>}
                        
                        <audio id={"filelink1_" + j} src={ baseurl + item.fileLink1}  />
                        <audio id={"filelink2_" + j} src={ baseurl + item.fileLink2}  />
                        <audio id={"filelink3_" + j} src={ baseurl + item.fileLink3}  />


                    </div>
                   
                </div>)
            })}

<audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />
<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

        </div>
        
        {props.questions.table !== undefined &&
        <>
            <div className='table-style1'>
                <table border='1' cellPadding="10" cellSpacing="0">
                    {props.questions.table.header !== undefined &&
                     <tr>
                         {props.questions.table.header.map((item) => {
                            return(<th>{item}</th>);

                         })}
                    </tr>
                    }
                   {props.questions.table.data.map((item,i) => {
                       return(<tr>
                           {item.map((item2,j) => {
                               return(<td>{item2}</td>)
                           })}
                           
                    </tr>);
                   })}
                    
                </table>
            </div>
        </>
        }


             

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>

                </div>


    </div>)
}
export default DragAndDrop;