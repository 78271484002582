import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Animate } from 'react-simple-animate';

const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]

function ListenAndChooseTheWord(props) {
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState("");
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [answer,setAnswer] = useState(0);
    const [answerFormat,setAnswerFormat] = useState({
        pass:true,
        progress:0,
        questionTitle:"Complete.",
        userAnswers:[],
        type:"complete"
    });
    const history = useHistory();

    const instr = useRef();

    useEffect(() => {
        document.getElementById("intr2").play();

    },[])
    useEffect(() => {
            let selected = props.questions;
            setCurrent(0);
            // selected = _.shuffle(selected);
            

            if(selected.length > 0) {
                setQuestion(selected[0])
                setAudio(baseurl + selected[0].audio);

                setTimeout(() => {
                    document.getElementById("intr").play();

                }, 3500);
            //     selected[0].complete = true;
            //     //  selected[0].answers = _.shuffle(selected[0].answers);
            //     selected[0].answers = _.shuffle(selected[0].answers);
            //     let find = selected[0].answers.findIndex((item) => item.correct === true);

            //     setAudio(selected[0].answers[find].audio);

            //     setQuestion(selected[0])

            //    // document.getElementById("intr2").play();
            //    document.getElementById("intr2").play();

            //    setTimeout(() => {

            //    }, 500);

            //    setTimeout(() => {
            //     document.getElementById("intr").play();

            // },2000)


                // setTimeout(() => {
                //     document.getElementById("intr").play();
                // },1800)
           
            }
            setData(selected);

            // console.log(props.questions)

            // setTimeout(() => {
            //     if(document.getElementById("question-audio") !== null) {
            //         document.getElementById("question-audio").play();
            //     }
            // }, 500);
                

    }, [props.questions])

    const checkQuestion = (e) => {

        let answerFormat2 = {...answerFormat};
        
        let counterNext = current;
       // let currentData = data;

        counterNext++;


        if(question.userAnswer === question.correct) {
           // props.playAudio(true);
            // setShowCorrect(true);
            document.getElementById("correct-au").play();
            setQuestion({
                ...question,
                correctAnswer:true
            })

            answerFormat2.userAnswers.push({
                userAnswer:question.userAnswer,
                correctAnswer:question.correct,
                correct:true
            });

        } else {
            
          //  props.playAudio(false);
            // setShowCorrect(true);
            document.getElementById("wrong-au").play();
            setQuestion({
                ...question,
                correctAnswer:false
            })

            answerFormat2.userAnswers.push({
                userAnswer:question.userAnswer,
                correctAnswer:question.correct,
                correct:false
            });
        }

        

        setTimeout(() => {

            if(counterNext < data.length) {

                setAnswerFormat(answerFormat2);

                let selected =  data[counterNext];
                selected.answers = _.shuffle(selected.answers);
                let find = selected.answers.findIndex((item) => item.correct === true);
    
                setQuestion(selected);
                setAudio(baseurl + selected.audio);
                setShowCorrect(false);
                setUserSelect("");
                setCurrent(counterNext);  
                document.getElementById("intr").play();

                

                setTimeout(() => {
                    // document.getElementById("intr2").play();

                    
                },300)

            } else {
              //  history.push(baseurl + "read_names")
              props.nextExercise(answerFormat2);
              window.location.hash = "#17";
            }
        }, 3000);


        /*setTimeout(() => {
            history.push(baseurl + "drag")
        }, 3000);*/


        // setTimeout(() => {
        //     setShowCorrect(false);

        //     if(data[counterNext] !== undefined) {
        //         currentData[counterNext].complete = true;
        //         data[counterNext].answers = _.shuffle(data[counterNext].answers);

                
    
        //         setData(currentData);
        //         setCurrent(counterNext);
        //         setQuestion(data[counterNext])
        //         setUserSelect("");
        //     } else {
        //         props.setShowGoodJob(true);
        //     }
        //     setTimeout(() => {
        //         if(document.getElementById("question-audio") !== null) {
        //             document.getElementById("question-audio").play();
        //         }
        //     }, 500);
        // }, 3000);

    }

    const playAudio = (e) => {
        if(document.getElementById("question-audio") !== null) {
            document.getElementById("question-audio").play();
        }
    }

    const checkUserSelect = (userSelect,indx) => {
        if(userSelect === indx) {
            return ("selected multiple-question-item");
        } else {
            return ("multiple-question-item");
        }
    }

    const checkCorrect = (userSelect,indx,correct) => {

        if(correct === true) {
            return("multiple-question-item selected correct")
        } else {
            if(userSelect === indx) {
                return ("selected wronganswer multiple-question-item");
            } else {
                return ("multiple-question-item");
            }
        }
        
    }

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }


    return(<div style={{width:"40vw"}}>
        

 

        <div className="question-title-container">
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/listen.svg"} />
                <h1 className="question-title">{props.quizsection.subtitle}</h1>

            </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                </div>

        <div className="question-container circle-and-read">
            {question.words !== undefined && 
                <div className="circle-and-read-container" style={{height: "30vh"}}>

                <audio src={`${audio}`} className="hidden-audio" id="intr" />

               {question.words.map((item) => {
                   if(item === "") {
                    return(<div className="text-item options-item">
                        {question.options.map((subItem,j) => {
                            return(<div style={
                                question.correctAnswer === undefined?
                                
                                question.userAnswer === j ? {
                                    boxShadow: "0px 6px 0px #E4B305",
                                    background: "#F9CB2E"
                                }:{}


                                :
                                question.correctAnswer === true && question.correct === j?{
                                    boxShadow: "0px 6px 0px #608F30",
                                    background: "#7EBE40"
                                }:{
                                    boxShadow: "0px 6px 0px #CA0000",
                                    background: "red"
                                }
                                
                            } onClick={(e) => { setQuestion({...question,userAnswer:j})
                        }}>{subItem}</div>);
                        })}
                    </div>)
                   } else {
                    return(<div className="text-item">{item}</div>)

                   }
                })}

              

                </div> 
            }
            <div className="button-check">
            <button className="check-answer-bt" onClick={checkQuestion} variant="contained" color="primary">Check my answer</button>

            </div>

<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                            <audio src={`${baseurl}assets/audios/new/click/click-on-the-one-you-hear.mp3`} className="hidden-audio" id="intr2" />

        </div>


    </div>)
}

export default ListenAndChooseTheWord;