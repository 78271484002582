import react,{useEffect, useState } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import ReactHlsPlayer from 'react-hls-player';

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    
    const history = useHistory();
    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [videoLink, setVideoLink] = useState("");
    const [icon,setIcon] = useState("");




    useEffect(() => {
        if(props.questions.videoCode !== undefined) {

            fetch("https://api.vimeo.com/videos/" + props.questions.videoCode,{
                method: "GET",
                headers: {
                    "Authorization": "Bearer aeeec5987db81bbfd76e0f272e35468c", 
                }
            }).then(response => response.json()).then(data => {
                // console.log(data);
                // setVideoData(data.embed.html)
                // console.log("video212",{
                //     autoplay: true,
                //     controls: true,
                //     responsive: true,
                //     fluid: true,
                //     sources: [{
                //       src: data.files[0].link,
                //       type: 'video/mp4'
                //     }]
                // })

                
                if(data.files !== undefined) {
                    let files = data.files;
                    let findHls = files.findIndex((item) => item.quality === "hls");

                    if(findHls !== -1) {
                        setVideoLink(data.files[findHls].link_secure);
                    }
                }
            
            });

        } else {
            if(props.questions.video !== undefined) {
                setVideoLink(baseurl + props.questions.video);
            }
        }

    },[])


    const [items,setItems] = useState([
        ]);

        const [randList,setRandList] = useState([]);
    
        const checkQuestion = () => {
            let items2 = [...randList];
            let allcorrect = true;

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:"Listen, read, and say.",
                userAnswers:[],
                type:"complete"
            }


            items2.map((subItem,i) => {
                let currentword = "";

                subItem.words.forEach((subItem2) => {

                    if(subItem2.type === "input") {


                        if(subItem2.answers !== undefined) {

                            
                            subItem2.userAnswer = subItem2.userAnswer.toLowerCase().replaceAll("’","'");
                            // subItem2.text = subItem2.text.toLowerCase().replaceAll("’","'");
                            subItem2.answers = subItem2.answers.map((item) => {
                                item = item.toLowerCase().replaceAll("’","'");
                                return item;
                            });
                            if(subItem2.answers.indexOf(subItem2.userAnswer) !== -1 ) {
                                subItem.correct = true;
                            } else {
                                subItem.correct = false;
                                answerFormat.pass = false;
                                allcorrect = false;
                            }
    
                            answerFormat.userAnswers.push({
                                userAnswer:subItem2.userAnswer.trim(),
                                correctAnswer:subItem2.answers[0].trim(),
                                correct:subItem.correct
                            });


                        } else {
                            subItem2.userAnswer = subItem2.userAnswer.toLowerCase().replaceAll("’","'");
                            subItem2.text = subItem2.text.toLowerCase().replaceAll("’","'");
                            if(subItem2.text.trim() === subItem2.userAnswer.trim() ) {
                                subItem.correct = true;
                            } else {
                                subItem.correct = false;
                                answerFormat.pass = false;
                                allcorrect = false;
                            }
    
                            answerFormat.userAnswers.push({
                                userAnswer:subItem2.userAnswer.trim(),
                                correctAnswer:subItem2.text.trim(),
                                correct:subItem.correct
                            });
                        }
                        
                    }
                    
                })


                

                return subItem;
            })
            if(allcorrect === true) {
                document.getElementById("correct-au").play();

            } else {
                document.getElementById("wrong-au").play();

            }
            setRandList(items2);
            setTimeout(() => {
                // history.push(baseurl + "look_read_write")

                console.log("answerFormat",answerFormat);
        
                props.nextExercise(answerFormat);
            }, 3000);
            
        }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i,j) => {

        let randList2 = [...randList]
          let userSelect2 = [...userSelect];
          userSelect2[i] = value;
          setUserSelect(userSelect2);

          randList2[i].words[j].userAnswer = value;
          setRandList(randList2);
      }

    useEffect(() => {

        setIcon(props.questions.icon)
        console.log(props.questions)

        setTimeout(() => {
            document.getElementById("intr").play();
        },300)

        setItems(props.questions.answers)
        let currentItems = props.questions.answers;

        // currentItems = shuffle(currentItems);

        setRandList(currentItems);
        
    }, [])

    
    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    return(<div id="complete_write">
                <div className="question-title-container" style={{width:"70vw",margin:"auto"}}>
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                {/* <img src={baseurl + "assets/game5/write.svg"} /> */}

                {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}


                    <h1 className="question-title" dangerouslySetInnerHTML={{ __html: props.questions.title }}></h1>
                </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>

                </div>
                {/* {props.questions.video !== undefined && 
                
                <div className="video-intro" style={{"height": "50vh",margin:"auto"}}>
                    <img src={baseurl + "assets/game1/play.png"} id="video-waf" onClick={(e) => {
                        document.getElementById("video-waf").style.display = "none";
                        document.getElementById("video-lin").play();
                    }} />
                    <video id="video-lin" src={baseurl + props.questions.video} controls="" />
                </div>
                } */}


{videoLink !== "" &&
         <div className="video-intro" style={{"height": "50vh",margin:"auto"}}>
          <ReactHlsPlayer
          src={videoLink}
          autoPlay={false}
          controls={true}
          width="100%"
          height="auto"
        /></div>
        }
        
        <div id="drag-items-v1" className="drag-v2">
            {randList.map((item,i) => {
                                    let textAlign = "left";

                                    if(props.questions.textAlign !== undefined) {
                                        textAlign = props.questions.textAlign;
                                    }
                                    let borderRadius = {};
                                    console.log(item)
                                    if(item.rounded === true) {
                                        borderRadius = {borderRadius:"100%"};
                                    }

                return(
                <div className="drag-item-v1">
                    {item.correct === undefined ?<img src={baseurl + item.img} />:
                    item.correct === true?<img style={{border:"8px solid #83cd83"}} src={baseurl + item.img} />:<img style={{border:"8px solid #F84E53"}} src={baseurl + item.img} />
                                        

                    }



                    

                    <div className="word-v1" style={borderRadius}>
                        <div className="word-arrow"><img src={baseurl +"assets/game2/arrow.png"} /></div>
                        {item.words.map((subWord,j) => {

                            if(subWord.type === "input") {


                                if(subWord.size !== undefined) {
                                    return(<span className={"editable-input"} ><input style={props.questions.width === "auto"?{width:"auto",textAlign:textAlign}:{textAlign:textAlign}} spellCheck="false"  type="text" size={subWord.size} onChange={(e) => {changeTxt(e.target.value,i,j)}} /></span>)
                                } else {
                                    return(<span className={"editable-input"} ><input style={props.questions.width === "auto"?{width:"auto",textAlign:textAlign}:{textAlign:textAlign}} spellCheck="false"  type="text" onChange={(e) => {changeTxt(e.target.value,i,j)}} /></span>)
                                }
                            } else {
                                return(<span onChange={changeTxt} className={subWord.text === ""?"editable":"readable"} contentEditable={subWord.text === ""?true:false}>{subWord.text === ""?userSelect[i]:subWord.text}</span>)
                            }

                        })}
                    </div>
                </div>)
            })}
        </div>

            {/* <audio src={`${baseurl}assets/muzzy/audio/complete_write_m_or_am.mp322`} className="hidden-audio" id="intr" /> */}
            {/* <audio src={`${baseurl}assets/levels/level1/unit1/assets/audios/complete.mp3`} className="hidden-audio" id="intr" /> */}
            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" /> 
            <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
            <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

            <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>
            </div>


    </div>)
}
export default DragAndDrop;