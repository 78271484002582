import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import GreenCircle from './../svgs/greenCircle'
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {
    const history = useHistory();

    const [currentDrag,setCurrentDrag] = useState({});
    const [userSelect,setUserSelect] = useState(["","","","",""]);
    const [checkAnswers,setCheckAnswers] = useState(false);

    const intr = useRef(null)

    const [items,setItems] = useState([
        {
            id:"1",
            word:"bob",
            img:baseurl +"assets/game2/item2.png",
            fileLink:baseurl + "assets/tracks/t05/hello.mp3",
            words:[
                {
                    text:"Hello."
                }
            ]
        },
        {
            id:"2",
            word:"Muzzy",
            img:baseurl +"assets/game2/item5.png",
            fileLink:baseurl + "assets/tracks/t05/hi.mp3",
            words:[
                {
                    text:"Hi."
                }
               
            ]
        },
        {
            id:"3",
            word:"Corvax",
            img:baseurl +"assets/game2/item2.png",
            fileLink:baseurl + "assets/tracks/t05/how_are_you.mp3",
            words:[
                {
                    text:"How are you?"
                }
            ]
        },
        {
            id:"4",
            word:"Queen",
            img:baseurl +"assets/game2/item5.png",
            fileLink:baseurl + "assets/tracks/t05/iam_fine.mp3",
            words:[
                {
                    text:"I'm fine."
                }
              
            ]
        },
        {
            id:"5",
            word:"Sylvia",
            img:baseurl +"assets/game2/item2.png",
            fileLink:baseurl + "assets/tracks/t05/iam_muzzy.mp3",
            words:[
                {
                    text:"I'm Muzzy."
                }
             
            ]
        },
        {
            id:"5",
            word:"Sylvia",
            img:baseurl +"assets/game2/item5.png",
            fileLink:baseurl + "assets/tracks/t05/iam_selvia.mp3",
            words:[
                {
                    text:"I’m Sylvia."
                }
            ]
        }
        ]);

        const [randList,setRandList] = useState([]);
    


    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      
      const changeTxt = (value,i) => {
        let userSelect2 = [...userSelect];
        userSelect2[i] = value;
        setUserSelect(userSelect2);

    }

    const checkQuestion = () => {

            //history.push(baseurl + "look_say")
            window.location.hash = "#9";
    }


    useEffect(() => {
        let currentItems = [...items];

        // currentItems = shuffle(currentItems);

        intr.current.play();

        setTimeout(() => {
            // document.getElementById("intr").play();
        },300)

        setRandList(currentItems);
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const audioPlay = (i) => {
        document.getElementById("audioplay-" + i).play();
    }

    return(<div id="look_write">
                <div className="question-title-container">
                <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                <img src={baseurl + "assets/game5/write.svg"} />

                    <h1 className="question-title">Listen, read, and say.</h1>
                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl +"assets/audio/listen.png"} /></div>
                </div>
                {/* <GreenCircle style={{marginLeft:-30,marginTop:-30}} /> */}

        <div id="look_write-items-v1" className="listenread-container">
            {randList.map((item,j) => {
                return(
                <div className="look_write-item-v1 listenread">

                    {checkAnswers === false ?<img src={item.img} />:item.correct === false ?<img style={{border:"5px solid #F84E53"}} src={item.img} />:<img style={{border:"5px solid #83cd83"}} src={item.img} />}
                    {/* {item.correct === undefined ?<img style={{border:"8px solid #F84E53"}} src={item.img} />:
                    item.correct === true?<img style={{border:"8px solid #83cd83"}} src={item.img} />:<img src={item.img} />
                    } */}
                    
                    <div className="word-v1">
                        {item.words.map((subWord,i) => {


                            
                                return(<div style={{
                            
                                fontSize: 20,
    boxShadow: "rgb(34 203 242) -4px 4px 0px",
    border: "2px solid rgb(34 203 242)",
    padding: "15px 30px",
    borderRadius: "10px",
    position:"relative",
    background:"#fff"
                            }}>

                                
                                <img src={j % 2 === 0?baseurl + "assets/game8/arrow.png":baseurl + "assets/game8/arrow_ar.png"} style={{
                                        width: "auto",
                                        height: "auto",
                                        border: 0,
                                        position: "absolute",
                                        left: -19,
                                        top: "17%",
                                        borderRadius:0
                                }} />
                                {subWord.text}
                                
                                </div>)
                            

                        })}
                                                        <div onClick={() => {audioPlay(j)}} className="audio-de" style={{marginLeft:10,marginRight:10}}><img src={baseurl + "assets/audio/listen.png"} /></div>

                                                        <audio src={item.fileLink} id={"audioplay-" + j} />

<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                    </div>
                </div>)
            })}
        </div>
        <audio src={`${baseurl}assets/tracks/t05/track5v1.mp3`} className="hidden-audio" id="intr" ref={intr} />

             

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Next</button>

                </div>


    </div>)
}
export default DragAndDrop;