import react,{useEffect, useState,useRef } from 'react'
import axios from 'axios';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Animate } from 'react-simple-animate';
import CurvedArrow from "react-curved-arrow";
import DrawLine from "./DrawLine";

const baseurl = process.env.REACT_APP_FRONT_LINK;

// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
const questionobj = [
    {
        image:""
    }
]

function ListenAndChooseTheWord(props) {
    const [data,setData] = useState([
       
    ]);
    const [current,setCurrent] = useState(0);
    const [question,setQuestion] = useState({});
    const [userSelect,setUserSelect] = useState("");
    const [showCorrect,setShowCorrect] = useState(false);
    const [audio,setAudio] = useState("");
    const [answer,setAnswer] = useState(0);
    const [content,setContent] = useState([]);

    const history = useHistory();

    const instr = useRef();

    useEffect(() => {
        document.getElementById("intr2").play();

    },[])
    useEffect(() => {



        setContent(props.questions.content);
                

    }, [props.questions])

    const checkQuestion = (e) => {
        

        // let data_v2 = [...data];
        // let allcorrect = true;
        // data_v2.map((item,index) => {
        //     console.log(item.text.toLowerCase(),item.userAnswer.toLowerCase())
        //     if(item.text.toLowerCase() === item.userAnswer.toLowerCase()) {
        //         item.correct = true;
        //     } else {
        //         allcorrect = false;
        //         item.correct = false;
        //     }

        //     return item;
        // });

        // setData(data_v2);

        // if(allcorrect === true) {
        //     document.getElementById("correct-au").play();
        // } else {
        //     document.getElementById("wrong-au").play();

        // }

        let answerFormat = {
            pass:true,
            progress:0,
            questionTitle:props.questions.title,
            userAnswers:[],
            type:"image-txt"
        }

        props.nextExercise(answerFormat);



   

    }

    const playAudio = (e) => {
        if(document.getElementById("question-audio") !== null) {
            document.getElementById("question-audio").play();
        }
    }

    const checkUserSelect = (userSelect,indx) => {
        if(userSelect === indx) {
            return ("selected multiple-question-item");
        } else {
            return ("multiple-question-item");
        }
    }

    const checkCorrect = (userSelect,indx,correct) => {

        if(correct === true) {
            return("multiple-question-item selected correct")
        } else {
            if(userSelect === indx) {
                return ("selected wronganswer multiple-question-item");
            } else {
                return ("multiple-question-item");
            }
        }
        
    }

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const updateText = (value,i) => {

        let data_v2 = [...data];

        data_v2[i].userAnswer = value;

        setData(data_v2);
    }


    return(<div style={{width:"40vw"}}>
        

 
        {props.questions.hideTitle !== true &&
        <div className="question-title-container">
            <div style={{display:"flex",alignItems:"center",gap:"20px"}}>

                {props.questions.icon !== "none" &&  <img src={baseurl + "assets/game5/listen.svg"} />}
                <h1 className="question-title" dangerouslySetInnerHTML={{__html:props.questions.title}}></h1>

            </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                </div>}

                {props.questions.options !== undefined && <div style={{borderRadius:25,background:"#22cbf2",marginBottom:30,display:"flex",alignItems:"center",justifyContent:"center",padding:"30px 0px",flexWrap:"wrap"}}>
                    {props.questions.options.map((item) => <div style={{padding:"5px 20px",fontFamily:"gotham-medium",fontSize:20}}>{item}</div>)}

                </div>}


                <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <div>
                    {props.questions.leftImage !== undefined && <div>
                        <img style={{width:400}} src={baseurl + props.questions.leftImage} />    
                    </div>}
                    </div>
                    <div></div>
                    
                </div>
                

                <div style={{

display: "flex",
flexDirection: "column",
alignItems: "center",
justifyContent: "center",
width: "100%"
                }}>
                {content.map((item,index) => {

                    if(item.type === "text") {

                       
                        return(<div className='text-style text-itemv'>
                            {item.topImage !== undefined && <div><img src={baseurl + item.topImage} /></div>}
                            <div dangerouslySetInnerHTML={{__html:item.text}}></div>
                        </div>)
                    }
                    if(item.type === "image") {     

                        let imageStyle = {};

                        if(item.border === true) {
                            imageStyle.border = "4px solid #22CBF2";
                        }
                        if(item.borderRadius === true) {
                            imageStyle.borderRadius = "20px";
                        }
                        if(item.width !== undefined) {
                            imageStyle.width = item.width;
                        }
                        return(<div className='text-itemv img-style'>
                            <div className='text-style text-itemv'>
                            {item.title !== undefined && <div className="maintitle" style={{paddingBottom:20}}>{item.title}</div>}
                            {item.number !== undefined && <div className='number-item'>{item.number}</div>}
                            <img src={baseurl + item.url} /></div>
                            {item.download !== undefined && <div align="center"><a download className='check-answer-bt' href={baseurl + item.download}>Download</a></div>}

                            </div>);
                    }
                })}
                </div>

         

                                      

        <div className="question-container circle-and-read">
            
            <div className="button-check">
            <button className="check-answer-bt" onClick={checkQuestion} variant="contained" color="primary">Next</button>

            </div>

<audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

                            {/* <audio src={`${baseurl}assets/tracks/t08/track8v1.mp3`} className="hidden-audio" id="intr2" /> */}
                            <audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr2" />

        </div>


    </div>)
}

export default ListenAndChooseTheWord;