import * as React from "react"

function SoundIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} {...props} viewBox="0 0 54 54">
      <g data-name="Group 4031" transform="translate(-1137 -180)">
        <circle
          data-name="Ellipse 99"
          cx={27}
          cy={27}
          r={27}
          transform="translate(1137 180)"
          fill="#22cbf2"
        />
        <g data-name="Group 3998" fill="#fff">
          <path
            data-name="Path 2517"
            d="M1166.927 197.441v18.4a4.4 4.4 0 01-2.865 4.147 4.48 4.48 0 01-1.587.293 4.4 4.4 0 01-3.3-1.5l-4.019-4.429a2.693 2.693 0 00-1.937-.822 5.225 5.225 0 01-5.219-5.219v-2.922a5.226 5.226 0 015.219-5.221 2.5 2.5 0 001.914-.889l3.959-4.685a4.435 4.435 0 017.834 2.849z"
          />
          <path
            data-name="Path 2518"
            d="M1175.594 199.939a1.392 1.392 0 00-1.978 1.958 6.967 6.967 0 01-.051 9.841 1.392 1.392 0 001.958 1.979 9.754 9.754 0 00.072-13.778z"
          />
          <path
            data-name="Path 2519"
            d="M1169.665 203.844a1.392 1.392 0 00-.01 1.968 1.393 1.393 0 01-.01 1.968 1.392 1.392 0 101.958 1.979 4.18 4.18 0 00.031-5.9 1.392 1.392 0 00-1.968-.01z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SoundIcon
