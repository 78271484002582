import react,{useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import SoundIcon from './../svgs/SoundIcon';

let words = ["Sit down","Stand up","Write"]
const baseurl = process.env.REACT_APP_FRONT_LINK;

function DragAndDrop(props) {

    const [currentDrag,setCurrentDrag] = useState({});
    const [icon,setIcon] = useState("");
    // const [userAnswers,setUserAnswers] = useState([]);

    const [userSelect,setUserSelect] = useState({
        "drop-item-0":{},
        "drop-item-1":{},
        "drop-item-2":{},
        "drop-item-3":{}
    });
    const history = useHistory();

    const [items,setItems] = useState([]);

        const [randList,setRandList] = useState([]);
    

    const drag = (e,select) => {
        setCurrentDrag(select);
        e.preventDefault();

    }
    const drop = (e) => {
        document.getElementById("clickbt").play();

        let userSelectV1 = {...userSelect};
        let currentRandList = [...randList];

        setUserSelect(userSelectV1);

        if(userSelectV1[e.target.id] !== undefined) {
            let replaced = userSelectV1[e.target.id];
            currentRandList.push(replaced);
        }
        currentRandList = currentRandList.filter((item) => item.id !== currentDrag.id);
        userSelectV1[e.target.id] = currentDrag;
        setUserSelect(userSelectV1);

        setRandList(currentRandList)

        document.getElementById(e.target.id).innerHTML = `<img id=${e.target.id} src=${baseurl + currentDrag.img} />`;
        e.preventDefault();
    }
    const allowDrop = (ev) => {     
        ev.preventDefault();
        
    }

    const shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
      
        // While there remain elements to shuffle...
        while (currentIndex != 0) {
      
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
      
          // And swap it with the current element.
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
      
        return array;
      }
      const checkQuestion = () => {

            let userSelect2 = Object.values(userSelect);
            userSelect2 = JSON.stringify(userSelect2);
            let items2 = JSON.stringify(items);
            let allcorrect = true;

            if(userSelect2 === items2) {
                allcorrect = true;

            } else {
                allcorrect = false;
            }
            userSelect2 = JSON.parse(userSelect2);
            items2 = JSON.parse(items2);

           
            let userAnswers = [];

            items2.forEach((subItem,i) => {

                if(userSelect2[i] !== undefined) {
                    if(userSelect2[i].id === subItem.id) {

                        subItem.correctA = true;

                        subItem.word2.forEach((subItem2) => {

                            if(subItem2.userAnswer !== undefined) {
                                subItem2.userAnswer = subItem2.userAnswer.replaceAll("’","'");
                                subItem2.text = subItem2.text.replaceAll("’","'");

                                subItem2.userAnswer = subItem2.userAnswer.toLowerCase();
                                subItem2.text = subItem2.text.toLowerCase();
                            }

                            // console.log(subItem2.userAnswer,subItem2.text)
                            
                            if(subItem2.userAnswer !== subItem2.text && subItem2.type === 'selected') {
                                subItem.correctA = false;
                                allcorrect = false;
                            }
                        });


                        if(subItem.correctA === true) {
                            document.getElementById("drop-item-" + i).style.border = "5px solid #83cd83";
                        } else {
                            document.getElementById("drop-item-" + i).style.border = "5px solid #F84E53";   
                        }

                    } else {
                        subItem.correctA = false;
                        document.getElementById("drop-item-" + i).style.border = "5px solid #F84E53";

                    }

                    // console.log(userSelect2[i])

                    if(userSelect2[i].id !== undefined) {
                        userSelect2[i].correct = subItem.correctA;

                        
                        userAnswers = [...userAnswers,userSelect2[i]];
                    } else {
                        subItem.correct = subItem.correctA;
                        userAnswers = [...userAnswers,subItem];
                    }

                    // userSelect2[i].correct = subItem.correctA;
                    

                } else {
                    subItem.correctA = false;
                    subItem.correct = subItem.correctA;

                    // console.log(subItem) 
                    // userSelect2[i].correct = subItem.correctA;
                    userAnswers = [...userAnswers,subItem];


                    document.getElementById("drop-item-" + i).style.border = "5px solid #F84E53";
                }

                

            });





            if(allcorrect === true) {
                document.getElementById("correct-au").play();

            } else {
                document.getElementById("wrong-au").play();
               
            }
            // setItems(items2);


          setTimeout(() => {

            let answerFormat = {
                pass:true,
                progress:0,
                questionTitle:"Listen, stick, and write.",
                userAnswers:[],
                type:"listen-stick-write"
            }

            userAnswers.forEach((item) => {
                answerFormat.userAnswers.push({
                    userAnswer:"",
                    correctAnswer:item.word,
                    correct:item.correct
                })

                if(item.correct === false) {
                    answerFormat.pass = false;
                }
            });


            console.log("answerFormat_v2v",answerFormat)


           // console.log("userAnswers_V3",userAnswers);
            //history.push(baseurl + "WatchVideo")
           props.nextExercise(answerFormat);
          }, 3000);
      }

    useEffect(() => {

        // console.log("props.questions",props.questions);
        setItems(props.questions.answers)
        setIcon(props.questions.icon);
                let currentItems = props.questions.answers;

        // currentItems = shuffle(currentItems);

        setRandList(_.shuffle(currentItems));

        setTimeout(() => {
            document.getElementById("intr").play();
        },300)
        
    }, [])

    const playIntr = (e) => {
        document.getElementById("intr").play();
    }

    const changeTxt = (value,i) => {
    }

    const playAudio = (i) => {
        document.getElementById("audiodrag-" + i).play();
    }   

    const updateText = (value,i,j) => {
        let items2 = items;

        items2[i].word2[j].userAnswer = value;
        setItems(items2);
    }

    return(<div id={props.questions.mainLayout !== undefined?"fixed-layout":""}>
                <div className="question-title-container">
                    <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                    {/* <img src={baseurl + "assets/game5/listen.svg"} /> */}
                    {icon !== ""?<img src={baseurl + "assets/headicons/"+ icon +".svg"} />:<img src={baseurl + "assets/game5/listen.svg"} />}
                    <h1 className="question-title">Listen, stick, and write.</h1>

                    </div>
                    <div id="audio-bt" onClick={playIntr}><img src={baseurl + "assets/audio/listen.png"} /></div>
                </div>
        <div className={props.questions.style}>
        <div id="drag-Lists" style={props.questions.flexwrap === "wrap"?{flexWrap:"wrap",alignItems:"flex-start"}:{alignItems:"flex-start"}}>
            {items.map((item,i) => {

                let item_width = "13vw";
                
                if(props.questions.width !== undefined) {
                    item_width = props.questions.width;
                }
                return(
                <div className="drop-item" style={{width:item_width,height:"auto"}}>
<div className="drop-subItem" style={{width:item_width,height:item_width}} onDrop={drop} id={"item-" + i} onDragOver={allowDrop} id={"drop-item-" + i}></div>                    {/* {item.correctA !== undefined && <div style={item.correctA === true?{border:"3px solid lightgreen"}:{border:"3px solid red"}} className="drop-subItem" onDrop={drop} id={"item-" + i} onDragOver={allowDrop}></div>} */}


<div style={{display:"flex",justifyContent:"center"}}>
<audio src={baseurl + item.fileLink} id={"audiodrag-" + i} />
<div style={{cursor:"pointer"}} onClick={() => playAudio(i)}><SoundIcon style={{width:30}} /></div>

                    <div className="drop-title" style={{display:"flex",gap:5,justifyContent:"center",paddingLeft:15}}>{item.word2.map((subWord,j) => {
                        // return <span>{subItem}</span>
                        
                        if(subWord.type === "selected") {
                            
                            let spacing = {
                                margin:"0 5%"
                            };
    
                            if(subWord.leftSpacing === false) {
                                spacing.marginLeft = "0";
                            }
    
                            if(subWord.rightSpacing === false) {
                                spacing.marginRight = "0";
                            }
                            let textAlign = "left";
                            if(subWord.textAlign === "right") {
                                textAlign = "right";
                            }
                            let subdata = subWord.text.split("");
                            // let width = ((1/subdata.length) * 100) - 5;
                            // subdata.map((subItem2) => {
                            //     textObj.push(<div style={{width:width + "%"}}><input spellCheck="false"  type="text" maxLength={1} style={{borderBottom:"2px solid #000",width:"100%"}} /></div>)
                            // });

                            let currentwidth = subdata.length;
                            if(subWord.ch !== undefined) {
                                currentwidth = subWord.ch;
                            }
                            let chtext = {
                                width:currentwidth + "ch"
                            }

                            if(props.questions.textarea === true) {
                                chtext = {};
                            }
                            return(<div className={"editable-input editable-input-drag"} style={
                                {textAlign:textAlign,display:"flex",flexWrap:"wrap",...spacing,...chtext}
                            
                            }>
                               {props.questions.textarea === true?<textarea className='textarea-style' spellCheck="false"  onChange={(e) => {
                                updateText(e.target.value,i,j);
                            }} type="text" style={{width:"100%",textAlign:textAlign,color:"#0B329D",fontSize:20,fontFamily:"gotham-medium",height:subWord.height}} />:
                               <input spellCheck="false"  onChange={(e) => {
                                updateText(e.target.value,i,j);
                            }} type="text" maxLength={subdata.length} style={{width:"100%",textAlign:textAlign}} />
                               }
                                
                            
                            </div>)

                        } else {
    
                           
                            return(<div onChange={changeTxt} dangerouslySetInnerHTML={{__html:subWord.text}}></div>)
                        }
                    })}</div>
                </div>
                </div>);
            })}
        </div>
        <div id="drag-items" style={{marginTop:100,display:"flex",alignItems:"center",justifyContent:"center",flexWrap:"wrap"}}>
            {randList.map((item) => {

let item_width = "13vw";
                
if(props.questions.width !== undefined) {
    item_width = props.questions.width;
}

                if(item.img !== undefined) {
                    return(<div className="drag-item" style={{width:item_width,height:item_width}} onDrag={(env) => {drag(env,item)}}><img src={baseurl + item.img} /></div>)
                }
            })}
        </div>
        </div>
             {props.questions.newaudio !== undefined?

<audio src={`${baseurl}${props.questions.audio}`} className="hidden-audio" id="intr" />: <audio src={`${baseurl}assets/audio/p4/title.mp3?id=123231213`} className="hidden-audio" id="intr" />
             }
       
                <audio src={`${baseurl}assets/audio/correct.mp3`} className="hidden-audio" id="correct-au" />
                <audio src={`${baseurl}assets/audio/wrong.mp3`} className="hidden-audio" id="wrong-au" />

        <div className="button-check">
                <button className="check-answer-bt" variant="contained" color="primary" onClick={checkQuestion}>Check my answer</button>

                </div>


    </div>)
}
export default DragAndDrop;