import React from 'react';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';

const baseurl = process.env.REACT_APP_FRONT_LINK;

function VerticalMenu(props) {
    const user = useSelector((state) => state.user);

    const redirect = (link) => {
        window.location.hash = link
    }

    return (<div id="vertical-menu">
        <div id="vertical-logo"><img src={baseurl + "assets/header/logo.svg"} /></div>
        <div id="vertical-vmenu">
            {/* <Link to={baseurl + "read"} className="vertical-vmenu-item">
            <div className={props.active === "read"?"vmenu-img active-item":"vmenu-img"}><img src={baseurl + "assets/vertical/read.png"} id="read-item" className="active-item" /></div>
            <div>Read</div>
        </Link> */}
            <a onClick={() => { redirect("#video") }} className="vertical-vmenu-item">
                <div className={props.active === "video" || window.location.hash === "#video" ? "vmenu-img active-item" : "vmenu-img"}><img src={baseurl + "assets/vertical/watch.png"} id="watch-item" /></div>
                <div>Watch</div>

            </a>
            <a onClick={() => { redirect("#1") }} className="vertical-vmenu-item">
                <div className={props.active === "Exercise" && window.location.hash !== "#video" ? "vmenu-img active-item" : "vmenu-img"}><img src={baseurl + "assets/vertical/exercise.png"} id="exe-item" /></div>
                <div>Exercises</div>

            </a>
        </div>
        <div id="vertical-bottom">
            <img src={baseurl + "assets/header/icons/alert.png"} style={{ opacity: "0" }} />
            {/* <img src={baseurl + "assets/header/icons/profile2.png"} /> */}

            {user.avatar !== "" && <img style={{
                background: "#fff", borderRadius: "100%",
                maxWidth: "100%",
                height: "auto",
                padding: 3,
                boxSizing: "border-box"
            }} src={`${baseurl}assets/avatars/home/menu/item1.png`} />}
        </div>
    </div>)
}
export default VerticalMenu;